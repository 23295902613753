import React, { Fragment, useEffect, useState, useContext } from 'react';
import FullCalendar from '@fullcalendar/react';
import timeLinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import { CALENDAR_DAILY, CALENDAR_WEEKLY } from 'utils/constants/queryParams';
import { PrimaryButtonForm, Toggle, ToggleLabel, ToggleWrapper } from 'shared/styles/buttons';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { BsCheck } from 'react-icons/bs';
import { FiClock } from 'react-icons/fi';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { AppContext, BranchItemsContext } from 'context';
import * as html2canvas from 'html2canvas';
import $ from 'jquery';
import {
  ButtonsContainer,
  CalendarContainer,
  CalendarContainer1,
  NextPreviousContainer,
  // ProgressBarContainer,
  // ProgressContainer,
  // ProgressOtherContainer,
  CalendarOptionsRowContainer,
  StaticArrowUpDown,
} from './styles';
import moment from 'moment';
import CustomProgressBar from 'components/SharedComponents/CustomProgressBar';
import ProgressInformation from 'components/SharedComponents/ProgressInformation';
import { DropdownTypesContainer } from 'shared/styles/constants/tagsStyles';
import DateSelector from '../DateSelector';
import {
  ItemContainer,
  MainContainer,
  PublishButton,
  RightContainer,
} from '../ProgressInformation/styles';
import { DOWNLOAD } from 'utils/constants/icons';
import { useRef } from 'react';
import { StaticArrow } from 'components/DashboardComponents/LeftSidebar/styles';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { PURPLE } from 'shared/styles/constants/colors';
import './calendar.css'
import NoSelectAllDropdownFilter from '../NoSelectAllDropdownFilter';

const OPTAPLANNER_STATUS = {
  SOLVING: 'SOLVING',
  TERMINATED: 'TERMINATED',
};

const TimelineCalendar = ({
  resources = [],
  events = [],
  setShiftType,
  shiftType,
  values,
  setValues,
  eventClickHandler = () => { },
  resourceAreaHeaderContent,
  showProgressSection = false,
  optaplannerStatus = [{ state: '', tries: 0 }],
  visitsAssigned = 0,
  visitsUnassigned = 0,
  loadingOptaPlanner = false,
  timeStart = null,
  timeStatus = '',
  totalVisits = 0,
  getOptaplannerStatus = () => { },
  runningDate = '',
  calendarView,
  setCalendarView,
  editMode,
  isSplitMode = false,
  setChangeVisitDetails,
  showDeleteAllVisits,
  showPlannerVisitsFn,
  showPlannerAssign,
  setChangeVisit,
  timeZone,
  employeeVisitData,
  clinetVisitData,
  rotaShift,
  handleDownlaod,
  downloadDisable,
  setLoading,
  setAllowSchedulerCalls = true,
  useInput,
  setListView = false,
  decreaseWidth,
  setDecreaseWidth,
  schedulerData = [],
  commHrsData = [],
  preferredData = {},
  // query_params_data={}
  rightClickHandler = () => {},
  removeRightClickHandler = () => {},
  compactViewToggle,
  payTypeNameFilter = [],
  setPayTypeNameFilter = () => {},
  dropDownHandleSelect = () => { },
  shiftCategoryFilter=false,
  selectable = false,
  handleSelect = () => { },
  selectedEvents =[],
  enableMultiVisitSelection = false,
  handleMultiSelectEventToggle = () => {},
  isMultiEventSelect = false,
  handleEventDragStart = () => {},
  handleEventDrop = () => {},
  loading = false,
  seconds = 1,
  setSeconds= () => {},
  initialWait = 1,
}) => {
  const { contextChoices } = useContext(AppContext);
  const titleRef = useRef();
  const calendarRef = useRef([]);

  const [currentStepIndex, setCurrentStepIndex] = useState(1);
  const [eventsValue, setEventsValue] = useState([]);
  const [recourseValue, setRecourseValue] = useState();
  const [scrollValue, setScrollValue] = useState(0);
// console.log({resourceAreaHeaderContent, eventsValue, resources},'resource')
  // console.log('event_cal', eventsValue);
  useEffect(() => {
    if (optaplannerStatus[0].state === '' || visitsAssigned === 0) {
      setCurrentStepIndex(1);
    }
    if (
      (optaplannerStatus[0].state === OPTAPLANNER_STATUS.SOLVING &&
        visitsAssigned >= 1) ||
      visitsAssigned >= 1
    ) {
      setCurrentStepIndex(2);
    }
    if (
      optaplannerStatus[0].tries > 1 &&
      optaplannerStatus[0].tries <= 9 &&
      visitsAssigned >= 1
    ) {
      setCurrentStepIndex(3);
    }
    if (
      (optaplannerStatus[0].tries > 9 && visitsAssigned >= 1) ||
      (optaplannerStatus[0].state === OPTAPLANNER_STATUS.TERMINATED &&
        visitsAssigned >= 1)
    ) {
      setCurrentStepIndex(4);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optaplannerStatus[0].state, visitsAssigned, contextChoices]);

  // useEffect(()=>{
  //   if(query_params_data.start){
  //     setLoading(false)
  //   }

  // },[query_params_data.start])

  

  useEffect(() => {
    setEventsValue(events);
  }, [events]);



  useEffect(() => {
    setRecourseValue(recourseValue);
    if (!calendarRef.current[0]) {
      return;
    }
    let calendarApi = calendarRef.current[0].getApi();
    let calendarApi1 = calendarRef?.current[1]?.getApi();

    const view = calendarApi.view;

    calendarRef &&
      calendarRef.current[1] &&
      calendarRef.current[1].getApi().gotoDate(view.currentStart);

    values.start_date = view.currentStart;
    values.end_date = view.currentEnd;
    // if(rotaShift){
    //   setShiftType(view.type);
    // }else{
    calendarApi.changeView(shiftType);
    calendarApi1 && calendarApi1.changeView(shiftType);
    setShiftType(shiftType);
    //}

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSplitMode]);

  function startOfWeek(date) {
    var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);

    return new Date(date.setDate(diff));
  }

  useEffect(() => {
    if (values.choose_date) {
      if (shiftType === CALENDAR_DAILY) {
        calendarRef.current[0].getApi().gotoDate(values.choose_date);
        calendarRef &&
          calendarRef.current[1] &&
          calendarRef.current[1].getApi().gotoDate(values.choose_date);
        values.start_date = moment.utc(values.choose_date).local().toDate();
        values.end_date = moment
          .utc(values.choose_date)
          .add(1, 'days')
          .local()
          .toDate();
        setValues({ ...values });
      } else {
        calendarRef.current[0].getApi().gotoDate(values.choose_date);
        calendarRef &&
          calendarRef.current[1] &&
          calendarRef.current[1].getApi().gotoDate(values.choose_date);
        const c_date = new Date(values.choose_date);
        const str = startOfWeek(c_date);
        values.start_date = moment.utc(str).local().toDate();
        values.end_date = moment.utc(str).add(7, 'days').local().toDate();
        setValues({ ...values });
      }
    }
  }, [values.choose_date, shiftType]);

  useEffect(() => {
    let _events = [...events];
    _events.forEach(e => {
      // e.start = moment(e.start).format()
      // e.end = moment(e.end).format()

      if (e && e.title && typeof e.title === 'object') {
        if (e.title?.length === 0) {
          e.title = '';
        } else e.title = e.title.find(title => title !== undefined);
      }
    });
    setEventsValue([..._events]);
    let index = 0;
    let resourceElement = null;
    let dataElement = null;
    document
      .querySelectorAll('.App .fc-scroller-harness-liquid')
      .forEach(element => {
        if (index == 0) {
          resourceElement = element;
        } else {
          dataElement = element;
        }
        index++;
      });

    let headerData = null;
    index = 0;
    document.querySelectorAll('.App .fc-scroller').forEach(element => {
      if (index == 1) headerData = element;

      index++;
    });
    $(dataElement).scrollLeft($(headerData).scrollLeft());

    if (dataElement != null && resourceElement != null && headerData != null) {
      $(dataElement).scroll(function () {
        $(resourceElement).scrollTop($(dataElement).scrollTop());
        $(headerData).scrollLeft($(dataElement).scrollLeft());
      });
      $(resourceElement).attr(
        'style',
        'overflow:hidden !important; overflow-x: scroll !important;'
      );
    }

    //    document.querySelectorAll(".App .fc-media-screen").forEach(element=>{
    //
    //      console.log("fc-media-screen:", $(element));
    //      $(element).css({'height':'100vh !important'})
    ////      $(element).height();
    //
    //
    //    });

    let index1 = 0;
    let resourceElement1 = null;
    let dataElement1 = null;
    document
      .querySelectorAll('.App2 .fc-scroller-harness-liquid')
      .forEach(element => {
        if (index1 == 0) {
          resourceElement1 = element;
        } else {
          dataElement1 = element;
        }
        index1++;
      });

    let headerData1 = null;
    index1 = 0;
    document.querySelectorAll('.App2 .fc-scroller').forEach(element => {
      if (index1 == 1) headerData1 = element;

      index1++;
    });
    $(dataElement1).scrollLeft($(headerData1).scrollLeft());

    if (
      dataElement1 != null &&
      resourceElement1 != null &&
      headerData1 != null
    ) {
      $(dataElement1).scroll(function () {
        $(resourceElement1).scrollTop($(dataElement1).scrollTop());
        $(headerData1).scrollLeft($(dataElement1).scrollLeft());
      });
      $(resourceElement1).attr(
        'style',
        'overflow:hidden !important; overflow-x: scroll !important;'
      );
    }
  }, [events, isSplitMode]);

  const handleDateClick = arg => {
    // console.log('handleDateClick -> arg', arg);
    // console.log('handleDateClick -> arg.dateStr', arg.dateStr);
  };

  const handleEventClick = arg => {
    console.log(arg,"arg")
    if (arg.event._def.extendedProps.openModal) {
      return;
    }
    eventClickHandler({
      id: arg.event.id,
      groupId: arg.event.groupId,
      extendedProps: arg.event.extendedProps,
    });
  };
  const showToday = () => {
    // setLoading(true)
    if (!calendarRef.current[0]) {
      return '';
    }
    let calendarApi = calendarRef.current[0].getApi();
    let calendarApi1 = calendarRef?.current[1]?.getApi();
    calendarApi.today();
    calendarApi1 && calendarApi1.today();
    const view = calendarApi.view;

    values.start_date = view.currentStart;
    values.end_date = view.currentEnd;

    setValues({ ...values });
    getOptaplannerStatus();
  };
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: '#6E6BFA',
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#6E6BFA',
    },
  }));
  const getCalendarTitle = () => {
    if (!calendarRef.current[1]) {
      return '';
    }
    let calendarApi = calendarRef.current[1].getApi();
    const view = calendarApi.view;
    return view.title;
  };

  const dailyView = () => {
    setLoading(true)
    let calendarApi = calendarRef.current[0].getApi();
    let calendarApi1 = calendarRef?.current[1]?.getApi();

    calendarApi.changeView(CALENDAR_DAILY);
    calendarApi1 && calendarApi1.changeView(CALENDAR_DAILY);
    setShiftType(CALENDAR_DAILY);
    const view = calendarApi.view;

    values.start_date = view.currentStart;
    values.end_date = view.currentEnd;
    setValues({ ...values });
    if (!rotaShift) {
      setAllowSchedulerCalls(false);
    }
    getOptaplannerStatus();
  };

  const weeklyView = () => {
    setLoading(true)
    setSeconds(initialWait)
    let calendarApi = calendarRef.current[0].getApi();
    let calendarApi1 = calendarRef?.current[1]?.getApi();

    calendarApi.changeView(CALENDAR_WEEKLY);
    calendarApi1 && calendarApi1.changeView(CALENDAR_WEEKLY);
    setShiftType(CALENDAR_WEEKLY);
    const view = calendarApi.view;

    values.start_date = view.currentStart;
    values.end_date = view.currentEnd;
    setValues({ ...values });
    if (!rotaShift) {
      setAllowSchedulerCalls(false);
    }

    getOptaplannerStatus();
  };

  const previousChange = () => {
    setLoading(true)
    setSeconds(initialWait)
    let calendarApi = calendarRef.current[0].getApi();
    let calendarApi1 = calendarRef?.current[1]?.getApi();

    calendarApi.prev();
    calendarApi1 && calendarApi1.prev();
    const view = calendarApi.view;

    values.start_date = view.currentStart;
    values.end_date = view.currentEnd;
    setValues({ ...values });
    getOptaplannerStatus();
  };

  const nextChange = () => {
    setLoading(true)
    setSeconds(initialWait)
    let calendarApi = calendarRef.current[0].getApi();
    let calendarApi1 = calendarRef?.current[1]?.getApi();
    calendarApi.next();
    calendarApi1 && calendarApi1.next();
    const view = calendarApi.view;

    values.start_date = view.currentStart;
    values.end_date = view.currentEnd;
    setValues({ ...values });
    getOptaplannerStatus();
  };

  const viewClassNames = () => {
    window.dispatchEvent(new Event('resize'));
  };

  const handleVisitChange = e => {
    // console.log(e, '478555');
    let visit = {
      end_date: moment(e.event._instance.range.end),
      id: e.event._def.publicId,
      employee: null,
      start_date: moment(e.event._instance.range.start),
    };

    setChangeVisit(e);
    setChangeVisitDetails(visit);
    remove(e);
  };

  const remove = ({ event }) => {
    if (!calendarRef.current[0]) return;
    //if (inElement({ x: jsEvent.pageX, y: jsEvent.pageY }, calendarRef.current)) return;
    event.remove();
  };

  const handleClick = () => {
    setDecreaseWidth(!decreaseWidth);
    // setTimeout(() => {
    //   titleRef.current.lastElementChild.scrollIntoView({ behavior: 'smooth' });
    // }, 1000);
    let dataElement1 = null;
    if (decreaseWidth) {
      document
        .querySelectorAll('.App .fc .fc-toolbar.fc-header-toolbar')
        .forEach(element => {
          dataElement1 = element;
        });
      if (dataElement1 != null) {
        $(dataElement1).attr(
          'style',
          'display:none !important; margin-top: -25px; margin-left: 10px'
        );
      }
    } else {
      document
        .querySelectorAll('.App .fc .fc-toolbar.fc-header-toolbar')
        .forEach(element => {
          dataElement1 = element;
        });
      if (dataElement1 != null) {
        $(dataElement1).attr(
          'style',
          'display:block !important; margin-top: -25px; margin-left: 10px; margin-bottom: 1.5rem'
        );
      }
    }
  };

  function msToTime(ms) {
    let hours = (ms / (1000 * 60 * 60)).toFixed(1);
    return hours 
  }

  // const getCalendarHeight=()=>{
  //   let val = '100%'
  //   if(window.outerHeight > 1151){
  //     val = decreaseWidth ? '110%' : '168%'
  //   }else if((window.outerHeight > 1079) && (window.outerHeight < 1152)){
  //     val = decreaseWidth ? '100%' : '165%'
  //   }else if((window.outerHeight > 944) && (window.outerHeight < 1080)){
  //     val = decreaseWidth ? '92%' : '165%'
  //   }else if((window.outerHeight > 899) && (window.outerHeight < 945)){
  //     val = decreaseWidth ? '87%' : '165%'
  //   }else if((window.outerHeight > 845) && (window.outerHeight < 900)){
  //     val = decreaseWidth ? '80%' : '165%'
  //   }else if((window.outerHeight > 719) && (window.outerHeight < 846)){
  //     val = decreaseWidth ? '80%' : '160%'
  //   }else if((window.outerHeight > 647) && (window.outerHeight < 720)){
  //     val = decreaseWidth ? '80%' : '160%'
  //   }
  //   return val;
  // }


  // const getCalHeight=()=>{
  //      let val = '80'
  //      if(window.outerHeight > 1250){
  //       val = decreaseWidth ? (rotaShift ? '80' : '145') : (rotaShift ? '80' : '145')
  //     }else if((window.outerHeight > 1051) && (window.outerHeight < 1251)){
  //     val = decreaseWidth ? (rotaShift ? '80' : '120') : (rotaShift ? '80' : '120')
  //   }else if((window.outerHeight > 1079) && (window.outerHeight < 1152)){
  //     val = decreaseWidth ? (rotaShift ? '80' : '102') : (rotaShift ? '80' : '102')
  //   }else if((window.outerHeight > 944) && (window.outerHeight < 1080)){
  //     val = decreaseWidth ? (rotaShift ? '77' : '95') : (rotaShift ? '80' : '86')
  //   }else if((window.outerHeight > 899) && (window.outerHeight < 945)){
  //     val = decreaseWidth ? (rotaShift ? '70' : '66') : '80'
  //   }else if((window.outerHeight > 845) && (window.outerHeight < 900)){
  //     val = decreaseWidth ? '65' : '80'
  //   }else if((window.outerHeight > 719) && (window.outerHeight < 846)){
  //     val = decreaseWidth ? '65' : '80'
  //   }else if((window.outerHeight > 647) && (window.outerHeight < 720)){
  //     val = decreaseWidth ? '65' : '80'
  //   }
  //   return val;
  // }

  const choose_date_hook = useInput('choose_date');
  const compactEventItemStyle = compactViewToggle ? {
    height: "2.5rem"
  }: {};
  const compactEventItemTitleStyle = compactViewToggle ? {
    display: "flex"
  }: {}

  const [fullCalendarOverflowY, setFullCalendarOverflowY] = useState(true);
  const handleDownloadImage = () => {
    const input = document.querySelector('#full-calendar-container');
    if(!input) {
      console.log("CANNOT FIND CALENDAR TO EXPORT")
      return;
    }
    const current_date = moment().format('DD-MM-YYY');
    setFullCalendarOverflowY(false);
    setLoading(true)
    setTimeout(()=>{
      html2canvas(input, { windowWidth: shiftType===CALENDAR_DAILY? 3300:1800, })
        .then((canvas) => {
          const link = document.createElement('a');
          link.href = canvas.toDataURL('image/png');
          link.download = `calendar_${current_date}.png`;
          link.click();
        })
        .finally(() => {
          setFullCalendarOverflowY(true);
          setLoading(false)
        });
    }, 1)
  };

 
  useEffect(() => {
    if (seconds > 1) {
      const timerId = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds - 1);
      }, 1000);

      return () => clearInterval(timerId); // Cleanup on unmount or re-render
    }
  }, [seconds]);

  return (
    <Fragment>
      {decreaseWidth && (
        <>
        <CalendarOptionsRowContainer row={1}>
          <ButtonsContainer className='mb-0'
            selected={shiftType === CALENDAR_DAILY}>
            <div className="d-flex">
              <div className={'dailyView'}>
                <button className={'daily mr-2'} onClick={dailyView}>
                  Daily
                </button>
                <button className={'weekly'} onClick={weeklyView}>
                  Weekly
                </button>
              </div>
              <DropdownTypesContainer className="ml-1 mr-1">
                <DateSelector
                  typeFilter={true}
                  labelTop="Select Date"
                  value={values.choose_date ? values.choose_date : ''}
                  {...choose_date_hook}
                />
              </DropdownTypesContainer>
               {/* Multi-Select Events */}
               {/* {enableMultiVisitSelection && <>
                      <ToggleWrapper style={{top:"6px"}}>
                        <Toggle
                          id="checkbox_multi_select"
                          type="checkbox"
                          onClick={handleMultiSelectEventToggle}
                          checked={isMultiEventSelect}
                        />
                        <ToggleLabel htmlFor="checkbox_multi_select" />
                      </ToggleWrapper>
                      <div className="toggle-label ml-2 mt-1 mr-2">
                     Clear Selecttion
                      </div>
                    </>} */}
              {rotaShift && (
                <MainContainer>
                  <RightContainer>
                    <ItemContainer>
                      <PublishButton
                        disable={downloadDisable}
                        onClick={!downloadDisable && handleDownlaod}>
                        {DOWNLOAD}
                      </PublishButton>
                    </ItemContainer>
                  </RightContainer>
                </MainContainer>
              )}
            </div>
          </ButtonsContainer>
          {
            !rotaShift &&
            <ProgressInformation
              hardScore={optaplannerStatus[0].hard}
              mediumScore={optaplannerStatus[0].medium}
              softScore={optaplannerStatus[0].soft}
              assigned={`${visitsAssigned}/${totalVisits}`}
              timerPause={!loadingOptaPlanner}
              initialTime={optaplannerStatus[0].startAt}
              finalTime={optaplannerStatus[0].endAt}
              runningDate={runningDate}
              actualDate={values.start_date}
              optaplannerStatus={optaplannerStatus}
              showDeleteAllVisits={showDeleteAllVisits}
              showPlannerVisitsFn={showPlannerVisitsFn}
              showPlannerAssign={showPlannerAssign}
              handleDownlaod={handleDownlaod}
              downloadDisable={downloadDisable || !fullCalendarOverflowY}
              handleDownloadImage={handleDownloadImage}
              showDownloadImageIcon={!isSplitMode}
            />
          }
          </CalendarOptionsRowContainer>
          <CalendarOptionsRowContainer row={2}>
            <NextPreviousContainer
              className={'nextPrevious'}
              progress={showProgressSection}>
              <div className={'d-flex align-items-center'}>
                <div className={'arrow'}>
                  <MdKeyboardArrowLeft onClick={previousChange} />
                </div>
                <div className={'arrow'}>
                  <MdKeyboardArrowRight onClick={nextChange} />
                </div>
                <PrimaryButtonForm onClick={showToday}>
                  <span>Today</span>
                </PrimaryButtonForm>
              </div>
            </NextPreviousContainer>
            {showProgressSection ? (
              <CustomProgressBar
                currentStep={currentStepIndex}
                item={['Unassigned', 'Assigned', 'Optimized', 'Best']}
                loadingOptaPlanner={loadingOptaPlanner}
                optaplannerStatus={optaplannerStatus}
                runningDate={runningDate}
                actualDate={values.start_date}
              />
            ) : (
              <></>
            )}
          </CalendarOptionsRowContainer>
            
        </>
      )}
      <StaticArrowUpDown decreaseWidth={decreaseWidth} onClick={handleClick}>
        <div ref={titleRef}>
          {decreaseWidth ? (
            <IoIosArrowUp size={18} color={PURPLE} />
          ) : (
            <IoIosArrowDown size={18} color={PURPLE} />
          )}
        </div>
      </StaticArrowUpDown>
      <CalendarContainer
        className="App"
        progress={showProgressSection}
        reduceColumns={shiftType === CALENDAR_WEEKLY}
        isSplitMode={isSplitMode}
        height={!fullCalendarOverflowY ? undefined : decreaseWidth? "calc(100vh - 23rem)":"calc(100vh - 8rem)"}
        minHeight={decreaseWidth? "calc(100vh - 23rem)":"calc(100vh - 8rem)"}
        overflow={decreaseWidth?"visible":"hidden"}
        id="full-calendar-container"
      // innerHeight={window.outerHeight}
      // view={CALENDAR_DAILY}
      >
        <FullCalendar
          eventDragStart={handleEventDragStart}
          eventDrop={handleEventDrop}
          selectable={selectable}
          select={handleSelect}
          eventClassNames={(eventInfo) => selectedEvents.length && selectedEvents.includes(eventInfo.event.id) ? 'selected-event' : ''}
          eventDidMount={rightClickHandler}
          eventWillUnmount={removeRightClickHandler}
          schedulerLicenseKey={'0149351374-fcs-1640812113'}
          scrollTime={moment().subtract(1, 'hours').format('HH:00:00')}
          ref={el => (calendarRef.current[0] = el)}
          plugins={[timeLinePlugin, interactionPlugin, bootstrapPlugin]}
          dateClick={handleDateClick}
          eventClick={handleEventClick}
          // selectable={true}
          firstDay={1}
          // expandRows={true}
          // className={'timeline-cal'}
          height={fullCalendarOverflowY? "100%" : "auto"}
          // height={"100%"
          //   // getCalendarHeight()
          //   // isSplitMode
          //   //   ? '100%'
          //   //   : decreaseWidth
          //   //     ? `calc(120% - 8rem)`
          //   //     : `calc(${window.outerHeight}px - 8rem)`
          // }
          headerToolbar={{
            left: 'title',
            center: '',
            end: '',
          }}
          views={{
            resourceTimelineDay: {
              type: 'resourceTimelineDay',
              buttonText: 'Daily',
              slotDuration: '00:05:00',
              eventMinWidth: 1,
              slotMinWidth: 10,
            },

            resourceTimelineWeekDays: {
              type: 'resourceTimelineWeek',
              duration: { weeks: 1 },
              slotDuration: { days: 1 },
              slotLabelFormat: [
                { month: 'long', year: 'numeric' }, // top level of text
                {
                  day: 'numeric',
                  weekday: 'short',
                  omitCommas: true,
                },
              ],
              buttonText: 'Weekly',
            },
          }}
          slotLabelFormat={{
            hour: 'numeric',
            minute: '2-digit',
            omitZeroMinute: false,
            hour12: false,
          }}
          eventTimeFormat={{
            hour: 'numeric',
            minute: '2-digit',
            hour12: false,
            // timeZoneName: 'short'
          }}
          titleFormat={
            // will produce something like "Tuesday, September 18, 2018"
            shiftType === CALENDAR_DAILY
              ? {
                month: 'short',
                year: 'numeric',
                day: 'numeric',
                weekday: 'short',
              }
              : {
                month: 'short',
                year: 'numeric',
                day: 'numeric',
              }
          }
          // nextDayThreshold={'06:00'}
          initialView={CALENDAR_DAILY}
          filterResourcesWithEvents={true}
          resourceOrder="title employee"
          resourceAreaHeaderContent={resourceAreaHeaderContent}
          resourceAreaWidth="8rem"
          resources={[...resources]}
          events={seconds > 1 ? eventsValue.slice(0, 100) : eventsValue}
          themeSystem="bootstrap"
          viewClassNames={viewClassNames}
          lazyFetching={false}
          editable={editMode ? true : false}
          eventOrder={shiftType===CALENDAR_WEEKLY ? 'start, orderEvent, title' : 'orderEvent, start,allDay,title'}
          eventChange={event => handleVisitChange(event)}
          //eventLeave={event => handleVisitChange(event)}
          //eventDragStop={remove}
          timeZone={timeZone ? timeZone : 'none'}
          nowIndicator={true}
          eventOverlap={true}
          slotEventOverlap={true}
          eventContent={function (args, createElement) {
            const serviceCategory =
              args.event._def.extendedProps &&
                args.event._def.extendedProps.service_category
                ? ` (${args.event._def.extendedProps.service_category})`
                : '';
            if (args.event._def.extendedProps.icon && !args.event._def.extendedProps.tolerance) {
              const icon = args.event._def.extendedProps.icon;
              const format = 'HH:mm';
              let prefEmp = preferredData[args.event._def.extendedProps.clientId] || []

              // var time = moment() gives you current time. no format required.
              let start_t = moment
                .utc(args.event._instance.range.start)
                .format('HH:mm');
              const time = moment(start_t, format);
              const beforeTime = moment(
                args.event._def.extendedProps.earliest_start_time,
                format
              );
              const afterTime = moment(
                args.event._def.extendedProps.latest_start_time,
                format
              );


              return (
                <>
                  <BootstrapTooltip
                    title={
                      <>
                        <div style={{ display: 'flex' }}>
                          <span
                            style={{ fontWeight: 'bold', marginRight: '3px' }}>
                            {args.timeText
                              ? args.timeText
                              : moment
                                .utc(args.event._instance.range.start)
                                .format('HH:mm') +
                              '-' +
                              moment
                                .utc(args.event._instance.range.end)
                                .format('HH:mm')}
                          </span>
                          <span>{args.event._def.title}</span>
                        </div>
                        <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Gender: '}
                            </span>
                            {args.event._def.extendedProps.gender ?? 'All'}
                        </div>
                        {args.event._def.extendedProps.assigned_by &&
                        <div>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Assigned By: '}
                            </span>
                            {args.event._def.extendedProps.assigned_by}
                          </div>
                        </div>}
                        {args.event._def.extendedProps
                              .earliest_start_time && 
                              <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Earliest Start Time: '}
                            </span>
                            {args.event._def.extendedProps
                              .earliest_start_time &&
                              args.event._def.extendedProps.earliest_start_time.slice(
                                0,
                                -3
                              )}
                          </div>
                        </div>}
                       {args.event._def.extendedProps.latest_start_time && <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Latest Start Time: '}
                            </span>
                            {args.event._def.extendedProps.latest_start_time &&
                              args.event._def.extendedProps.latest_start_time.slice(
                                0,
                                -3
                              )}
                          </div>
                        </div>}
                        {args.event._def.extendedProps
                              .actual_visit_start_time && 
                              <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Clocked In Time: '}
                            </span>
                            {args.event._def.extendedProps
                              .actual_visit_start_time &&
                              moment(
                                args.event._def.extendedProps
                                  .actual_visit_start_time
                              ).format('HH:mm')}
                          </div>
                        </div>}
                        {args.event._def.extendedProps
                              .actual_visit_end_time && 
                              <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Clocked Out Time: '}
                            </span>
                            {args.event._def.extendedProps
                              .actual_visit_end_time &&
                              moment(
                                args.event._def.extendedProps
                                  .actual_visit_end_time
                              ).format('HH:mm')}
                          </div>
                        </div>}
                        {args.event._def.extendedProps.visit_type && 
                        <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Visit Type: '}
                            </span>
                            {args.event._def.extendedProps.visit_type}
                          </div>
                        </div>}
                        {args.event._def.extendedProps.visit_priority && 
                        <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Visit Priority: '}
                            </span>
                            {args.event._def.extendedProps.visit_priority}
                          </div>
                        </div>}
                       {args.event._def.extendedProps.service_category &&
                        <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Service Category: '}
                            </span>
                            {args.event._def.extendedProps.service_category}
                          </div>
                        </div>}
                       {args.event._def.extendedProps.run_view_name &&
                        <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Run Name: '}
                            </span>
                            {args.event._def.extendedProps.run_view_name}
                          </div>
                        </div>}
                      {args.event._def.extendedProps.address &&
                        <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Address: '}
                            </span>
                            {args.event._def.extendedProps.address}
                          </div>
                        </div>}
                        {args.event._def.extendedProps.zipcode &&
                        <div>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Postal Code: '}
                            </span>
                            {args.event._def.extendedProps.zipcode}
                          </div>
                        </div>}
                       {args.event._def.extendedProps.is_published &&
                        <div>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {args.event._def.extendedProps.is_published
                                ? 'Published'
                                : 'Unpublished'}
                            </span>
                          </div>
                        </div>}
                       {args.event._def.extendedProps.employee_group_area &&
                        <div>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Employee Group Area: '}
                            </span>
                            {args.event._def.extendedProps.employee_group_area}
                          </div>
                        </div>}
                       {args.event._def.extendedProps.client_group_area &&
                        <div>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Client Group Area: '}
                            </span>
                            {args.event._def.extendedProps.client_group_area}
                          </div>
                        </div>}
                        {(prefEmp.length > 0) &&
                        <div>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Preferred Employees: '}
                            </span>
                            {prefEmp.join(',')}
                          </div>
                        </div>}
                      </>
                    }
                    arrow>
                    <div className="d-flex flex-column" style={compactEventItemStyle}>
                      <div className='d-flex justify-content-between'>
                        <div className='mr-1' style={{display: "flex", gap: "0.25rem", alignItems: "center"}}>
                        {args.timeText.replace(/ /g, '') && 
                          <span style={{ fontWeight: 'bold', marginRight: '3px' }}>
                            {args.timeText.replace(/ /g, '')}
                          </span>
                        }
                          {args.event._def.extendedProps.employeeId &&
                            beforeTime &&
                            afterTime &&
                            !(
                              time.isBetween(beforeTime, afterTime) ||
                              moment
                                .utc(args.event._instance.range.start)
                                .format('HH:mm') ==
                              args.event._def.extendedProps.earliest_start_time?.slice(
                                0,
                                -3
                              ) ||
                              moment
                                .utc(args.event._instance.range.start)
                                .format('HH:mm') ==
                              args.event._def.extendedProps.latest_start_time?.slice(
                                0,
                                -3
                              ) ||
                              moment
                                .utc(args.event._instance.range.end)
                                .format('HH:mm') ==
                              args.event._def.extendedProps.earliest_start_time?.slice(
                                0,
                                -3
                              ) ||
                              moment
                                .utc(args.event._instance.range.end)
                                .format('HH:mm') ==
                              args.event._def.extendedProps.latest_start_time?.slice(
                                0,
                                -3
                              )
                            ) && <span><FiClock /></span>}
                          {args.event._def.extendedProps.is_published && (
                            <span><BsCheck /></span>
                          )}
                        <span>
                          {args.event._def.extendedProps.gender
                            ? args.event._def.extendedProps.gender.substring(
                              0,
                              1
                            )
                            : 'A'}
                        </span>
                        <span>
                          {args.event._def.extendedProps.priority_icon}
                        </span>
                        {args.event._def.extendedProps.isRegular && (
                          <span className="px-1">R</span>
                        )}
                        </div>
                        <span>{args.event._def.extendedProps.assigned_by}</span>
                      </div>
                      <div style={compactEventItemTitleStyle}>
                        <span style={{marginRight: "0.25rem"}}>{icon}</span>
                        <span>{args.event._def.title.substring(0, 15) + (serviceCategory || '') }</span>                        
                      </div>
                    </div>
                  </BootstrapTooltip>
                </>
              );
            } else if (
              args.event._def.extendedProps.typeVisit &&
              args.event._def.extendedProps.travelMode && !args.event._def.extendedProps.tolerance
            ) {
              const icon = args.event._def.extendedProps.travelMode;
              return (
                <>
                  <BootstrapTooltip
                    title={
                      <>
                        <div style={{ display: 'flex'}}>
                          <span
                            style={{ fontWeight: 'bold', marginRight: '3px' }}>
                            {args.timeText
                              ? args.timeText
                              : moment
                                .utc(args.event._instance.range.start)
                                .format('HH:mm') +
                              '-' +
                              moment
                                .utc(args.event._instance.range.end)
                                .format('HH:mm')}
                          </span>
                          <span>{args.event._def.title}</span>
                        </div>
                        <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Gender: '}
                            </span>
                            {args.event._def.extendedProps.gender ?? 'All'}
                        </div>
                        <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Earliest Start Time: '}
                            </span>
                            {args.event._def.extendedProps
                              .earliest_start_time &&
                              args.event._def.extendedProps.earliest_start_time.slice(
                                0,
                                -3
                              )}
                          </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Latest Start Time: '}
                            </span>
                            {args.event._def.extendedProps.latest_start_time &&
                              args.event._def.extendedProps.latest_start_time.slice(
                                0,
                                -3
                              )}
                          </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Clocked In Time: '}
                            </span>
                            {args.event._def.extendedProps
                              .actual_visit_start_time &&
                              moment(
                                args.event._def.extendedProps
                                  .actual_visit_start_time
                              ).format('HH:mm')}
                          </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Clocked Out Time: '}
                            </span>
                            {args.event._def.extendedProps
                              .actual_visit_end_time &&
                              moment(
                                args.event._def.extendedProps
                                  .actual_visit_end_time
                              ).format('HH:mm')}
                          </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Visit Type: '}
                            </span>
                            {args.event._def.extendedProps.visit_type}
                          </div>
                        </div>
                        {args.event._def.extendedProps.visit_priority && 
                        <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Visit Priority: '}
                            </span>
                            {args.event._def.extendedProps.visit_priority}
                          </div>
                        </div>}
                        <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Service Category: '}
                            </span>
                            {args.event._def.extendedProps.service_category}
                          </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Service Category Type: '}
                            </span>
                            {args.event._def.extendedProps.service_category_type}
                          </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Address: '}
                            </span>
                            {args.event._def.extendedProps.address}
                          </div>
                        </div>
                        <div>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Postal Code: '}
                            </span>
                            {args.event._def.extendedProps.zipcode}
                          </div>
                        </div>
                        <div>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {args.event._def.extendedProps.is_published
                                ? 'Published'
                                : 'Unpublished'}
                            </span>
                          </div>
                        </div>
                      </>
                    }
                    arrow>
                    <div className="d-flex flex-column"
                    style={compactEventItemStyle}>
                    <div style={{display: "flex", gap: "0.25rem"}}>
                      {args.timeText.replace(/ /g, '') && 
                          <span style={{ fontWeight: 'bold', marginRight: '3px' }}>
                            {args.timeText.replace(/ /g, '')}
                          </span>
                        }
                      <span>
                        {args.event._def.extendedProps.gender
                          ? args.event._def.extendedProps.gender.substring(0, 1)
                          : 'A'}
                      </span>
                      <span>{args.event._def.extendedProps.priority_icon}</span>
                      {args.event._def.title !== 'Unavailable' && <>{icon}</>}
                      </div>
                      <div style={compactEventItemTitleStyle}>{args.event._def.title + (serviceCategory || '') }</div>
                    </div>
                  </BootstrapTooltip>
                </>
              );
            } else if (args.event._def.extendedProps.typeVisit && !args.event._def.extendedProps.tolerance) {
              const format = 'HH:mm';

              // var time = moment() gives you current time. no format required.
              let start_t = moment
                .utc(args.event._instance.range.start)
                .format('HH:mm');
              const time = moment(start_t, format);
              const beforeTime = moment(
                args.event._def.extendedProps.earliest_start_time,
                format
              );
              const afterTime = moment(
                args.event._def.extendedProps.latest_start_time,
                format
              );
              return (
                <>
                  <BootstrapTooltip
                    title={
                      <>
                        <div>
                          <span
                            style={{ fontWeight: 'bold', marginRight: '3px' }}>
                            {args.timeText
                              ? args.timeText
                              : moment
                                .utc(args.event._instance.range.start)
                                .format('HH:mm') +
                              '-' +
                              moment
                                .utc(args.event._instance.range.end)
                                .format('HH:mm')}
                          </span>
                          <span>{args.event._def.title}</span>
                        </div>
                        <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Gender: '}
                            </span>
                            {args.event._def.extendedProps.gender ?? 'All'}
                        </div>
                        <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Earliest Start Time: '}
                            </span>
                            {args.event._def.extendedProps
                              .earliest_start_time &&
                              args.event._def.extendedProps.earliest_start_time.slice(
                                0,
                                -3
                              )}
                          </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Latest Start Time: '}
                            </span>
                            {args.event._def.extendedProps.latest_start_time &&
                              args.event._def.extendedProps.latest_start_time.slice(
                                0,
                                -3
                              )}
                          </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Clocked In Time: '}
                            </span>
                            {args.event._def.extendedProps
                              .actual_visit_start_time &&
                              moment(
                                args.event._def.extendedProps
                                  .actual_visit_start_time
                              ).format('HH:mm')}
                          </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Clocked Out Time: '}
                            </span>
                            {args.event._def.extendedProps
                              .actual_visit_end_time &&
                              moment(
                                args.event._def.extendedProps
                                  .actual_visit_end_time
                              ).format('HH:mm')}
                          </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Visit Type: '}
                            </span>
                            {args.event._def.extendedProps.visit_type}
                          </div>
                        </div>
                        {args.event._def.extendedProps.visit_priority && 
                        <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Visit Priority: '}
                            </span>
                            {args.event._def.extendedProps.visit_priority}
                          </div>
                        </div>}
                        <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Service Category: '}
                            </span>
                            {args.event._def.extendedProps.service_category}
                          </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Service Category Type: '}
                            </span>
                            {args.event._def.extendedProps.service_category_type}
                          </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Address: '}
                            </span>
                            {args.event._def.extendedProps.address}
                          </div>
                        </div>
                        <div>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Postal Code: '}
                            </span>
                            {args.event._def.extendedProps.zipcode}
                          </div>
                        </div>
                        <div>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {args.event._def.extendedProps.is_published
                                ? 'Published'
                                : 'Unpublished'}
                            </span>
                          </div>
                        </div>
                      </>
                    }
                    arrow>
                    <div className="d-flex flex-column"
                      style={compactEventItemStyle}>
                      <div className='d-flex justify-content-between'>
                        <div className='mr-1' style={{display: "flex", gap: "0.25rem", alignItems: "center"}}>
                        {args.timeText.replace(/ /g, '') && 
                          <span style={{ fontWeight: 'bold', marginRight: '3px' }}>
                            {args.timeText.replace(/ /g, '')}
                          </span>
                        }
                          {args.event._def.extendedProps.employeeId &&
                            beforeTime &&
                            afterTime &&
                            !(
                              time.isBetween(beforeTime, afterTime) ||
                              moment
                                .utc(args.event._instance.range.start)
                                .format('HH:mm') ==
                              args.event._def.extendedProps.earliest_start_time?.slice(
                                0,
                                -3
                              ) ||
                              moment
                                .utc(args.event._instance.range.start)
                                .format('HH:mm') ==
                              args.event._def.extendedProps.latest_start_time?.slice(
                                0,
                                -3
                              ) ||
                              moment
                                .utc(args.event._instance.range.end)
                                .format('HH:mm') ==
                              args.event._def.extendedProps.earliest_start_time?.slice(
                                0,
                                -3
                              ) ||
                              moment
                                .utc(args.event._instance.range.end)
                                .format('HH:mm') ==
                              args.event._def.extendedProps.latest_start_time?.slice(
                                0,
                                -3
                              )
                            ) && <span><FiClock /></span>}
                          {args.event._def.extendedProps.is_published && (
                            <span><BsCheck /></span>
                          )}
                        <span>
                          {args.event._def.extendedProps.gender
                            ? args.event._def.extendedProps.gender.substring(
                              0,
                              1
                            )
                            : 'A'}
                        </span>
                        <span>
                          {args.event._def.extendedProps.priority_icon}
                        </span>
                        {args.event._def.extendedProps.isRegular && (
                          <span className="px-1">R</span>
                        )}
                        </div>
                        <span>{args.event._def.extendedProps.assigned_by}</span>
                      </div>
                      <div style={compactEventItemTitleStyle}>
                        {args.event._def.title.substring(0, 15) + (serviceCategory || '') }                      
                      </div>
                    </div>
                  </BootstrapTooltip>
                </>
              );
            } else if (args.event._def.extendedProps.travelMode && !args.event._def.extendedProps.tolerance) {
              const icon = args.event._def.extendedProps.travelMode;
              // console.log(args.event._def.title , (serviceCategory || '') ,"args.event._def.title")
              return (
                <>
                  <BootstrapTooltip
                    title={
                      <>
                        <div>
                          <span
                            style={{ fontWeight: 'bold', marginRight: '3px' }}>
                            {args.timeText
                              ? args.timeText
                              : moment
                                .utc(args.event._instance.range.start)
                                .format('HH:mm') +
                              '-' +
                              moment
                                .utc(args.event._instance.range.end)
                                .format('HH:mm')}
                          </span>
                          <span>{args.event._def.title}</span>
                        </div>
                        {args.event._def.extendedProps.visit_priority && 
                        <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Visit Priority: '}
                            </span>
                            {args.event._def.extendedProps.visit_priority}
                          </div>
                        </div>}
                        {args.event._def.extendedProps.service_category &&
                        <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Service Category: '}
                            </span>
                            {args.event._def.extendedProps.service_category}
                          </div>
                        </div>}
                        <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Travel Mode: '}
                            </span>
                            {args.event._def.extendedProps
                              .travel_mode_name ?
                              args.event._def.extendedProps.travel_mode_name : args.event._def.extendedProps.listViewTravelName ?? null}
                          </div>
                        </div>
                      </>
                    }
                    arrow>
                    <div 
                      style={{ display: 'flex'}}>
                        <div>
                        {args.timeText.replace(/ /g, '') && 
                          <span style={{ fontWeight: 'bold', marginRight: '3px' }}>
                            {args.timeText.replace(/ /g, '')}
                          </span>
                        }
                      <span>{args.event._def.extendedProps.priority_icon}</span>
                      {args.event._def.title !== 'Unavailable' && <>{icon}</>}
                        </div>
                      <div style={{ paddingLeft: "5px"}}>{args.event._def.title?.substring(0, 15) + (serviceCategory || '') }</div>
                    </div>
                  </BootstrapTooltip>
                </>
              );
            } else if (args.event._def.extendedProps.clientView && !args.event._def.extendedProps.tolerance) {
              const format = 'HH:mm';

              // var time = moment() gives you current time. no format required.
              let start_t = moment
                .utc(args.event._instance.range.start)
                .format('HH:mm');
              const time = moment(start_t, format);
              const beforeTime = moment(
                args.event._def.extendedProps.earliest_start_time,
                format
              );
              const afterTime = moment(
                args.event._def.extendedProps.latest_start_time,
                format
              );
              return (
                <>
                  <BootstrapTooltip
                    title={
                      <>
                        <div>
                          <span
                            style={{ fontWeight: 'bold', marginRight: '3px' }}>
                            {args.timeText
                              ? args.timeText
                              : moment
                                .utc(args.event._instance.range.start)
                                .format('HH:mm') +
                              '-' +
                              moment
                                .utc(args.event._instance.range.end)
                                .format('HH:mm')}
                          </span>
                          <span>{args.event._def.title}</span>
                        </div>
                        <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Gender: '}
                            </span>
                            {args.event._def.extendedProps.gender ?? 'All'}
                        </div>
                        {args.event._def.extendedProps.assigned_by &&
                        <div>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Assigned By: '}
                            </span>
                            {args.event._def.extendedProps.assigned_by}
                          </div>
                        </div>}
                        {args.event._def.extendedProps
                              .earliest_start_time &&
                        <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Earliest Start Time: '}
                            </span>
                            {args.event._def.extendedProps
                              .earliest_start_time &&
                              args.event._def.extendedProps.earliest_start_time.slice(
                                0,
                                -3
                              )}
                          </div>
                        </div>
                    }
                     {args.event._def.extendedProps.latest_start_time &&
                        <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Latest Start Time: '}
                            </span>
                            {args.event._def.extendedProps.latest_start_time &&
                              args.event._def.extendedProps.latest_start_time.slice(
                                0,
                                -3
                              )}
                          </div>
                        </div>
                    }
                    {args.event._def.extendedProps
                              .actual_visit_start_time &&
                        <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Clocked In Time: '}
                            </span>
                            {args.event._def.extendedProps
                              .actual_visit_start_time &&
                              moment(
                                args.event._def.extendedProps
                                  .actual_visit_start_time
                              ).format('HH:mm')}
                          </div>
                        </div>
                    }
                       {args.event._def.extendedProps
                              .actual_visit_end_time &&
                               <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Clocked Out Time: '}
                            </span>
                            {args.event._def.extendedProps
                              .actual_visit_end_time &&
                              moment(
                                args.event._def.extendedProps
                                  .actual_visit_end_time
                              ).format('HH:mm')}
                          </div>
                        </div>}
                      {args.event._def.extendedProps.visit_type &&
                        <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Visit Type: '}
                            </span>
                            {args.event._def.extendedProps.visit_type}
                          </div>
                        </div>}
                        {args.event._def.extendedProps.visit_priority && 
                        <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Visit Priority: '}
                            </span>
                            {args.event._def.extendedProps.visit_priority}
                          </div>
                        </div>}
                      {args.event._def.extendedProps.service_category &&
                        <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Service Category: '}
                            </span>
                            {args.event._def.extendedProps.service_category}
                          </div>
                        </div>}
                      {args.event._def.extendedProps.service_category_type &&
                        <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Service Category Type: '}
                            </span>
                            {args.event._def.extendedProps.service_category_type}
                          </div>
                        </div>}
                       {args.event._def.extendedProps.address &&
                        <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Address: '}
                            </span>
                            {args.event._def.extendedProps.address}
                          </div>
                        </div>}
                      {args.event._def.extendedProps.zipcode &&
                        <div>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Postal Code: '}
                            </span>
                            {args.event._def.extendedProps.zipcode}
                          </div>
                        </div>}
                        {args.event._def.extendedProps.is_published &&
                        <div>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {args.event._def.extendedProps.is_published
                                ? 'Published'
                                : 'Unpublished'}
                            </span>
                          </div>
                        </div>}
                       {args.event._def.extendedProps.employee_group_area &&
                        <div>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Employee Group Area: '}
                            </span>
                            {args.event._def.extendedProps.employee_group_area}
                          </div>
                        </div>}
                       {args.event._def.extendedProps.client_group_area &&
                        <div>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Client Group Area: '}
                            </span>
                            {args.event._def.extendedProps.client_group_area}
                          </div>
                        </div>}
                      </>
                    }
                    arrow>
                    <div className="d-flex flex-column"
                      style={compactEventItemStyle}>
                      <div className='d-flex justify-content-between'>
                        <div className='mr-1' style={{display: "flex", gap: "0.25rem"}}>
                        {args.timeText.replace(/ /g, '') && 
                          <span style={{ fontWeight: 'bold', marginRight: '3px' }}>
                            {args.timeText.replace(/ /g, '')}
                          </span>
                        }
                          {args.event._def.extendedProps.employeeId &&
                            beforeTime &&
                            afterTime &&
                            !(
                              time.isBetween(beforeTime, afterTime) ||
                              moment
                                .utc(args.event._instance.range.start)
                                .format('HH:mm') ==
                              args.event._def.extendedProps.earliest_start_time?.slice(
                                0,
                                -3
                              ) ||
                              moment
                                .utc(args.event._instance.range.start)
                                .format('HH:mm') ==
                              args.event._def.extendedProps.latest_start_time?.slice(
                                0,
                                -3
                              ) ||
                              moment
                                .utc(args.event._instance.range.end)
                                .format('HH:mm') ==
                              args.event._def.extendedProps.earliest_start_time?.slice(
                                0,
                                -3
                              ) ||
                              moment
                                .utc(args.event._instance.range.end)
                                .format('HH:mm') ==
                              args.event._def.extendedProps.latest_start_time?.slice(
                                0,
                                -3
                              )
                            ) && <span><FiClock /></span>}
                          {args.event._def.extendedProps.is_published && (
                            <span><BsCheck /></span>
                          )}
                        <span>
                          {args.event._def.extendedProps.gender
                            ? args.event._def.extendedProps.gender.substring(
                              0,
                              1
                            )
                            : 'A'}
                        </span>
                        <span>
                          {args.event._def.extendedProps.priority_icon}
                        </span>
                        {args.event._def.extendedProps.isRegular && (
                          <span className="px-1">R</span>
                        )}
                        </div>
                        <span>{args.event._def.extendedProps.assigned_by}</span>
                      </div>
                      <div style={compactEventItemTitleStyle}>
                        {args.event._def.title.substring(0, 15) + (serviceCategory || '') }                    
                      </div>
                    </div>
                  </BootstrapTooltip>
                </>
              );
            } else if (args.event._def.extendedProps.waittime && !args.event._def.extendedProps.tolerance) {
              return (
                <>
                  <BootstrapTooltip
                    title={<>
                      <div className="d-flex">
                          <span
                            style={{ fontWeight: 'bold', marginRight: '3px' }}>
                            {args.timeText
                              ? args.timeText
                              : moment
                                .utc(args.event._instance.range.start)
                                .format('HH:mm') +
                              '-' +
                              moment
                                .utc(args.event._instance.range.end)
                                .format('HH:mm')}
                          </span>
                          <span className='ml-1'>{args.event._def.title}</span>
                        </div>
                        {args.event._def.extendedProps.service_category &&
                        <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Service Category: '}
                            </span>
                            {args.event._def.extendedProps.service_category}
                          </div>
                        </div>}
                    </>}
                  >
                    <div style={compactEventItemStyle}>
                      {args.timeText.replace(/ /g, '') && 
                          <span style={{ fontWeight: 'bold', marginRight: '3px' }}>
                            {args.timeText.replace(/ /g, '')}
                          </span>
                        }
                      <span>{args.event._def.title + (serviceCategory || '') }</span>
                      <span>{args.event._def.title + (serviceCategory || '') }</span>
                    </div>
                  </BootstrapTooltip>
                </>
              );
            } else if (args.event._def.extendedProps.clocked && !args.event._def.extendedProps.tolerance) {
              const icon = args.event._def.extendedProps.icon;
              const format = 'HH:mm';

              // var time = moment() gives you current time. no format required.
              let start_t = moment
                .utc(args.event._instance.range.start)
                .format('HH:mm');
              const time = moment(start_t, format);
              const beforeTime = moment(
                args.event._def.extendedProps.earliest_start_time,
                format
              );
              const afterTime = moment(
                args.event._def.extendedProps.latest_start_time,
                format
              );
              return (
                <>
                  <BootstrapTooltip
                    title={
                      <>
                        <div style={{ display: 'flex' }}>
                          <span
                            style={{ fontWeight: 'bold', marginRight: '3px' }}>
                            {args.timeText
                              ? args.timeText
                              : moment
                                .utc(args.event._instance.range.start)
                                .format('HH:mm') +
                              '-' +
                              moment
                                .utc(args.event._instance.range.end)
                                .format('HH:mm')}
                          </span>
                          <span>{args.event._def.title}</span>
                        </div>
                        {args.event._def.extendedProps
                              .earliest_start_time && 
                              <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Earliest Start Time: '}
                            </span>
                            {args.event._def.extendedProps
                              .earliest_start_time &&
                              args.event._def.extendedProps.earliest_start_time.slice(
                                0,
                                -3
                              )}
                          </div>
                        </div>}
                       {args.event._def.extendedProps.latest_start_time && <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Latest Start Time: '}
                            </span>
                            {args.event._def.extendedProps.latest_start_time &&
                              args.event._def.extendedProps.latest_start_time.slice(
                                0,
                                -3
                              )}
                          </div>
                        </div>}
                        {args.event._def.extendedProps
                              .actual_visit_start_time && 
                              <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Clocked In Time: '}
                            </span>
                            {args.event._def.extendedProps
                              .actual_visit_start_time &&
                              moment(
                                args.event._def.extendedProps
                                  .actual_visit_start_time
                              ).format('HH:mm')}
                          </div>
                        </div>}
                        {args.event._def.extendedProps
                              .actual_visit_end_time && 
                              <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Clocked Out Time: '}
                            </span>
                            {args.event._def.extendedProps
                              .actual_visit_end_time &&
                              moment(
                                args.event._def.extendedProps
                                  .actual_visit_end_time
                              ).format('HH:mm')}
                          </div>
                        </div>}
                        {args.event._def.extendedProps.visit_type && 
                        <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Visit Type: '}
                            </span>
                            {args.event._def.extendedProps.visit_type}
                          </div>
                        </div>}
                       {args.event._def.extendedProps.service_category &&
                        <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Service Category: '}
                            </span>
                            {args.event._def.extendedProps.service_category}
                          </div>
                        </div>}
                       {args.event._def.extendedProps.run_view_name &&
                        <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Run Name: '}
                            </span>
                            {args.event._def.extendedProps.run_view_name}
                          </div>
                        </div>}
                      {args.event._def.extendedProps.address &&
                        <div style={{ display: 'flex' }}>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Address: '}
                            </span>
                            {args.event._def.extendedProps.address}
                          </div>
                        </div>}
                        {args.event._def.extendedProps.zipcode &&
                        <div>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Postal Code: '}
                            </span>
                            {args.event._def.extendedProps.zipcode}
                          </div>
                        </div>}
                       {args.event._def.extendedProps.is_published &&
                        <div>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {args.event._def.extendedProps.is_published
                                ? 'Published'
                                : 'Unpublished'}
                            </span>
                          </div>
                        </div>}
                       {args.event._def.extendedProps.employee_group_area &&
                        <div>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Employee Group Area: '}
                            </span>
                            {args.event._def.extendedProps.employee_group_area}
                          </div>
                        </div>}
                       {args.event._def.extendedProps.client_group_area &&
                        <div>
                          <div>
                            <span style={{ fontWeight: 'bold' }}>
                              {'Client Group Area: '}
                            </span>
                            {args.event._def.extendedProps.client_group_area}
                          </div>
                        </div>}
                      </>
                    }
                    arrow>
                     <div style={{borderTop:"2px solid black", height:"3px"}}>
                      {/* <span style={{ fontWeight: 'bold', marginRight: '3px' }}>
                        {args.timeText.replace(/ /g, '')}
                      </span> */}
                    </div>
                  </BootstrapTooltip>
                </>
              );
            } 
          }}
          resourceLabelContent={function (args) {
            if (calendarView === 'team' || rotaShift) {
              let employee_det =
                employeeVisitData &&
                employeeVisitData.find(
                  det => det.employee_id === args.resource._resource.id
                );
              return (
                <>
                  <BootstrapTooltip
                    title={
                      <>
                        <div style={{ fontSize: '13px' }}>
                          <span style={{ marginRight: '4px' }}>
                            Total Visits:
                          </span>
                          <span>{employee_det && employee_det.total_visits}</span>
                        </div>
                        <div style={{ fontSize: '13px' }}>
                          <span style={{ marginRight: '4px' }}>Visits Hrs:</span>
                          <span>
                            {employee_det && employee_det.visits_hrs }
                          </span>
                        </div>
                        <div style={{ fontSize: '13px' }}>
                          <span style={{ marginRight: '4px' }}>
                            Contracted Hrs:
                          </span>
                          <span>
                            {employee_det && employee_det.contracted_hrs}
                          </span>
                        </div>
                        <div style={{ fontSize: '13px' }}>
                          <span style={{ marginRight: '4px' }}>Desired Hrs:</span>
                          <span>
                            {employee_det && employee_det.desired_hrs}
                          </span>
                        </div>
                        <div style={{ fontSize: '13px' }}>
                          <span style={{ marginRight: '4px' }}>Travel Hrs:</span>
                          <span>
                            {employee_det && employee_det.travel_hrs}
                          </span>
                        </div>
                        <div style={{ fontSize: '13px' }}>
                          <span style={{ marginRight: '4px' }}>
                            Travel Distance:
                          </span>
                          <span>
                            {employee_det && employee_det.travel_distance}
                          </span>
                        </div>
                        {/* <div style={{ fontSize: '13px' }}>
                          <span style={{ marginRight: '4px' }}>Wait Hrs:</span>
                          <span>
                            {employee_det && employee_det.wait_hrs}
                          </span>
                        </div> */}
                        <div style={{ fontSize: '13px' }}>
                          <span style={{ marginRight: '4px' }}>
                            Remain Desired Wk Hrs:
                          </span>
                          {/* <span>{
                        employee_det && (
                          (employee_det.remaining_desired_hrs - Math.floor(employee_det.remaining_desired_hrs)) === 0
                            ? employee_det.remaining_desired_hrs : employee_det.remaining_desired_hrs
                        )
                      }</span> */}
                          <span>
                            {employee_det && employee_det.remain_desired_wk_hrs}
                          </span>
                        </div>
                        <div style={{ fontSize: '13px' }}>
                          <span style={{ marginRight: '4px' }}>
                            Remain Contrd Wk Hrs:
                          </span>
                          {/* <span>{
                        employee_det && (
                          (employee_det.remaining_contracted_desired_hrs - Math.floor(employee_det.remaining_contracted_desired_hrs)) === 0
                            ? employee_det.remaining_contracted_desired_hrs : employee_det.remaining_contracted_desired_hrs
                        )
                      }</span> */}
                          <span>
                            {employee_det &&  employee_det.remain_contrd_wk_hrs}
                          </span>
                        </div>
                        <div style={{ fontSize: '13px' }}>
                          <span style={{ marginRight: '4px' }}>
                            Default Travel Mode:
                          </span>
                          <span>
                            {employee_det && employee_det.default_travel_mode}
                          </span>
                        </div>
                        <div style={{ fontSize: '13px' }}>
                          <span style={{ marginRight: '4px' }}>
                            Phone Number:
                          </span>
                          <span>
                            {employee_det && employee_det.phone_number}
                          </span>
                        </div>
                      </>
                    }
                    arrow>
                    <div>{args.fieldValue}</div>
                  </BootstrapTooltip>
                </>
              );
            }
            if (calendarView === 'client') {
                let visit_hrs = 0;
              let total_visit_arr = schedulerData?.find((visit_d)=>visit_d.id == args.resource._resource.id)
              let total_visit_count = total_visit_arr?.visits?.length ;
              total_visit_arr && total_visit_arr.visits && total_visit_arr.visits.forEach((visit_item)=>{
                if(visit_item.end_date && visit_item.start_date){
                  let start_temp = moment(visit_item.start_date)
                  let end_temp = moment(visit_item.end_date)
                  let temp = end_temp.diff(start_temp)
                  visit_hrs = visit_hrs + Number(msToTime(temp))
                }
              })
              const comHrs = commHrsData.find((hrs_d)=>hrs_d.id == args.resource._resource.id)
              const remain_com_hrs = (comHrs.commissioned_hours_per_week || 0) - visit_hrs
              return (
                <>
                  <BootstrapTooltip
                    title={
                      <>
                        <div style={{ fontSize: '13px' }}>
                          <span style={{ marginRight: '4px' }}>
                            Total Visits:
                          </span>
                          <span>{total_visit_count || 0}</span>
                        </div>
                        <div style={{ fontSize: '13px' }}>
                          <span style={{ marginRight: '4px' }}>Visits Hrs:</span>
                          <span>
                           {visit_hrs?.toFixed(1) || 0}
                          </span>
                        </div>
                        <div style={{ fontSize: '13px' }}>
                          <span style={{ marginRight: '4px' }}>Commissioned Hrs:</span>
                          <span>
                           {comHrs?.commissioned_hours_per_week || 0}
                          </span>
                        </div>
                        <div style={{ fontSize: '13px' }}>
                          <span style={{ marginRight: '4px' }}>Remain Commissioned Hrs:</span>
                          <span>
                           {remain_com_hrs?.toFixed(1) || 0}
                          </span>
                        </div>
                      </>
                    }
                    arrow>
                    <div>{args.fieldValue}</div>
                  </BootstrapTooltip>
                </>
              );
            }
          }}
        />
      </CalendarContainer>
      {isSplitMode && (
        <CalendarContainer1
          className="App2"
          progress={showProgressSection}
          reduceColumns={shiftType === CALENDAR_WEEKLY}
        // view={CALENDAR_DAILY}
        >
          <FullCalendar
            schedulerLicenseKey={'0149351374-fcs-1640812113'}
            scrollTime={moment().subtract(1, 'hours').format('HH:00:00')}
            ref={el => (calendarRef.current[1] = el)}
            plugins={[timeLinePlugin, interactionPlugin, bootstrapPlugin]}
            droppable={true}
            dateClick={handleDateClick}
            eventClick={handleEventClick}
            // selectable={true}
            firstDay={1}
            // expandRows={true}
            height="100%"
            headerToolbar={{
              left: '',
              center: '',
              end: '',
            }}
            views={{
              resourceTimelineDay: {
                type: 'resourceTimelineDay',
                buttonText: 'Daily',
                slotDuration: '00:05:00',
                eventMinWidth: 1,
                slotMinWidth: 10,
              },

              resourceTimelineWeekDays: {
                type: 'resourceTimelineWeek',
                duration: { weeks: 1 },
                slotDuration: { days: 1 },
                slotLabelFormat: [
                  { month: 'long', year: 'numeric' }, // top level of text
                  {
                    day: 'numeric',
                    weekday: 'short',
                    omitCommas: true,
                  },
                ],
                buttonText: 'Weekly',
              },
            }}
            slotLabelFormat={{
              hour: 'numeric',
              minute: '2-digit',
              omitZeroMinute: false,
              hour12: false,
            }}
            eventTimeFormat={{
              hour: 'numeric',
              minute: '2-digit',
              hour12: false,
              // timeZoneName: 'short'
            }}
            nextDayThreshold={'06:00'}
            initialView={CALENDAR_DAILY}
            filterResourcesWithEvents={true}
            resourceOrder="title employee"
            resourceAreaHeaderContent={resourceAreaHeaderContent}
            resourceAreaWidth="8rem"
            resources={[...resources]}
            events={[...eventsValue]}
            themeSystem="bootstrap"
            viewClassNames={viewClassNames}
            lazyFetching={false}
            editable={editMode ? true : false}
            eventOrder={'start,orderEvent,allDay,title'}
            eventChange={event => handleVisitChange(event)}
            eventReceive={event => handleVisitChange(event)}
            //eventDragStop={remove}
            timeZone={timeZone ? timeZone : 'none'}
            nowIndicator={true}
            eventContent={function (args, createElement) {
              if (args.event._def.extendedProps.icon) {
                const icon = args.event._def.extendedProps.icon;
                return (
                  <>
                    <BootstrapTooltip
                      title={
                        <>
                          <div style={{ display: 'flex' }}>
                            <span
                              style={{ fontWeight: 'bold', marginRight: '3px' }}>
                              {args.timeText
                                ? args.timeText
                                : moment
                                  .utc(args.event._instance.range.start)
                                  .format('HH:mm') +
                                '-' +
                                moment
                                  .utc(args.event._instance.range.end)
                                  .format('HH:mm')}
                            </span>
                            <span>{args.event._def.title}</span>
                          </div>
                          <div style={{ display: 'flex' }}>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Earliest Start Time: '}
                              </span>
                              {args.event._def.extendedProps
                                .earliest_start_time &&
                                args.event._def.extendedProps.earliest_start_time.slice(
                                  0,
                                  -3
                                )}
                            </div>
                          </div>
                          <div style={{ display: 'flex' }}>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Latest Start Time: '}
                              </span>
                              {args.event._def.extendedProps
                                .latest_start_time &&
                                args.event._def.extendedProps.latest_start_time.slice(
                                  0,
                                  -3
                                )}
                            </div>
                          </div>
                          <div style={{ display: 'flex' }}>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Clocked In Time: '}
                              </span>
                              {args.event._def.extendedProps
                                .actual_visit_start_time &&
                                moment(
                                  args.event._def.extendedProps
                                    .actual_visit_start_time
                                ).format('HH:mm')}
                            </div>
                          </div>
                          <div style={{ display: 'flex' }}>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Clocked Out Time: '}
                              </span>
                              {args.event._def.extendedProps
                                .actual_visit_end_time &&
                                moment(
                                  args.event._def.extendedProps
                                    .actual_visit_end_time
                                ).format('HH:mm')}
                            </div>
                          </div>
                          <div style={{ display: 'flex' }}>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Visit Type: '}
                              </span>
                              {args.event._def.extendedProps.visit_type}
                            </div>
                          </div>
                          <div style={{ display: 'flex' }}>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Service Category: '}
                              </span>
                              {args.event._def.extendedProps.service_category}
                            </div>
                          </div>
                          <div style={{ display: 'flex' }}>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Service Category Type: '}
                              </span>
                              {args.event._def.extendedProps.service_category_type}
                            </div>
                          </div>
                          <div style={{ display: 'flex' }}>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Address: '}
                              </span>
                              {args.event._def.extendedProps.address}
                            </div>
                          </div>
                          <div>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Postal Code: '}
                              </span>
                              {args.event._def.extendedProps.zipcode}
                            </div>
                          </div>
                          <div>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Is Published: '}
                              </span>
                              {args.event._def.extendedProps.is_published
                                ? 'true'
                                : 'false'}
                            </div>
                          </div>
                        </>
                      }
                      arrow
                    >
                      <div className="d-flex">
                        <div>
                          <span
                            style={{ fontWeight: 'bold', marginRight: '3px' }}>
                            {args.timeText.replace(/ /g, '')}
                          </span>
                          <span>
                            {args.event._def.extendedProps.is_published ? (
                              <BsCheck />
                            ) : (
                              ''
                            )}
                          </span>

                          <span>
                            {args.event._def.extendedProps.priority_icon}
                          </span>
                          <span>{icon}</span>
                          {args.event._def.extendedProps.isRegular && (
                            <span>R</span>
                          )}
                          <span>{args.event._def.title.substring(0, 15) + args.event._def.extendedProps.service_category}</span>
                        </div>
                        <div>
                          <span>
                            {args.event._def.extendedProps.assigned_by}
                          </span>
                          <span>
                            {args.event._def.extendedProps.gender
                              ? args.event._def.extendedProps.gender.substring(
                                0,
                                1
                              )
                              : 'A'}
                          </span>
                          <span>
                            {!args.event._def.extendedProps
                              .earliest_start_time &&
                              !args.event._def.extendedProps
                                .latest_start_time && <FiClock />}
                          </span>
                        </div>
                      </div>
                    </BootstrapTooltip>
                  </>
                );
              } else if (
                args.event._def.extendedProps.typeVisit &&
                args.event._def.extendedProps.travelMode
              ) {
                const icon = args.event._def.extendedProps.travelMode;
                return (
                  <>
                    <BootstrapTooltip
                      title={
                        <>
                          <div style={{ display: 'flex' }}>
                            <span
                              style={{ fontWeight: 'bold', marginRight: '3px' }}>
                              {args.timeText
                                ? args.timeText
                                : moment
                                  .utc(args.event._instance.range.start)
                                  .format('HH:mm') +
                                '-' +
                                moment
                                  .utc(args.event._instance.range.end)
                                  .format('HH:mm')}
                            </span>
                            <span>{args.event._def.title}</span>
                          </div>
                         {args.event._def.extendedProps
                                .earliest_start_time &&
                                 <div style={{ display: 'flex' }}>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Earliest Start Time: '}
                              </span>
                              {args.event._def.extendedProps
                                .earliest_start_time &&
                                args.event._def.extendedProps.earliest_start_time.slice(
                                  0,
                                  -3
                                )}
                            </div>
                          </div>}
                        {args.event._def.extendedProps
                                .latest_start_time &&
                                  <div style={{ display: 'flex' }}>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Latest Start Time: '}
                              </span>
                              {args.event._def.extendedProps
                                .latest_start_time &&
                                args.event._def.extendedProps.latest_start_time.slice(
                                  0,
                                  -3
                                )}
                            </div>
                          </div>}
                          {args.event._def.extendedProps
                                .actual_visit_start_time && 
                                <div style={{ display: 'flex' }}>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Clocked In Time: '}
                              </span>
                              {args.event._def.extendedProps
                                .actual_visit_start_time &&
                                moment(
                                  args.event._def.extendedProps
                                    .actual_visit_start_time
                                ).format('HH:mm')}
                            </div>
                          </div>}
                          {args.event._def.extendedProps
                                .actual_visit_end_time && 
                                <div style={{ display: 'flex' }}>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Clocked Out Time: '}
                              </span>
                              {args.event._def.extendedProps
                                .actual_visit_end_time &&
                                moment(
                                  args.event._def.extendedProps
                                    .actual_visit_end_time
                                ).format('HH:mm')}
                            </div>
                          </div>}
                         {args.event._def.extendedProps.visit_type &&
                          <div style={{ display: 'flex' }}>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Visit Type: '}
                              </span>
                              {args.event._def.extendedProps.visit_type}
                            </div>
                          </div>}
                        {args.event._def.extendedProps.service_category &&
                          <div style={{ display: 'flex' }}>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Service Category: '}
                              </span>
                              {args.event._def.extendedProps.service_category}
                            </div>
                          </div>}
                         {args.event._def.extendedProps.service_category_type &&
                          <div style={{ display: 'flex' }}>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Service Category Type: '}
                              </span>
                              {args.event._def.extendedProps.service_category_type}
                            </div>
                          </div>}
                         {args.event._def.extendedProps.address &&
                          <div style={{ display: 'flex' }}>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Address: '}
                              </span>
                              {args.event._def.extendedProps.address}
                            </div>
                          </div>}
                         {args.event._def.extendedProps.zipcode &&
                          <div>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Postal Code: '}
                              </span>
                              {args.event._def.extendedProps.zipcode}
                            </div>
                          </div>}
                        {args.event._def.extendedProps.is_published &&
                          <div>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Is Published: '}
                              </span>
                              {args.event._def.extendedProps.is_published
                                ? 'true'
                                : 'false'}
                            </div>
                          </div>}
                        </>
                      }
                      arrow>
                      <div>
                        <span style={{ fontWeight: 'bold', marginRight: '3px' }}>
                          {args.timeText.replace(/ /g, '')}
                        </span>
                        <span>
                          {args.event._def.extendedProps.gender
                            ? args.event._def.extendedProps.gender.substring(
                              0,
                              1
                            )
                            : 'A'}
                        </span>
                        <span>
                          {args.event._def.extendedProps.priority_icon}
                        </span>
                        {args.event._def.title !== 'Unavailable' && <>{icon}</>}
                        <span>{args.event._def.title}</span>
                      </div>
                    </BootstrapTooltip>
                  </>
                );
              } else if (args.event._def.extendedProps.typeVisit) {
                return (
                  <>
                    <BootstrapTooltip
                      title={
                        <>
                          <div>
                            <span
                              style={{ fontWeight: 'bold', marginRight: '3px' }}>
                              {args.timeText
                                ? args.timeText
                                : moment
                                  .utc(args.event._instance.range.start)
                                  .format('HH:mm') +
                                '-' +
                                moment
                                  .utc(args.event._instance.range.end)
                                  .format('HH:mm')}
                            </span>
                            <span>{args.event._def.title}</span>
                          </div>
                          <div style={{ display: 'flex' }}>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Earliest Start Time: '}
                              </span>
                              {args.event._def.extendedProps
                                .earliest_start_time &&
                                args.event._def.extendedProps.earliest_start_time.slice(
                                  0,
                                  -3
                                )}
                            </div>
                          </div>
                          <div style={{ display: 'flex' }}>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Latest Start Time: '}
                              </span>
                              {args.event._def.extendedProps
                                .latest_start_time &&
                                args.event._def.extendedProps.latest_start_time.slice(
                                  0,
                                  -3
                                )}
                            </div>
                          </div>
                          <div style={{ display: 'flex' }}>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Clocked In Time: '}
                              </span>
                              {args.event._def.extendedProps
                                .actual_visit_start_time &&
                                moment(
                                  args.event._def.extendedProps
                                    .actual_visit_start_time
                                ).format('HH:mm')}
                            </div>
                          </div>
                          <div style={{ display: 'flex' }}>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Clocked Out Time: '}
                              </span>
                              {args.event._def.extendedProps
                                .actual_visit_end_time &&
                                moment(
                                  args.event._def.extendedProps
                                    .actual_visit_end_time
                                ).format('HH:mm')}
                            </div>
                          </div>
                          <div style={{ display: 'flex' }}>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Visit Type: '}
                              </span>
                              {args.event._def.extendedProps.visit_type}
                            </div>
                          </div>
                          <div style={{ display: 'flex' }}>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Service Category: '}
                              </span>
                              {args.event._def.extendedProps.service_category}
                            </div>
                          </div>
                          <div style={{ display: 'flex' }}>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Service Category Type: '}
                              </span>
                              {args.event._def.extendedProps.service_category_type}
                            </div>
                          </div>
                          <div style={{ display: 'flex' }}>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Address: '}
                              </span>
                              {args.event._def.extendedProps.address}
                            </div>
                          </div>
                          <div>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Postal Code: '}
                              </span>
                              {args.event._def.extendedProps.zipcode}
                            </div>
                          </div>
                          <div>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Is Published: '}
                              </span>
                              {args.event._def.extendedProps.is_published
                                ? 'true'
                                : 'false'}
                            </div>
                          </div>
                        </>
                      }
                      arrow>
                      <div className="d-flex">
                        <div>
                          <span
                            style={{ fontWeight: 'bold', marginRight: '3px' }}>
                            {args.timeText.replace(/ /g, '')}
                          </span>
                          <span>
                            {args.event._def.extendedProps.is_published ? (
                              <BsCheck />
                            ) : (
                              ''
                            )}
                          </span>

                          <span>
                            {args.event._def.extendedProps.priority_icon}
                          </span>
                          {args.event._def.extendedProps.isRegular && (
                            <span>R</span>
                          )}
                          <span>{args.event._def.title}</span>
                        </div>
                        <div>
                          <span>
                            {args.event._def.extendedProps.assigned_by}
                          </span>
                          <span>
                            {args.event._def.extendedProps.gender
                              ? args.event._def.extendedProps.gender.substring(
                                0,
                                1
                              )
                              : 'A'}
                          </span>
                          <span>
                            {!args.event._def.extendedProps
                              .earliest_start_time &&
                              !args.event._def.extendedProps
                                .latest_start_time && <FiClock />}
                          </span>
                        </div>
                      </div>
                    </BootstrapTooltip>
                  </>
                );
              } else if (args.event._def.extendedProps.travelMode) {
                const icon = args.event._def.extendedProps.travelMode;
                return (
                  <>
                    <BootstrapTooltip
                      title={
                        <>
                          <div>
                            <span
                              style={{ fontWeight: 'bold', marginRight: '3px' }}>
                              {args.timeText
                                ? args.timeText
                                : moment
                                  .utc(args.event._instance.range.start)
                                  .format('HH:mm') +
                                '-' +
                                moment
                                  .utc(args.event._instance.range.end)
                                  .format('HH:mm')}
                            </span>
                            <span>{args.event._def.title}</span>
                          </div>
                          <div style={{ display: 'flex' }}>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Earliest Start Time: '}
                              </span>
                              {args.event._def.extendedProps
                                .earliest_start_time &&
                                args.event._def.extendedProps.earliest_start_time.slice(
                                  0,
                                  -3
                                )}
                            </div>
                          </div>
                          <div style={{ display: 'flex' }}>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Latest Start Time: '}
                              </span>
                              {args.event._def.extendedProps
                                .latest_start_time &&
                                args.event._def.extendedProps.latest_start_time.slice(
                                  0,
                                  -3
                                )}
                            </div>
                          </div>
                          <div style={{ display: 'flex' }}>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Clocked In Time: '}
                              </span>
                              {args.event._def.extendedProps
                                .actual_visit_start_time &&
                                moment(
                                  args.event._def.extendedProps
                                    .actual_visit_start_time
                                ).format('HH:mm')}
                            </div>
                          </div>
                          <div style={{ display: 'flex' }}>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Clocked Out Time: '}
                              </span>
                              {args.event._def.extendedProps
                                .actual_visit_end_time &&
                                moment(
                                  args.event._def.extendedProps
                                    .actual_visit_end_time
                                ).format('HH:mm')}
                            </div>
                          </div>
                          <div style={{ display: 'flex' }}>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Visit Type: '}
                              </span>
                              {args.event._def.extendedProps.visit_type}
                            </div>
                          </div>
                          <div style={{ display: 'flex' }}>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Service Category: '}
                              </span>
                              {args.event._def.extendedProps.service_category}
                            </div>
                          </div>
                          <div style={{ display: 'flex' }}>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Service Category Type: '}
                              </span>
                              {args.event._def.extendedProps.service_category_type}
                            </div>
                          </div>
                          <div style={{ display: 'flex' }}>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Address: '}
                              </span>
                              {args.event._def.extendedProps.address}
                            </div>
                          </div>
                          <div>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Postal Code: '}
                              </span>
                              {args.event._def.extendedProps.zipcode}
                            </div>
                          </div>
                          <div>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Is Published: '}
                              </span>
                              {args.event._def.extendedProps.is_published
                                ? 'true'
                                : 'false'}
                            </div>
                          </div>
                        </>
                      }
                      arrow>
                      <div>
                        <span style={{ fontWeight: 'bold', marginRight: '3px' }}>
                          {args.timeText.replace(/ /g, '')}
                        </span>
                        <span>
                          {args.event._def.extendedProps.gender
                            ? args.event._def.extendedProps.gender.substring(
                              0,
                              1
                            )
                            : 'A'}
                        </span>
                        <span>
                          {args.event._def.extendedProps.priority_icon}
                        </span>
                        {args.event._def.title !== 'Unavailable' && <>{icon}</>}
                        <span>{args.event._def.title.substring(0, 15)}</span>
                      </div>
                    </BootstrapTooltip>
                  </>
                );
              } else if (args.event._def.extendedProps.clientView) {
                return (
                  <>
                    <BootstrapTooltip
                      title={
                        <>
                          <div>
                            <span
                              style={{ fontWeight: 'bold', marginRight: '3px' }}>
                              {args.timeText
                                ? args.timeText
                                : moment
                                  .utc(args.event._instance.range.start)
                                  .format('HH:mm') +
                                '-' +
                                moment
                                  .utc(args.event._instance.range.end)
                                  .format('HH:mm')}
                            </span>
                            <span>{args.event._def.title}</span>
                          </div>
                          <div style={{ display: 'flex' }}>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Earliest Start Time: '}
                              </span>
                              {args.event._def.extendedProps
                                .earliest_start_time &&
                                args.event._def.extendedProps.earliest_start_time.slice(
                                  0,
                                  -3
                                )}
                            </div>
                          </div>
                          <div style={{ display: 'flex' }}>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Latest Start Time: '}
                              </span>
                              {args.event._def.extendedProps
                                .latest_start_time &&
                                args.event._def.extendedProps.latest_start_time.slice(
                                  0,
                                  -3
                                )}
                            </div>
                          </div>
                          <div style={{ display: 'flex' }}>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Clocked In Time: '}
                              </span>
                              {args.event._def.extendedProps
                                .actual_visit_start_time &&
                                moment(
                                  args.event._def.extendedProps
                                    .actual_visit_start_time
                                ).format('HH:mm')}
                            </div>
                          </div>
                          <div style={{ display: 'flex' }}>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Clocked Out Time: '}
                              </span>
                              {args.event._def.extendedProps
                                .actual_visit_end_time &&
                                moment(
                                  args.event._def.extendedProps
                                    .actual_visit_end_time
                                ).format('HH:mm')}
                            </div>
                          </div>
                          <div style={{ display: 'flex' }}>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Visit Type: '}
                              </span>
                              {args.event._def.extendedProps.visit_type}
                            </div>
                          </div>
                          <div style={{ display: 'flex' }}>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Service Category: '}
                              </span>
                              {args.event._def.extendedProps.service_category}
                            </div>
                          </div>
                          <div style={{ display: 'flex' }}>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Service Category Type: '}
                              </span>
                              {args.event._def.extendedProps.service_category_type}
                            </div>
                          </div>
                          <div style={{ display: 'flex' }}>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Address: '}
                              </span>
                              {args.event._def.extendedProps.address}
                            </div>
                          </div>
                          <div>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Postal Code: '}
                              </span>
                              {args.event._def.extendedProps.zipcode}
                            </div>
                          </div>
                          <div>
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {'Is Published: '}
                              </span>
                              {args.event._def.extendedProps.is_published
                                ? 'true'
                                : 'false'}
                            </div>
                          </div>
                        </>
                      }
                      arrow>
                      <div className="d-flex">
                        <div>
                          <span
                            style={{ fontWeight: 'bold', marginRight: '3px' }}>
                            {args.timeText.replace(/ /g, '')}
                          </span>
                          <span>
                            {args.event._def.extendedProps.is_published ? (
                              <BsCheck />
                            ) : (
                              ''
                            )}
                          </span>

                          <span>
                            {args.event._def.extendedProps.priority_icon}
                          </span>
                          {args.event._def.extendedProps.isRegular && (
                            <span>R</span>
                          )}
                          <span>{args.event._def.title}</span>
                        </div>
                        <div>
                          <span>
                            {args.event._def.extendedProps.assigned_by}
                          </span>
                          <span>
                            {args.event._def.extendedProps.gender
                              ? args.event._def.extendedProps.gender.substring(
                                0,
                                1
                              )
                              : 'A'}
                          </span>
                          <span>
                            {!args.event._def.extendedProps
                              .earliest_start_time &&
                              !args.event._def.extendedProps
                                .latest_start_time && <FiClock />}
                          </span>
                        </div>
                      </div>
                    </BootstrapTooltip>
                  </>
                );
              } else if (args.event._def.extendedProps.waittime) {
                return (
                  <>
                    <BootstrapTooltip>
                      <div>
                        <span style={{ fontWeight: 'bold', marginRight: '3px' }}>
                          {args.timeText.replace(/ /g, '')}
                        </span>
                        <span>{args.event._def.title}</span>
                      </div>
                    </BootstrapTooltip>
                  </>
                );
              }
            }}
            resourceLabelContent={function (args) {
              if (calendarView === 'team' || rotaShift) {
                let employee_det =
                  employeeVisitData &&
                  employeeVisitData.find(
                    det => det.employee_id === args.resource._resource.id
                  );
                return (
                  <>
                    <BootstrapTooltip
                      title={
                        <>
                          <div style={{ fontSize: '13px' }}>
                            <span style={{ marginRight: '4px' }}>
                              Total Visits:
                            </span>
                            <span>
                              {employee_det && employee_det.no_visits}
                            </span>
                          </div>
                          <div style={{ fontSize: '13px' }}>
                            <span style={{ marginRight: '4px' }}>
                              Visits Hrs:
                            </span>
                            <span>
                              {employee_det &&
                                (employee_det.total_visit_hours -
                                  Math.floor(employee_det.total_visit_hours) ===
                                  0
                                  ? employee_det.total_visit_hours
                                  : employee_det.total_visit_hours.toFixed(2))}
                            </span>
                          </div>
                          <div style={{ fontSize: '13px' }}>
                            <span style={{ marginRight: '4px' }}>
                              Contracted Hrs:
                            </span>
                            <span>
                              {employee_det &&
                                (employee_det.contracted_no_hours -
                                  Math.floor(
                                    employee_det.contracted_no_hours
                                  ) ===
                                  0
                                  ? employee_det.contracted_no_hours
                                  : employee_det.contracted_no_hours.toFixed(
                                    2
                                  ))}
                            </span>
                          </div>
                          <div style={{ fontSize: '13px' }}>
                            <span style={{ marginRight: '4px' }}>
                              Desired Hrs:
                            </span>
                            <span>
                              {employee_det &&
                                (employee_det.max_hour_per_week -
                                  Math.floor(employee_det.max_hour_per_week) ===
                                  0
                                  ? employee_det.max_hour_per_week
                                  : employee_det.max_hour_per_week.toFixed(2))}
                            </span>
                          </div>
                          <div style={{ fontSize: '13px' }}>
                            <span style={{ marginRight: '4px' }}>
                              Travel Hrs:
                            </span>
                            <span>
                              {employee_det &&
                                (employee_det.schedule_travel_time -
                                  Math.floor(
                                    employee_det.schedule_travel_time
                                  ) ===
                                  0
                                  ? employee_det.schedule_travel_time
                                  : employee_det.schedule_travel_time.toFixed(
                                    2
                                  ))}
                            </span>
                          </div>
                          <div style={{ fontSize: '13px' }}>
                            <span style={{ marginRight: '4px' }}>
                              Travel Distance:
                            </span>
                            <span>
                              {employee_det &&
                                (employee_det.actual_travel_miles -
                                  Math.floor(
                                    employee_det.actual_travel_miles
                                  ) ===
                                  0
                                  ? employee_det.actual_travel_miles
                                  : employee_det.actual_travel_miles.toFixed(
                                    2
                                  ))}
                            </span>
                          </div>
                          <div style={{ fontSize: '13px' }}>
                            <span style={{ marginRight: '4px' }}>Wait Hrs:</span>
                            <span>
                              {employee_det &&
                                (employee_det.duration -
                                  Math.floor(employee_det.duration) ===
                                  0
                                  ? employee_det.duration
                                  : employee_det.duration.toFixed(2))}
                            </span>
                          </div>
                          <div style={{ fontSize: '13px' }}>
                            <span style={{ marginRight: '4px' }}>
                              Remain Desired Wk Hrs:
                            </span>
                            <span>
                              {employee_det &&
                                (employee_det.remaining_desired_hrs -
                                  Math.floor(
                                    employee_det.remaining_desired_hrs
                                  ) ===
                                  0
                                  ? employee_det.remaining_desired_hrs
                                  : employee_det.remaining_desired_hrs.toFixed(
                                    2
                                  ))}
                            </span>
                          </div>
                          <div style={{ fontSize: '13px' }}>
                            <span style={{ marginRight: '4px' }}>
                              Remain Contrd Wk Hrs:
                            </span>
                            <span>
                              {employee_det &&
                                (employee_det.remaining_contracted_desired_hrs -
                                  Math.floor(
                                    employee_det.remaining_contracted_desired_hrs
                                  ) ===
                                  0
                                  ? employee_det.remaining_contracted_desired_hrs
                                  : employee_det.remaining_contracted_desired_hrs.toFixed(
                                    2
                                  ))}
                            </span>
                          </div>
                        </>
                      }
                      arrow>
                      <div>{args.fieldValue}</div>
                    </BootstrapTooltip>
                  </>
                );
              }
              if (calendarView === 'client') {
                let client_det =
                  clinetVisitData &&
                  clinetVisitData.find(
                    det => det.id === args.resource._resource.id
                  );
                return (
                  <>
                    <BootstrapTooltip
                      title={
                        <>
                          <div style={{ fontSize: '13px' }}>
                            <span style={{ marginRight: '4px' }}>
                              Total Visits:
                            </span>
                            <span>{client_det && client_det.visit_count}</span>
                          </div>
                          <div style={{ fontSize: '13px' }}>
                            <span style={{ marginRight: '4px' }}>
                              Visits Hrs:
                            </span>
                            <span>
                              {client_det &&
                                (client_det.visit_hours -
                                  Math.floor(client_det.visit_hours) ===
                                  0
                                  ? client_det.visit_hours
                                  : client_det.visit_hours.toFixed(2))}
                            </span>
                          </div>
                        </>
                      }
                      arrow>
                      <div>{args.fieldValue}</div>
                    </BootstrapTooltip>
                  </>
                );
              }
            }}
            loading={loading}
          />
        </CalendarContainer1>
      )}
    </Fragment>
  );
};

export default TimelineCalendar;