import { apiURL, apiURLReplica } from '../env';
import { get, post, deleteFetch, patch } from './Api';
import queryString from 'query-string';
import { methods, unauthorized_status_code } from 'utils/constants/api';
import { clearToken } from '../localStorage/token';
import { PAGE_SIZE_RENDER, PER_PAGE_TEN } from 'utils/constants/pagination';
import { CALENDAR_WEEKLY } from 'utils/constants/queryParams';
import { getTenant, getToken } from '../localStorage/token';
import moment from 'moment';

const { POST, PATCH, DELETE } = methods;
const getBaseUrl = () => {
  let tenant = getTenant();
  return `${apiURL}/${tenant}/api/v1`;
};
const getBaseUrl2 = () => {
  let tenant = getTenant();
  return `${apiURL}/${tenant}/api/v2`;
};
const getBaseUrl4 = () => {
  let tenant = getTenant();
  return `${apiURL}/${tenant}/api/v4`;
};
const getBaseUrlMicroServices = () => {
  let tenant = getTenant();
  return `${apiURLReplica}/${tenant}/api/v1`;
};
export const getScheduler = (values, exclude, pageSize) => {
  let toParse = {};
  let filterParse = {};
  const baseUrl = getBaseUrl4();
  if (values.employees || values.employees) {
    if (values.employees) {
      let employeeFilter = values.employees.map(data => data.id);
      if (employeeFilter.find(emp => emp == 'none')) {
        let strEmployeeFilter = employeeFilter
          .filter(emp => emp != 'none')
          .join(',');
        filterParse.employees = strEmployeeFilter;
        filterParse.unassigned = true;
      } else {
        let strEmployeeFilter = employeeFilter.join(',');
        filterParse.employees = strEmployeeFilter;
      }
    } else {
      let employeeFilter = values.employees.map(data => data.id);
      let strEmployeeFilter = employeeFilter.join(',');
      filterParse.employees = strEmployeeFilter;
    }
  }
  console.log(values,"valuesvalues")
  if (values.client) {
    let clientFilter = values.client.map(data => data.id);
    let strClientFilter = clientFilter.join(',');
    filterParse.clients = strClientFilter;
  }
  if (values?.location) {
    let locationFilter = values?.location.map(data => data.id);
    let strLocationFilter = locationFilter.join(',');
    filterParse.locations = strLocationFilter;
  }
  if (values.service_category) {
    let serviceFilter = values.service_category.map(data => data.id);
    let strServiceFilter = serviceFilter.join(',');
    filterParse.service_category = strServiceFilter;
  }
  if (values.service_category_type) {
    let serviceCategoryFilter = values.service_category_type.map(data => data.id);
    let strServiceCategoryFilter = serviceCategoryFilter.join(',');
    filterParse.service_category_type = strServiceCategoryFilter;
  }
  if (values.funder_name) {
    let funder_client_filter = values.funder_name.filter(data => data.is_client);
    let serviceFilter = funder_client_filter.map(data => data.id);
    let strServiceFilter = serviceFilter.join(',');
    filterParse.funder_client_name = strServiceFilter;
  }
  if (values.funder_name) {
    let funder_filter = values.funder_name.filter(data => !data.is_client);
    let serviceFilter = funder_filter.map(data => data.id);
    let strServiceFilter = serviceFilter.join(',');
    filterParse.funder_name = strServiceFilter;
  }

  if (values.funder_category) {
    let funder_cat_filter = values.funder_category.filter(data => data.id !== 'none');
    let serviceFilter = funder_cat_filter.map(data => data.id);
    let strServiceFilter = serviceFilter.join(',');
    filterParse.funder_category = strServiceFilter;
  }

  if (values.funder_category) {
    let funder_cat_filter = values.funder_category.filter(data => data.id == 'none');
    if (funder_cat_filter.length > 0) {
      filterParse.null_funder_category = true;
    }
  }

  // if (values.runroutes) {
  //   let runRouteFilter=values.runroutes.map(data=>data.id)
  //   let strRunRouteFilter=runRouteFilter.join(",")
  //   filterParse.runroute = strRunRouteFilter;
  // }

  if (values.runroutesclient) {
    let runRouteFilter = values.runroutesclient.map(data => data.id);
    let strRunRouteFilter = runRouteFilter.join(',');
    filterParse.runrouteclient = strRunRouteFilter;
  }
  if (values.runroutesteam) {
    let runRouteFilter = values.runroutesteam.map(data => data.id);
    let strRunRouteFilter = runRouteFilter.join(',');
    filterParse.runrouteteam = strRunRouteFilter;
  }
  if (values.jobDepartment) {
    let jobDepartmentFilter = values.jobDepartment.map(data => data.id);
    let strJobDepartmentFilter = jobDepartmentFilter.join(',');
    filterParse.jobDepartment = strJobDepartmentFilter;
  }

  if (values.branch) {
    let branchFilter = values.branch.map(data => data.id);
    let strbranchFilter = branchFilter.join(',');
    filterParse.branch = strbranchFilter;
  }
  if (values.headerbranch) {
    let branchFilter = values.headerbranch.map(data => data.id);
    let strheaderbranchFilter = branchFilter.join(',');
    filterParse.headerbranch = strheaderbranchFilter;
  }

  if (values.visitStatus) {
    let visitStatusFilter = values.visitStatus.map(data => data.id);
    let strVisitStatusFilter = visitStatusFilter.join(',');
    filterParse.visitStatus = strVisitStatusFilter;
  }
  if (values.run_view_name) {
    let runNameFilter = values.run_view_name.map(data => data.id);
    let strRunNameFilter = runNameFilter.join(',');
    filterParse.run_view_name = strRunNameFilter;
  }

  if (values.availability !== '') {
    toParse.is_available = values.availability;
  }
  if (values.visit_publish) {
    filterParse.visit_publish = values.visit_publish;
  }

  
  if (values.doubleUp) {
    filterParse.doubleUp = values.doubleUp
  }

  toParse.limit = pageSize || PAGE_SIZE_RENDER;

  const new_start_date = new Date(
    moment(values.start_date).format('YYYY-MM-DD')
  );
  const new_end_date = new Date(moment(values.end_date).format('YYYY-MM-DD'));
  let conditionString = '';
  let datesRange = '';
  // start_date to utc and backend required format
  datesRange =
    'start_date=' + encodeURIComponent(new_start_date.getUTCString());

  // end_date to utc and backend required format and concat to start_date
  datesRange =
    datesRange + '&end_date=' + encodeURIComponent(new_end_date.getUTCString());

  conditionString += datesRange;
  if (filterParse.employees) {
    conditionString += `${exclude.employee ? '&exclude_employee=' : '&employees='}` + filterParse.employees;
  }
  if (filterParse.clients) {
    conditionString += `${exclude.client ? '&exclude_client=' : '&clients='}` + filterParse.clients;
  }
  if (filterParse.locations) {
    conditionString += `${exclude.location ? '&exclude_location=' : '&locations='}` + filterParse.locations;
  }
  if (filterParse.service_category) {
    conditionString += '&service_category=' + filterParse.service_category;
  }
  if( filterParse.service_category_type){
    conditionString += '&service_category_type=' + filterParse.service_category_type;
  }
  if (filterParse.funder_client_name) {
    conditionString += '&funder_client_name=' + filterParse.funder_client_name;
  }
  if (filterParse.funder_name) {
    conditionString += '&funder_name=' + filterParse.funder_name;
  }
  if (filterParse.funder_category) {
    conditionString += '&funder_category=' + filterParse.funder_category;
  }
  if (filterParse.null_funder_category) {
    conditionString += '&null_funder_category=' + 'true';
  }
  const check_unassigned = exclude.employee ? true : filterParse.unassigned;
  if (check_unassigned) {
    conditionString += '&unassigned=' + (exclude.employee ? !(filterParse.unassigned ? true : false) : filterParse.unassigned);
  }

  if (filterParse.runrouteclient) {
    conditionString += '&run_route_client=' + filterParse.runrouteclient;
  }

  if (filterParse.runrouteteam) {
    conditionString += '&run_route_employee=' + filterParse.runrouteteam;
  }

  if (filterParse.jobDepartment) {
    conditionString += '&job_category=' + filterParse.jobDepartment;
  }
  if (filterParse.visitStatus) {
    conditionString += '&visit_status=' + filterParse.visitStatus;
  }
  if (filterParse.run_view_name) {
    conditionString += '&run_view_name=' + filterParse.run_view_name;
  }
  if (filterParse.visit_publish) {
    conditionString += '&isPublished=' + ((filterParse.visit_publish === 'publish') ? 'true' : 'false');
  }
  if(filterParse.doubleUp){
    conditionString += `&doubleUp=${filterParse.doubleUp}`
  }
  if (filterParse.branch) {
    if (filterParse.branch.includes('None')) {
      conditionString += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.branch;
      const branchIdsWithoutNoneId = branchItemsIds
        .split(',')
        .filter(e => e !== 'None')
        .join(',');
      if (branchIdsWithoutNoneId)
        conditionString += '&branch_id=' + branchIdsWithoutNoneId;
    } else {
      conditionString += '&branch_id=' + filterParse.branch;
    }
  } else if (filterParse.headerbranch) {
    if (filterParse.headerbranch.includes('None')) {
      conditionString += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.headerbranch;
      const branchIdsWithoutNoneId = branchItemsIds
        .split(',')
        .filter(e => e !== 'None')
        .join(',');
      if (branchIdsWithoutNoneId)
        conditionString += '&branch_id=' + branchIdsWithoutNoneId;
    } else {
      conditionString += '&branch_id=' + filterParse.headerbranch;
    }
  }
  conditionString += '&view=Web';
  let url = '';
  if (values.shiftType === CALENDAR_WEEKLY) {
    url = `${baseUrl}/scheduler/visits/weekly/?${conditionString}`;
  } else {
    url = `${baseUrl}/scheduler/visits/daily/?${conditionString}`;
  }

  return get(url);
};
export const getWaitTime = (values, pageSize) => {
  let stringified = '';
  let toParse = {};
  let filterParse = {};
  const baseUrl = getBaseUrl();
  if (values.employees || values.employees) {
    if (values.employees) {
      let employeeFilter = values.employees.map(data => data.id);
      let strEmployeeFilter = employeeFilter
        .filter(emp => emp != 'none')
        .join(',');
      filterParse.employeeIds = strEmployeeFilter;
    } else {
      let employeeFilter = values.employees.map(data => data.id);
      let strEmployeeFilter = employeeFilter
        .filter(emp => emp != 'none')
        .join(',');
      filterParse.employeeIds = strEmployeeFilter;
    }
  }
  // if (values.client) {
  //   let clientFilter=values.client.map(data=>data.id)
  //   let strClientFilter=clientFilter.join(",")
  //   filterParse.clients = strClientFilter;
  // }
  // if (values.visits) {
  //   toParse.visits = values.visits;
  // }
  // if (values.runroutes) {
  //   let runRouteFilter=values.runroutes.map(data=>data.id)
  //   let strRunRouteFilter=runRouteFilter.join(",")
  //   filterParse.runrouteIds = strRunRouteFilter;
  // }
  if (values.runroutesclient) {
    let runRouteFilter = values.runroutesclient.map(data => data.id);
    let strRunRouteFilter = runRouteFilter.join(',');
    filterParse.runrouteIdsclient = strRunRouteFilter;
  }
  if (values.runroutesteam) {
    let runRouteFilter = values.runroutesteam.map(data => data.id);
    let strRunRouteFilter = runRouteFilter.join(',');
    filterParse.runrouteIdsteam = strRunRouteFilter;
  }
  if (values.jobDepartment) {
    let jobDepartmentFilter = values.jobDepartment.map(data => data.id);
    let strJobDepartmentFilter = jobDepartmentFilter.join(',');
    filterParse.jobDepartmentIds = strJobDepartmentFilter;
  }

  if (values.branch) {
    let branchFilter = values.branch.map(data => data.id);
    let strbranchFilter = branchFilter.join(',');
    filterParse.branch = strbranchFilter;
  }
  if (values.headerbranch) {
    let branchFilter = values.headerbranch.map(data => data.id);
    let strheaderbranchFilter = branchFilter.join(',');
    filterParse.headerbranch = strheaderbranchFilter;
  }
  if (values.visitStatus) {
    let visitStatusFilter = values.visitStatus.map(data => data.id);
    let strVisitStatusFilter = visitStatusFilter.join(',');
    filterParse.visitStatusIds = strVisitStatusFilter;
  }

  if (values.availability !== '') {
    toParse.is_available = values.availability;
  }

  toParse.limit = pageSize || PAGE_SIZE_RENDER;

  const new_start_date = new Date(values.start_date);
  const new_end_date = new Date(values.end_date);
  let conditionString = '';
  let datesRange = '';

  datesRange = 'startDate=' + new Date(values.start_date).getTime();

  datesRange = datesRange + '&endDate=' + new Date(values.end_date).getTime();
  conditionString += datesRange;
  if (filterParse.employeeIds) {
    conditionString += '&employeeIds=' + filterParse.employeeIds;
  }
  // if(filterParse.runrouteIds){
  //   conditionString+='&run_route='+filterParse.runrouteIds

  // }

  if (filterParse.runrouteIdsclient) {
    conditionString += '&run_route_client=' + filterParse.runrouteIdsclient;
  }

  if (filterParse.runrouteIdsteam) {
    conditionString += '&run_route_employee=' + filterParse.runrouteIdsteam;
  }

  if (filterParse.jobDepartmentIds) {
    conditionString += '&job_category=' + filterParse.jobDepartmentIds;
  }

  if (filterParse.branch) {
    if (filterParse.branch.includes('None')) {
      conditionString += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.branch;
      const branchIdsWithoutNoneId = branchItemsIds
        .split(',')
        .filter(e => e !== 'None')
        .join(',');
      if (branchIdsWithoutNoneId)
        conditionString += '&branch_id=' + branchIdsWithoutNoneId;
    } else {
      conditionString += '&branch_id=' + filterParse.branch;
    }
  } else if (filterParse.headerbranch) {
    if (filterParse.headerbranch.includes('None')) {
      conditionString += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.headerbranch;
      const branchIdsWithoutNoneId = branchItemsIds
        .split(',')
        .filter(e => e !== 'None')
        .join(',');
      if (branchIdsWithoutNoneId)
        conditionString += '&branch_id=' + branchIdsWithoutNoneId;
    } else {
      conditionString += '&branch_id=' + filterParse.headerbranch;
    }
  }

  if (filterParse.visitStatusIds) {
    conditionString += '&visit_status=' + filterParse.visitStatusIds;
  }

  let url = '';

  url = `${baseUrl}/optaplanner/app/getEmployeesWaitTime?${conditionString}`;
  return get(url);
};
export const getVisitDetail = visitId => {
  //Todo: add correct url for get visit view details
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/scheduler/visits/${visitId}/`;

  return get(url);
};

export const getMedicationListOnSchedular = async (Id, values) => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};
  let filterParse = {};
  if (values.search) {
    toParse.search = values.search;
  }
  if (values.ordering) {
    toParse.ordering = values.ordering;
  }
  // if (values.offset) {

  if (values.employee_name) {
    let runRouteFilter = values.employee_name.map(data => data.id);
    let strRunRouteFilter = runRouteFilter.join(',');
    filterParse.employee_name = strRunRouteFilter;
  }

  stringified = queryString.stringify(toParse) || '';
  if (values.date_from) {
    stringified += '&start_date=' + values.date_from;
  }
  if (values.date_to) {
    stringified += '&end_date=' + values.date_to;
  }
  if (filterParse.employee_name) {
    stringified += '&employee=' + filterParse.employee_name;
  }
  toParse.offset = values.offset;
  // }
  toParse.limit = values.limitperpage;

  //stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/medication/medicationVisitWise/medicationListing/?visitId=${Id}`;

  return get(url);
};

export const getSchedularMedication = async (Id, values) => {
  const baseUrl = getBaseUrl2();
  let stringified = "";
  let toParse = {};


  toParse.offset = values.offset;
  toParse.limit = values.limitperpage;
  if (values.service_visit) {
    toParse.service_visit = values.service_visit
  }
  if (values.visit_start_date) {
    toParse.medicationDate = values.visit_start_date
  }
  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/medication/medicationVisitWise/medicationListing/?${stringified}`;


  //http://localhost:8000/org/b ill-test-org-1/api/v2/medication/medicationVisitWise/medicationListing/?service_visit=c7812873-982d-4d72-81e3-55594b34c4de&medicationDate=2024-08-01

  return get(url);
};

export const getEarlyLate = () => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/scheduler/evv/settings/getEvvSettingsData/?VisitTimeClassified=true&ClockValidationCheck=true`;
  return get(url);
};

export const getPrefEmpData = () => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/client/demographics/get_client_preferred_employee_listing/`;
  return get(url);
};

export const getPinnedEmp = values => {
  let conditionString = '';

  if (values.employee_id) {
    conditionString += 'employee_id=' + values.employee_id;
  }
  if (values.requirement_id) {
    conditionString += '&requirement_id=' + values.requirement_id;
  }
  if (values.client_service_visit_id) {
    conditionString +=
      '&client_service_visit_id=' + values.client_service_visit_id;
  }
  if (values.each_repeat_on) {
    conditionString += '&each_repeat_on=' + values.each_repeat_on;
  }

  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/get-pinned-employee?${conditionString}`;

  return get(url);
};

export const getAllOffersForVisits = id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/scheduler/offer/visit/get_offer_approval_visit/?visit=${id}`;
  return get(url);
};

export const getActiveEmp = (date, view) => {
  const baseUrl = getBaseUrl();
  let viewTemp = '';
  if (view) {
    viewTemp = `&view=true`
  }
  const url = `${baseUrl}/employees/getActiveEmployeeList/?date=${date}${viewTemp}`;
  return get(url);
};

export const getActiveClient = (date, view) => {
  const baseUrl = getBaseUrl();
  // let viewTemp = '';
  // if (view) {
  //   viewTemp = `&view=true`
  // }
  // http://localhost:8000/org/bill-test-org-1/api/v1/clients/getActiveClientList/?date=2024-8-12
  const url = `${baseUrl}/clients/getActiveClientList/?date=${date}`;
  return get(url);
};

export const getcommHrs = (dates) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/scheduler/client_commissioned_hours/?start_date=${dates.start_date}&end_date=${dates.end_date}`;
  return get(url);
};

export const getVisitCareNotesOnVisit = (values, limitPerPage, selectedVisitId) => {
  let stringified = '';
  let toParse = {};
  let filterParse = {};
  const baseUrl = getBaseUrl();
  if (values.search) {
    toParse.search = values.search;
  }
  if (values.ordering) {
    let lastOdering = values.ordering.split(',').pop();
    toParse.ordering = lastOdering;
  }
  toParse.offset = values.offset;
  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }
  if (selectedVisitId) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';

  // if (selectedVisitId) {
  //   stringified += '&visit=' + selectedVisitId;
  // }
  // 
  const url = `${baseUrl}/clients/care-notes-visit?visit_id=${selectedVisitId}`;
  return get(url);
};

export const getPlannerListSch = (start, end) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/scheduler/planned-data/getPlanning/?limit=100&offset=0&start_date=${start}&end_date=${end}`;
  return get(url);
};

export const getPlannerCompareSchVisits = (start, end, id) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/scheduler/planned-visit-data/getPlannedVisit/?planning=${id}&start_date=${start}&end_date=${end}`;
  return get(url);
};

export const accpetAllOffersForVisits = (visit, empId, force) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/scheduler/offer/visit/offer_approval/?visit=${visit}`;
  const body = {
    visit: visit,
    offer_employee: empId,
  };
  if (force) {
    body.force = true;
  }
  return patch(url, body, '');
};

export const setRevertPublish = values => {
  const method = PATCH;
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/scheduler/visits/RevertPublish/`;
  const body = {
    ...values,
  };
  return post(url, body, method);
};
export const setUnpinnedEmp = values => {
  const method = POST;
  let conditionString = '';
  if (values) {
    let clientFilter = values.map(data => data);
    let strClientFilter = clientFilter.join(',');
    conditionString = strClientFilter;
  }

  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/unpin-employee`;
  const body = {
    day_id: conditionString,
  };
  return post(url, body, method);
};
export const setDetach = values => {
  const method = POST;
  let conditionString = '';

  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/client/pinned-employee/bulk_detach/`;
  const body = {
    visit_ids: values,
  };
  return post(url, body, method);
};

export const setBulkDeleteMedication = (values, clientId) => {
  const method = PATCH;

  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/medication/medication/calendar/bulk_delete/`;
  const body = {
    medication_id_list: values,
  };
  return post(url, body, method);
};

export const getShiftPatterns = (values, limitPerPage) => {
  let stringified = '';
  let toParse = {};
  const baseUrl = getBaseUrl();
  // if (values.offset) {
  toParse.offset = values.offset;
  // }
  if (values.ordering) {
    // toParse.ordering = values.ordering;
    // for individual column name below lines
    let lastOdering = values.ordering.split(',').pop();
    toParse.ordering = lastOdering;
  }
  // }
  if (limitPerPage) {
    toParse.limit = limitPerPage;
  }

  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/shifts/patterns/?${stringified}`;

  return get(url);
};

export const getShiftPatternById = shiftPatternId => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/shifts/patterns/${shiftPatternId}/`;

  return get(url);
};

export const setShiftPattern = async values => {
  const baseUrl = getBaseUrl();
  const method = values.id ? PATCH : POST;
  const url = values.id
    ? `${baseUrl}/shifts/patterns/${values.id}/`
    : `${baseUrl}/shifts/patterns/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const setShiftPatternsDetails = async (
  shiftPatternId,
  values,
  patternId
) => {
  const baseUrl = getBaseUrl();
  // const method = patternId ? PATCH : POST;
  const method = POST;
  const url = `${baseUrl}/shifts/patterns/${shiftPatternId}/details/`;
  // const url = patternId
  //   ? `${baseUrl}/shifts/patterns/${shiftPatternId}/details/${patternId}/`
  //   : `${baseUrl}/shifts/patterns/${shiftPatternId}/details/`;

  const body = [...values];
  delete body.full_day;
  return post(url, body, method);
};

export const deleteShiftPatternsDetails = async (shiftPatternId, values) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/shifts/patterns/${shiftPatternId}/details/${values.id}/`;

  return deleteFetch(url);
};

export const deleteShiftPattern = async shiftPatternId => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/shifts/patterns/${shiftPatternId}/`;

  return deleteFetch(url);
};

export const runScheduler = (
  startDate,
  endDate,
  branch_filter,
  values,
  regularVisit,
  checkedRun,
  considerTimeRes,
  checkedFreezeVisits,
  planOnlyToggle,
  considerEmpRota,
  overrideWindow, 
  overrideWindowMinutes
) => {
  const baseUrl = getBaseUrl();
  let filterParse = {};
  const new_start_date = new Date(startDate);
  const new_end_date = new Date(endDate);
  if (branch_filter) {
    // let branchFilter = branch_filter.map(data => data.id);
    // let strbranchFilter = branchFilter.join(',');
    filterParse.branch_ids = branch_filter;
  }
  let conditionString = '';
  let datesRange = '';



  datesRange = 'startDate=' + new_start_date.getTime();

  datesRange = datesRange + '&endDate=' + new_end_date.getTime();
  conditionString += datesRange;
  if (planOnlyToggle) {
    conditionString += '&plannerOnly=' + planOnlyToggle;
    if (checkedFreezeVisits && (checkedFreezeVisits.length > 0)) {
      let temp = checkedFreezeVisits?.map((i) => (i.id))
      temp.map((item) => {
        conditionString += `&${item}=` + 'true';
      })
    }
  } else {
    conditionString += '&consider_only=' + (checkedRun ? checkedRun : 'All');
  }

  if (filterParse.branch_ids) {
    conditionString += '&branch_ids=' + filterParse.branch_ids;
  }
  conditionString += '&considerRegularVisit=' + regularVisit;
  conditionString += '&considerTimeRestriction=' + considerTimeRes;
  conditionString += '&considerEmployeeRota=' + considerEmpRota;
  conditionString += '&overrideWindow=' + overrideWindow;

  if(overrideWindow){
    conditionString += '&overrideWindowMinutes=' + (overrideWindowMinutes ? overrideWindowMinutes : 0);
  }


  const url = `${baseUrl}/optaplanner/app?${conditionString}`;

  return post(url, {}, POST);
};

export const getSchedulerOptaplanner = (startDate, endDate) => {
  const baseUrl = getBaseUrl();
  const new_start_date = new Date(startDate);
  const new_end_date = new Date(endDate);

  const url = `${baseUrl}/optaplanner/app?startDate=${new_start_date.getTime()}&endDate=${new_end_date.getTime()}`;

  return get(url);
};

export const deleteSchedulerOptaplanner = (start_date, end_date) => {
  const baseUrl = getBaseUrl();
  const new_start_date = new Date(start_date);
  const new_end_date = new Date(end_date);
  const url = `${baseUrl}/optaplanner/app?startDate=${new_start_date.getTime()}&endDate=${new_end_date.getTime()}`;

  return deleteFetch(url);
};

export const getAllVisitEvvslist = (values, pageSize) => {
    let stringified = '';
  let toParse = {};
  let filterParse = {};
  const baseUrl = getBaseUrl();
  // if (values.client_id) {
  //   toParse.client__id = values.client_id;
  // }
  if (values.travel_method) {
    toParse.travel_method = values.travel_method;
  }
  // if (values.employee_id) {
  //   toParse.employee__id =  values.employee_id;
  // }
  if (values.employee_id) {
    let runRouteFilter = values.employee_id.map(data => data.id);
    let strRunRouteFilter = runRouteFilter.join(',');
    filterParse.employee_id = strRunRouteFilter;
  }
  if (values.clocked_visit_provision__in) {
    let visitProvisionFilter = values.clocked_visit_provision__in.map(
      data => data.id
    );
    let strVisitProvisionFilter = visitProvisionFilter.join(',');
    filterParse.clocked_visit_provision__in = strVisitProvisionFilter;
  }
  if (values.evv_confirmation_status__in) {
    let visitConfirmationFilter = values.evv_confirmation_status__in.map(
      data => data.id
    );
    let strVisitConfirmationFilter = visitConfirmationFilter.join(',');
    filterParse.evv_confirmation_status__in = strVisitConfirmationFilter;
  }
  if (values.client_id) {
    let runRouteFilter = values.client_id.map(data => data.id);
    let strRunRouteFilter = runRouteFilter.join(',');
    filterParse.client_id = strRunRouteFilter;
  }
  if (values.visit_priority_id) {
    toParse.visit__client_service_visit__visit_priority__id =
      values.visit_priority_id;
  }
  if (values.timeDistance) {
    let timeDistanceFilter = values.timeDistance.map(data => data.id);
    let strTimeDistanceFilter = timeDistanceFilter.join(',');
    filterParse.timeDistance = strTimeDistanceFilter;
  }
  if (values.location) {
    let locationIds = values.location.map(el => el.id)
    const joinedIds = locationIds.join(",")
    filterParse.location = joinedIds
  }
  if (values.visit_no) {
    toParse.visit__visit_number = values.visit_no;
  }
  if (values.ordering) {
    // toParse.ordering = values.ordering;
    // for individual column name below lines
    let lastOdering = values.ordering.split(',').pop();
    toParse.ordering = lastOdering;
  }
  // if (values.offset) {
  toParse.offset = values.offset;
  // }
  if (values.client__clients_run_routes__run_routes__id__in) {
    let clientRunRouteFilter = values.client__clients_run_routes__run_routes__id__in.map(data => data.id);
    let strClientRunRouteFilter = clientRunRouteFilter.join(',');
    filterParse.client__clients_run_routes__run_routes__id__in = strClientRunRouteFilter;
  }
  if (values.employee__employee_run_routes__run_routes__id__in) {
    let employeeRunRouteFilter = values.employee__employee_run_routes__run_routes__id__in.map(data => data.id);
    let stEmployeeRunRouteFilter = employeeRunRouteFilter.join(',');
    filterParse.employee__employee_run_routes__run_routes__id__in = stEmployeeRunRouteFilter;
  }
  if (values.visitstatus) {
    let visitstatusFilter = values.visitstatus.map(data => data.id);
    let strvisitstatusFilter = visitstatusFilter.join(',');
    filterParse.visitstatus = strvisitstatusFilter;
  }
  if (values.travel_method) {
    let travelMethodFilter = values.travel_method;
    let strTravel;
  }
  if (values.branch) {
    let branchFilter = values.branch.map(data => data.id);
    let strbranchFilter = branchFilter.join(',');
    filterParse.branch = strbranchFilter;
  }

  if (values.headerbranch) {
    let branchFilter = values.headerbranch.map(data => data.id);
    let strheaderbranchFilter = branchFilter.join(',');
    filterParse.headerbranch = strheaderbranchFilter;
  }

  if (values.timeDistance) {
    let timeDistanceFilter = values.timeDistance.map(data => data.id);
    let strTimeDistanceFilter = timeDistanceFilter.join(',');
    filterParse.timeDistance = strTimeDistanceFilter;
  }
  toParse.limit = pageSize || PAGE_SIZE_RENDER;

  let datesRange = '';

  // start_date to utc and backend required format
  datesRange = 'schedule_start_date__gte=' + values.date_from;

  // end_date to utc and backend required format and concat to start_date
  datesRange = datesRange + '&schedule_end_date__lte=' + values.date_to;

  // if stringified has value then concat with &
  stringified = queryString.stringify(toParse) || '';
  if (filterParse.client__clients_run_routes__run_routes__id__in) {
    stringified += '&client__clients_run_routes__run_routes__id__in=' + filterParse.client__clients_run_routes__run_routes__id__in;
  }
  if (filterParse.employee__employee_run_routes__run_routes__id__in) {
    stringified += '&employee__employee_run_routes__run_routes__id__in=' + filterParse.employee__employee_run_routes__run_routes__id__in;
  }
  if (!((values.clockedDurationDiff__gte == null) || (values.clockedDurationDiff__gte == undefined))) {
    stringified += '&clockedDurationDiff__gte=' + values.clockedDurationDiff__gte;
  }
  if (!((values.clockedDurationDiff__lte == null) || (values.clockedDurationDiff__lte == undefined))) {
    stringified += '&clockedDurationDiff__lte=' + values.clockedDurationDiff__lte;
  }
  if (!((values.clockedDurationPercent__gte == null) || (values.clockedDurationPercent__gte == undefined))) {
    stringified += '&clockedDurationPercent__gte=' + values.clockedDurationPercent__gte;
  }
  if (!((values.clockedDurationPercent__lte == null) || (values.clockedDurationPercent__lte == undefined))) {
    stringified += '&clockedDurationPercent__lte=' + values.clockedDurationPercent__lte;
  }
  if (filterParse.visitstatus) {
    stringified += '&visit__visit_status__id__in=' + filterParse.visitstatus;
  }
  if (filterParse.employee_id) {
    stringified += '&employee__id__in=' + filterParse.employee_id;
  }
  if (filterParse.client_id) {
    stringified += '&client__id__in=' + filterParse.client_id;
  }
  if (filterParse.clocked_visit_provision__in) {
    stringified +=
      '&clocked_visit_provision__in=' + filterParse.clocked_visit_provision__in;
  }
  if (filterParse.evv_confirmation_status__in) {
    stringified +=
      '&evv_confirmation_status__in=' + filterParse.evv_confirmation_status__in;
  }
  if (filterParse.branch_id) {
    stringified += '&employee__job_detail__branch__id=' + filterParse.branch_id;
  }
  if (values.timeDistance) {
    stringified += '&timeDistance=' + filterParse.timeDistance;
  }

  if (filterParse.branch) {
    if (filterParse.branch.includes('None')) {
      stringified += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.branch;
      const branchIdsWithoutNoneId = branchItemsIds
        .split(',')
        .filter(e => e !== 'None')
        .join(',');
      if (branchIdsWithoutNoneId)
        stringified +=
          '&employee__job_detail__branch__id=' + branchIdsWithoutNoneId;
    } else {
      stringified += '&employee__job_detail__branch__id=' + filterParse.branch;
    }
  } else if (filterParse.headerbranch) {
    if (filterParse.headerbranch.includes('None')) {
      stringified += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.headerbranch;
      const branchIdsWithoutNoneId = branchItemsIds
        .split(',')
        .filter(e => e !== 'None')
        .join(',');
      if (branchIdsWithoutNoneId)
        stringified +=
          '&employee__job_detail__branch__id=' + branchIdsWithoutNoneId;
    } else {
      stringified +=
        '&employee__job_detail__branch__id=' + filterParse.headerbranch;
    }
  }

  if (stringified) {
    stringified = stringified + '&' + datesRange;
  } else {
    stringified = datesRange;
  }
  if (filterParse.location) {
    stringified += `&location__id__in=${filterParse.location}`
  }

  const url = `${baseUrl}/scheduler/visits/all/evvs/?${stringified}`;

  return get(url);
};
export const getAllVisitEsvslist = (values, esv_type, pageSize) => {
  let stringified = '';
  let toParse = {};
  let filterParse = {};
  const baseUrl = getBaseUrl();
  if (values?.client_id) {
    toParse.client__id = values.client_id;
  }
  if (values?.travel_method) {
    toParse.travel_method = values.travel_method;
  }
  if (values?.employee_id) {
    let empFilter = values.employee_id.map(data => data.id);
    let strEmpFilter = empFilter.join(',');
    filterParse.employee_id = strEmpFilter;
  }
  if (values?.shiftType) {
    let empFilter = values.shiftType.map(data => data.id);
    let strEmpFilter = empFilter.join(',');
    filterParse.shift_type = strEmpFilter;
  }
  if (values?.visit_priority_id) {
    toParse.visit__client_service_visit__visit_priority__id =
      values.visit_priority_id;
  }
  if (values?.visit_no) {
    toParse.visit__visit_number = values.visit_no;
  }
  if (values?.ordering) {
    // toParse.ordering = values.ordering;
    // for individual column name below lines
    let lastOdering = values.ordering.split(',').pop();
    toParse.ordering = lastOdering;
  }
  // if (values.offset) {
  toParse.offset = values.offset;
  // }
  if (values?.runroutes) {
    let runRouteFilter = values.runroutes.map(data => data.id);
    let strRunRouteFilter = runRouteFilter.join(',');
    filterParse.runroute = strRunRouteFilter;
  }
  if (values?.visitstatus) {
    let visitstatusFilter = values.visitstatus.map(data => data.id);
    let strvisitstatusFilter = visitstatusFilter.join(',');
    filterParse.visitstatus = strvisitstatusFilter;
  }
  if (values?.travel_mode) {
    let travelMethodFilter = values.travel_mode.map(data => data.id);
    let strTravelModeFilter = travelMethodFilter.join(',');
    filterParse.travel_mode = strTravelModeFilter;
  }
  if (values?.travel_code) {
    let travelMethodFilter = values.travel_code.map(data => data.id);
    let strTravelCodeFilter = travelMethodFilter.join(',');
    filterParse.travel_code = strTravelCodeFilter;
  }
  if (values?.branch) {
    let branchFilter = values.branch.map(data => data.id);
    let strbranchFilter = branchFilter.join(',');
    filterParse.branch = strbranchFilter;
  }

  if (values?.headerbranch) {
    let branchFilter = values.headerbranch.map(data => data.id);
    let strheaderbranchFilter = branchFilter.join(',');
    filterParse.headerbranch = strheaderbranchFilter;
  }

  toParse.limit = pageSize || PAGE_SIZE_RENDER;

  let datesRange = '';

  // start_date to utc and backend required format
  datesRange = 'start_date__gte=' + values.date_from + ' ' + '00:00:00';

  // end_date to utc and backend required format and concat to start_date
  datesRange =
    datesRange + '&end_date__lte=' + values.date_to + ' ' + '23:59:59';

  // if stringified has value then concat with &
  stringified = queryString.stringify(toParse) || '';
  if (filterParse.runroute) {
    stringified += '&run_route=' + filterParse.runroute;
  }

  if (filterParse.visitstatus) {
    stringified += '&shift_status__id__in=' + filterParse.visitstatus;
  }
  if (filterParse.employee_id) {
    stringified += '&employee__id__in=' + filterParse.employee_id;
  }
  if (filterParse.shift_type) {
    stringified += '&shift_type__id__in=' + filterParse.shift_type;
  }
  if (filterParse.travel_mode) {
    stringified += '&travel_mode__id__in=' + filterParse.travel_mode;
  }
  if (filterParse.travel_code) {
    stringified += '&travel_code__in=' + filterParse.travel_code;
  }

  if (filterParse.branch_id) {
    stringified += '&employee__job_detail__branch__id=' + filterParse.branch_id;
  }

  if (filterParse.branch) {
    if (filterParse.branch.includes('None')) {
      stringified += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.branch;
      const branchIdsWithoutNoneId = branchItemsIds
        .split(',')
        .filter(e => e !== 'None')
        .join(',');
      if (branchIdsWithoutNoneId)
        stringified +=
          '&employee__job_detail__branch__id=' + branchIdsWithoutNoneId;
    } else {
      stringified += '&employee__job_detail__branch__id=' + filterParse.branch;
    }
  } else if (filterParse.headerbranch) {
    if (filterParse.headerbranch.includes('None')) {
      stringified += '&null_branch=' + 'None';
      let branchItemsIds = filterParse.headerbranch;
      const branchIdsWithoutNoneId = branchItemsIds
        .split(',')
        .filter(e => e !== 'None')
        .join(',');
      if (branchIdsWithoutNoneId)
        stringified +=
          '&employee__job_detail__branch__id=' + branchIdsWithoutNoneId;
    } else {
      stringified +=
        '&employee__job_detail__branch__id=' + filterParse.headerbranch;
    }
  }
  stringified += '&viewSet=' + esv_type;
  if (stringified) {
    stringified = stringified + '&' + datesRange;
  } else {
    stringified = datesRange;
  }

  const url = `${baseUrl}/shifts/shift-view/get_shift_detail/?${stringified}`;

  return get(url);
};

export const setUpdateVisit = values => {
  const method = PATCH;
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/scheduler/visits/${values.id}/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};
export const cancelVisitSave = values => {
  const method = PATCH;
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/scheduler/visits/cancel_visit/`;
  const body = {
    ...values,
  };
  return post(url, body, method);
};
export const deleteVisit = ids => {
  const method = PATCH;
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/scheduler/visits/delete_visit/`;
  const body = {
    ...ids,
  };
  return post(url, body, method);
};

export const setUpdateOptaplannerVisit = values => {
  const method = POST;
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/optaplanner/app/assignVisit`;
  const body = {
    ...values,
  };
  return post(url, body, method);
};

export const setUpdateSchedularVisit = (values, schedularVisitId) => {
  const method = PATCH;
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/scheduler/visits/${schedularVisitId}/`;
  const body = {
    ...values,
  };
  return post(url, body, method);
};

export const deleteAllVisits = async (
  startDate,
  endDate,
  branch_for_delete,
  checkedDelete
) => {
  const new_start_date = encodeURIComponent(startDate.getUTCString());
  const new_end_date = encodeURIComponent(endDate.getUTCString());
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/scheduler/visits/reset/?start_date=${new_start_date}&end_date=${new_end_date}&consider_only=${checkedDelete}&branch_ids=${branch_for_delete}`;

  return deleteFetch(url);
};

export const getClientInfoById = id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/${id}/`;

  return get(url);
};

export const getAdhocServiceAddress = (id, type) => {
  const baseUrl = getBaseUrl();
  let stringified = '';

  if(type){
    stringified +=  `${type}=${id}`
  }
  const url = `${baseUrl}/clients/service-address/get_service_address_client_location/?${stringified}`;

  return get(url);
};

export const saveAdhocVisit = async values => {
  const baseUrl = getBaseUrl();
  const method = POST;
  const url = `${baseUrl}/scheduler/adhoc/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};

export const getEmployeeVisitData = values => {
  let stringified = '';
  let toParse = {};
  let filterParse = {};
  const baseUrl = getBaseUrl();
  if (values.client_id) {
    toParse.client__id = values.client_id;
  }
  if (values.employee_id) {
    toParse.employee__id = values.employee_id;
  }
  if (values.visit_priority_id) {
    toParse.visit__client_service_visit__visit_priority__id =
      values.visit_priority_id;
  }
  if (values.visit_no) {
    toParse.visit__visit_number = values.visit_no;
  }
  // added runroute+job department+branch
  if (values.jobDepartment) {
    let jobDepartmentFilter = values.jobDepartment.map(data => data.id);
    let strJobDepartmentFilter = jobDepartmentFilter.join(',');
    filterParse.jobDepartment = strJobDepartmentFilter;
  }
  if (values.branch) {
    let branchFilter = values.branch.map(data => data.id);
    let strbranchFilter = branchFilter.join(',');
    filterParse.branch_id = strbranchFilter;
  }
  // if (values.runroutes) {
  //   let runRouteFilter=values.runroutes.map(data=>data.id)
  //   let strRunRouteFilter=runRouteFilter.join(",")
  //   filterParse.runroute = strRunRouteFilter;
  // }

  if (values.runroutesclient) {
    let runRouteFilter = values.runroutesclient.map(data => data.id);
    let strRunRouteFilter = runRouteFilter.join(',');
    filterParse.runrouteclient = strRunRouteFilter;
  }
  if (values.runroutesteam) {
    let runRouteFilter = values.runroutesteam.map(data => data.id);
    let strRunRouteFilter = runRouteFilter.join(',');
    filterParse.runrouteteam = strRunRouteFilter;
  }

  if (values.visitStatus) {
    let visitStatusFilter = values.visitStatus.map(data => data.id);
    let strVisitStatusFilter = visitStatusFilter.join(',');
    filterParse.runroute = strVisitStatusFilter;
  }

  // toParse.limit = pageSize || PAGE_SIZE_RENDER;

  let datesRange = '';
  datesRange = 'start_date=' + moment(values.start_date).format('YYYY-MM-DD');
  datesRange =
    datesRange + '&end_date=' + moment(values.end_date).format('YYYY-MM-DD');

  // if stringified has value then concat with &
  stringified = queryString.stringify(toParse) || '';
  if (stringified) {
    stringified = stringified + '&' + datesRange;
  } else {
    stringified = datesRange;
  }
  // added checks for runroute+job department+branch

  // if(filterParse.runroute){
  //   stringified+='&run_route='+filterParse.runroute
  // }

  if (filterParse.runrouteclient) {
    stringified += '&run_route_client=' + filterParse.runrouteclient;
  }

  if (filterParse.runrouteteam) {
    stringified += '&run_route_employee=' + filterParse.runrouteteam;
  }

  if (filterParse.jobDepartment) {
    stringified += '&job_category=' + filterParse.jobDepartment;
  }
  // if(filterParse.branch_id){
  //   stringified+='&branch_id='+filterParse.branch_id
  // }
  if (filterParse.branch_id) {
    if (filterParse.branch_id == 'None') {
      stringified += '&null_branch=' + filterParse.branch_id;
    } else {
      stringified += '&branch_id=' + filterParse.branch_id;
    }
  }
  // if(filterParse.visitStatus){
  //   stringified+='&visit_status='+filterParse.visitStatus
  // }
  const url = `${baseUrl}/employees/employee/miscellaneous/?${stringified}`;

  return get(url);
};

export const getClientVisitData = values => {
  const baseUrl = getBaseUrl();
  // toParse.limit = pageSize || PAGE_SIZE_RENDER;

  let datesRange = '';

  datesRange = 'start_date=' + moment(values.start_date).format('YYYY-MM-DD');
  datesRange =
    datesRange + '&end_date=' + moment(values.end_date).format('YYYY-MM-DD');

  const url = `${baseUrl}/scheduler/client_visit_detail/?${datesRange}`;

  return get(url);
};

export const getPublishVisit = (values, bolVal) => {
  // bolVal -BE- needs value in "False"
  const baseUrl = getBaseUrl();
  const method = PATCH;
  let stringified = '';
  let toParse = {};
  let datesRange = '';
  datesRange = 'start_date=' + moment(values.start_date).format('YYYY-MM-DD');
  datesRange =
    datesRange + '&end_date=' + moment(values.end_date).format('YYYY-MM-DD');

  const body = {};

  if (bolVal == 'False') {
    //BE-python needs value in "False"format  in api query params . so sent
    toParse.status = bolVal;
  }
  // toParse.datesRange = datesRange;

  stringified = queryString.stringify(toParse) || '';
  if (stringified) {
    stringified = stringified + '&' + datesRange;
  } else {
    stringified = datesRange;
  }
  // const url = `${baseUrl}/scheduler/visits/publish/?${datesRange}/${status}`;
  const url = `${baseUrl}/scheduler/visits/publish/?${stringified}`;
  return post(url, body, method);
};

export const getDownloadSchedulerFile = async values => {
  const baseUrl = getBaseUrlMicroServices();
  const new_start_date = new Date(values.start_date);
  const new_end_date = new Date(values.end_date);

  let datesRange = '';
  // start_date to utc and backend required format
  datesRange =
    'start_date=' + encodeURIComponent(new_start_date.getUTCString());

  // end_date to utc and backend required format and concat to start_date
  datesRange =
    datesRange + '&end_date=' + encodeURIComponent(new_end_date.getUTCString());
  const url = `${baseUrl}/clients/app/download-excel-file?view_set=SchedularVisitViewSet&${datesRange}`;
  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/ms-excel',
      Authorization: 'Token ' + getToken(),
    },
  });

  if (response && response.status === 401) {
    clearToken();
    window.location.reload();
  }
  // const json = await response.json();
  // console.log('json', json);

  return response;
};

export const getDownloadRotaShiftFile = async values => {
  const baseUrl = getBaseUrlMicroServices();
  const new_start_date = new Date(values.start_date);
  const new_end_date = new Date(values.end_date);

  let datesRange = '';
  // start_date to utc and backend required format
  datesRange =
    'start_date=' + moment(values.start_date).format('YYYY-MM-DD')

  // end_date to utc and backend required format and concat to start_date
  datesRange =
    datesRange +
    '&end_date=' +
    moment(values.end_date).format('YYYY-MM-DD')
  const url = `${baseUrl}/reports/employee-shift/reports/downloadEmployeeShift/?view=Shifts&${datesRange}`;
  //const url = `${baseUrl}/clients/app/download-excel-file?view_set=RotaShift&${datesRange}`;

  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/ms-excel',
      Authorization: 'Token ' + getToken(),
    },
  });

  if (response && response.status === 401) {
    clearToken();
    window.location.reload();
  }
  // const json = await response.json();
  // console.log('json', json);

  return response;
};

export const getDownloadRegularVisitFile = async values => {
  const baseUrl = getBaseUrlMicroServices();
  // const new_start_date = new Date(values.start_date);
  // const new_end_date = new Date(values.end_date);

  //let datesRange = '';
  // start_date to utc and backend required format
  //datesRange =
  //'start_date=' + encodeURIComponent(new_start_date.getUTCString());

  // end_date to utc and backend required format and concat to start_date
  // datesRange =
  // datesRange + '&end_date=' + encodeURIComponent(new_end_date.getUTCString());
  const url = `${baseUrl}/clients/app/download-excel-file?view_set=RegularVisit&list_employees_for_regular_visit=true&view=client&list_for_regular_visit=true&download=true`;
  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/ms-excel',
      Authorization: 'Token ' + getToken(),
    },
  });

  if (response && response.status === 401) {
    clearToken();
    window.location.reload();
  }
  // const json = await response.json();
  // console.log('json', json);

  return response;
};

//scheduler  modal pop up   score get api
export const getScoreData = (visitId, values, pageSize) => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.search) {
    toParse.search = values.search;
  }
  if (values.ordering) {
    toParse.ordering = values.ordering;
  }
  // if (values.offset) {
  toParse.offset = values.offset;
  // }
  toParse.limit = pageSize;
  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/scheduler/visits/${visitId}/score-settings/?${stringified}`;
  return get(url);
};

//scheduler  modal pop up   Rate   get api
export const getSchedulerRate = visitId => {
  const baseUrl = getBaseUrl();
  //const url = `${baseUrl}/scheduler/rates-view/${visitId}/`;
  const url = `${baseUrl}/scheduler/rates-view/get_visit_rates_view/?visit=${visitId}`;
  return get(url);
};

export const getCopySch = value => {
  const method = POST;
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/optaplanner/copy-scheduler?schedule_start_date=${value.start_date}&schedule_end_date=${value.end_date}&copied_start_date=${value.date_from}&copied_end_date=${value.date_to}&branch_ids=${value.branch_ids}`;
  const body = {};
  return post(url, body, method);
};


export const setUpdateRateDetailsData = (values, VisitId) => {
  const method = PATCH;
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/scheduler/rates-view/${VisitId}/`;
  const body = {
    ...values,
  };
  return post(url, body, method);
};

export const getDataOnChangeRateID = (visitId, rateCardId, actualName, travelName) => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  if (travelName == 'travel') {
    stringified = '&consider_travel=true'
  }
  if(actualName ==='consider_schedule'){
    stringified += '&consider_schedule=true'
  }
  if (actualName == 'actual') {
    const url = `${baseUrl}/scheduler/rates-view/rate_card_calculation/?visit_id=${visitId}&rate_card=${rateCardId}&consider_actual=true${stringified}`;
    return get(url);
  } else {
    const url = `${baseUrl}/scheduler/rates-view/rate_card_calculation/?visit_id=${visitId}&rate_card=${rateCardId}${stringified}`;
    return get(url);
  }
};

export const DragAndDropVisits = (body, values, exclude) => {

  // visitFilters :
  // shiftFilters :

  let visitFilters = {};
  let shiftFilters = {}

// Visit filters is here
  if (values.employees || values.employees) {
    if (values.employees) {
      let employeeFilter = values.employees.map(data => data.id);
      if (employeeFilter.find(emp => emp == 'none')) {
        let strEmployeeFilter = employeeFilter
          .filter(emp => emp != 'none')
          .join(',');
        visitFilters.employees = strEmployeeFilter;
        visitFilters.unassigned = true;
      } else {
        let strEmployeeFilter = employeeFilter.join(',');
        visitFilters.employees = strEmployeeFilter;
      }
    } else {
      let employeeFilter = values.employees.map(data => data.id);
      let strEmployeeFilter = employeeFilter.join(',');
      visitFilters.employees = strEmployeeFilter;
    }
  }
  
  if (values.client) {
    let clientFilter = values.client.map(data => data.id);
    let strClientFilter = clientFilter.join(',');
    visitFilters.clients = strClientFilter;
  }
  if (values?.location) {
    let locationFilter = values?.location.map(data => data.id);
    let strLocationFilter = locationFilter.join(',');
    visitFilters.locations = strLocationFilter;
  }
  if (values.service_category) {
    let serviceFilter = values.service_category.map(data => data.id);
    let strServiceFilter = serviceFilter.join(',');
    visitFilters.service_category = strServiceFilter;
  }
  if (values.service_category_type) {
    let serviceCategoryFilter = values.service_category_type.map(data => data.id);
    let strServiceCategoryFilter = serviceCategoryFilter.join(',');
    visitFilters.service_category_type = strServiceCategoryFilter;
  }
  if (values.funder_name) {
    let funder_client_filter = values.funder_name.filter(data => data.is_client);
    let serviceFilter = funder_client_filter.map(data => data.id);
    let strServiceFilter = serviceFilter.join(',');
    visitFilters.funder_client_name = strServiceFilter;
  }
  if (values.funder_name) {
    let funder_filter = values.funder_name.filter(data => !data.is_client);
    let serviceFilter = funder_filter.map(data => data.id);
    let strServiceFilter = serviceFilter.join(',');
    visitFilters.funder_name = strServiceFilter;
  }

  if (values.funder_category) {
    let funder_cat_filter = values.funder_category.filter(data => data.id !== 'none');
    let serviceFilter = funder_cat_filter.map(data => data.id);
    let strServiceFilter = serviceFilter.join(',');
    visitFilters.funder_category = strServiceFilter;
  }

  if (values.funder_category) {
    let funder_cat_filter = values.funder_category.filter(data => data.id == 'none');
    if (funder_cat_filter.length > 0) {
      visitFilters.null_funder_category = true;
    }
  }
  if (values.runroutesclient) {
    let runRouteFilter = values.runroutesclient.map(data => data.id);
    let strRunRouteFilter = runRouteFilter.join(',');
    visitFilters.runrouteclient = strRunRouteFilter;
  }
  if (values.runroutesteam) {
    let runRouteFilter = values.runroutesteam.map(data => data.id);
    let strRunRouteFilter = runRouteFilter.join(',');
    visitFilters.runrouteteam = strRunRouteFilter;
  }
  if (values.jobDepartment) {
    let jobDepartmentFilter = values.jobDepartment.map(data => data.id);
    let strJobDepartmentFilter = jobDepartmentFilter.join(',');
    visitFilters.jobDepartment = strJobDepartmentFilter;
  }

  if (values.branch) {
    let branchFilter = values.branch.map(data => data.id);
    let strbranchFilter = branchFilter.join(',');
    visitFilters.branch = strbranchFilter;
  }
  if (values.headerbranch) {
    let branchFilter = values.headerbranch.map(data => data.id);
    let strheaderbranchFilter = branchFilter.join(',');
    visitFilters.headerbranch = strheaderbranchFilter;
  }

  if (values.visitStatus) {
    let visitStatusFilter = values.visitStatus.map(data => data.id);
    let strVisitStatusFilter = visitStatusFilter.join(',');
    visitFilters.visitStatus = strVisitStatusFilter;
  }
  if (values.run_view_name) {
    let runNameFilter = values.run_view_name.map(data => data.id);
    let strRunNameFilter = runNameFilter.join(',');
    visitFilters.run_view_name = strRunNameFilter;
  }

  if (values.availability !== '') {
    visitFilters.is_available = values.availability;
  }
  if (values.visit_publish) {
    visitFilters.visit_publish = values.visit_publish;
  }
  if (values.doubleUp) {
    visitFilters.doubleUp = values.doubleUp
  }
  if (values.start_date) {
    const new_start_date = new Date(moment(values.start_date).format('YYYY-MM-DD'));
    visitFilters.start_date = encodeURIComponent(new_start_date.getUTCString());
  }
  if (values.end_date) {
    const new_end_date = new Date(moment(values.end_date).format('YYYY-MM-DD'));
    visitFilters.end_date = encodeURIComponent(new_end_date.getUTCString());
  }
  visitFilters.view = 'Web';



  // Shift filters form here
  if (values.shift) {
    shiftFilters['shift_type__id'] = values.shift;
  }
  if (values.start_date) {
    shiftFilters.start_date = moment(values.start_date).format('YYYY-MM-DD')
  }
  if (values.end_date) {
    shiftFilters.end_date =  moment(values.end_date).format('YYYY-MM-DD')
  }


  if (values.runroutes) {
    let runRouteFilter = values.runroutes.map(data => data.id)
    let strRunRouteFilter = runRouteFilter.join(",")
    shiftFilters.runroute = strRunRouteFilter;
  }

  if (values.shift_type_filter) {
    let runRouteFilter = values.shift_type_filter.map(data => data.id)
    let strRunRouteFilter = runRouteFilter.join(",")
    shiftFilters.shift_type_filter = strRunRouteFilter;
  }

  if (values.runroutesclient) {
    let runRouteFilter = values.runroutesclient.map(data => data.id)
    let strRunRouteFilter = runRouteFilter.join(",")
    shiftFilters.runrouteclient = strRunRouteFilter;
  }

  if (values.runroutesteam) {
    let runRouteFilter = values.runroutesteam.map(data => data.id)
    let strRunRouteFilter = runRouteFilter.join(",")
    shiftFilters.employee_run_route_ids = strRunRouteFilter;
  }

  if (values.jobDepartment) {
    let jobDepartmentFilter = values.jobDepartment.map(data => data.id)
    let strJobDepartmentFilter = jobDepartmentFilter.join(",")
    shiftFilters.employee__job_detail__job_category__id = strJobDepartmentFilter;
  }

  if (values.branch) {
    let branchFilter = values.branch.map(data => data.id)
    let strbranchFilter = branchFilter.join(",")
    if (strbranchFilter.branch.includes('None')) {
      shiftFilters.null_branch = 'None';
      const branchIdsWithoutNoneId = branchFilter.split(",").filter(e => e !== 'None').join(",")
      if (branchIdsWithoutNoneId)
        shiftFilters.employee__job_detail__branch__id= branchIdsWithoutNoneId;
    } else {
      shiftFilters.employee__job_detail__branch__id= strbranchFilter;
    }
  } else if (values.headerbranch) {
      let branchFilter = values.headerbranch.map(data => data.id)
      let strheaderbranchFilter = branchFilter.join(",")
    if (strheaderbranchFilter.includes('None')) {
      shiftFilters.null_branch = 'None';
      const branchIdsWithoutNoneId = branchFilter.split(",").filter(e => e !== 'None').join(",")
      if (branchIdsWithoutNoneId)
        shiftFilters.employee__job_detail__branch__id= branchIdsWithoutNoneId;
    } else {
      shiftFilters.employee__job_detail__branch__id=  strheaderbranchFilter;
    }
  }

  if (values.employees) {
    let employeeID = {}
   
    let employeeFilter = values.employees.map(data => data.id)
    if (employeeFilter.find(emp => emp == 'none')) {
      let strEmployeeFilter = employeeFilter.filter(emp => emp != "none").join(",")
      employeeID.employees_id = strEmployeeFilter;
    } else {
      let strEmployeeFilter = employeeFilter.join(",")
      employeeID.employees_id = strEmployeeFilter;
    }

    if (employeeID.employees_id) {
      if(exclude.employee){
        shiftFilters.employee__id__exclude = employeeID.employees_id
      }else{
        shiftFilters.employee_ids = employeeID.employees_id
      }
    }
  }


 if (values.pay_type){
    let payTypeIds = values.pay_type.map(el => el.id).join(",")
    shiftFilters.pay_type_ids = payTypeIds;
  }

  let visitsFill = Object.keys(visitFilters);

  visitsFill.length && visitsFill.forEach((key) => {
    if (!visitFilters[key]) {
      delete visitFilters[key];
    }
  });

  let shiftFill = Object.keys(shiftFilters); 

  shiftFill.length && shiftFill.forEach((key) => {
    if (!shiftFilters[key]) {
      delete shiftFilters[key];
    }
  });


  const method = POST;
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/optaplanner/drag-reassigned`;
  const payload = {
    ...body,
    shiftFilters,
    visitFilters
  };
  return post(url, payload, method);
};

export const dragAndDropShifts = (body, values, exclude, shiftId) => {

let shiftFilters = {}

// Shift filters form here
if (values.shift) {
  shiftFilters['shift_type__id'] = values.shift;
}
if (values.start_date) {
  shiftFilters.start_date = moment(values.start_date).format('YYYY-MM-DD')
}
if (values.end_date) {
  shiftFilters.end_date =  moment(values.end_date).format('YYYY-MM-DD')
}


if (values.runroutes) {
  let runRouteFilter = values.runroutes.map(data => data.id)
  let strRunRouteFilter = runRouteFilter.join(",")
  shiftFilters.runroute = strRunRouteFilter;
}

if (values.shift_type_filter) {
  let runRouteFilter = values.shift_type_filter.map(data => data.id)
  let strRunRouteFilter = runRouteFilter.join(",")
  shiftFilters.shift_type_filter = strRunRouteFilter;
}

if (values.runroutesclient) {
  let runRouteFilter = values.runroutesclient.map(data => data.id)
  let strRunRouteFilter = runRouteFilter.join(",")
  shiftFilters.runrouteclient = strRunRouteFilter;
}

if (values.runroutesteam) {
  let runRouteFilter = values.runroutesteam.map(data => data.id)
  let strRunRouteFilter = runRouteFilter.join(",")
  shiftFilters.employee_run_route_ids = strRunRouteFilter;
}

if (values.jobDepartment) {
  let jobDepartmentFilter = values.jobDepartment.map(data => data.id)
  let strJobDepartmentFilter = jobDepartmentFilter.join(",")
  shiftFilters.employee__job_detail__job_category__id = strJobDepartmentFilter;
}

if (values.branch) {
  let branchFilter = values.branch.map(data => data.id)
  let strbranchFilter = branchFilter.join(",")
  if (strbranchFilter.branch.includes('None')) {
    shiftFilters.null_branch = 'None';
    const branchIdsWithoutNoneId = branchFilter.split(",").filter(e => e !== 'None').join(",")
    if (branchIdsWithoutNoneId)
      shiftFilters.employee__job_detail__branch__id= branchIdsWithoutNoneId;
  } else {
    shiftFilters.employee__job_detail__branch__id= strbranchFilter;
  }
} else if (values.headerbranch) {
    let branchFilter = values.headerbranch.map(data => data.id)
    let strheaderbranchFilter = branchFilter.join(",")
  if (strheaderbranchFilter.includes('None')) {
    shiftFilters.null_branch = 'None';
    const branchIdsWithoutNoneId = branchFilter.split(",").filter(e => e !== 'None').join(",")
    if (branchIdsWithoutNoneId)
      shiftFilters.employee__job_detail__branch__id= branchIdsWithoutNoneId;
  } else {
    shiftFilters.employee__job_detail__branch__id=  strheaderbranchFilter;
  }
}

if (values.employees) {
  let employeeID = {}
 
  let employeeFilter = values.employees.map(data => data.id)
  if (employeeFilter.find(emp => emp == 'none')) {
    let strEmployeeFilter = employeeFilter.filter(emp => emp != "none").join(",")
    employeeID.employees_id = strEmployeeFilter;
  } else {
    let strEmployeeFilter = employeeFilter.join(",")
    employeeID.employees_id = strEmployeeFilter;
  }

  if (employeeID.employees_id) {
    if(exclude.employee){
      shiftFilters.employee__id__exclude = employeeID.employees_id
    }else{
      shiftFilters.employee_ids = employeeID.employees_id
    }
  }
}


if (values.pay_type){
  let payTypeIds = values.pay_type.map(el => el.id).join(",")
  shiftFilters.pay_type_ids = payTypeIds;
}

const method = PATCH;
  const baseUrl = getBaseUrl2();
  const url = `${baseUrl}/shifts/shifts/${shiftId}/dragAndDropShift/`;
  const payload = {
    ...body,
    shiftFilters,
  };
  return post(url, payload, method);
  //http://localhost:8000/org/bill-test-org-1/api/v2/shifts/shifts/e58cdbf0-22d0-4002-9071-f679909eb858/dragAndDropShift/
}
export const UnassignAllVisits = values => {
  const method = POST;
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/optaplanner/bulk-unassigned`;
  const body = {
    ...values,
  };
  return post(url, body, method);
};
export const dragRunVisit = values => {
  const method = PATCH;
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/scheduler/visits/update_visit_run_name/`;
  const body = {
    ...values,
  };
  return post(url, body, method);
};
export const AssignAllVisits = values => {
  const method = POST;
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/optaplanner/bulk-Reassign`;
  const body = {
    ...values,
  };
  return post(url, body, method);
};

export const AssignFunder = values => {
  const method = PATCH;
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/scheduler/visits/visit_wise/`;
  const body = {
    ...values,
  };
  return post(url, body, method);
};

export const duplicateVisits = values => {
  const method = POST;
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/scheduler/visits/create_visit_data/`;
  const body = {
    ...values,
  };
  return post(url, body, method);
};
export const undoCancelVisit = values => {
  const method = PATCH;
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/scheduler/visits/revert_cancel/`;
  const body = {
    ...values,
  };
  return post(url, body, method);
};
export const setShadowVisits = (values, observKey) => {
  const method = PATCH;
  const baseUrl = getBaseUrl();
  const url = observKey
    ? `${baseUrl}/scheduler/visits/shadow_visit/?key=${observKey}`
    : `${baseUrl}/scheduler/visits/shadow_visit/`;
  const body = {
    ...values,
  };
  return post(url, body, method);
};

export const setTravelRate = values => {
  const method = PATCH;
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/shifts/shifts/attach_travel_rate/`;
  const body = {
    ...values,
  };
  return post(url, body, method);
};

export const setRateCardRota = values => {
  const method = PATCH;
  const baseUrl = getBaseUrl2();
  const url = `${baseUrl}/shifts/shifts/bulk_rate_card/`;
  const body = {
    ...values,
  };
  return post(url, body, method);
};

export const AdjustlVisits = values => {
  const method = POST;
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/optaplanner/bulk-AdjustTime`;
  const body = {
    ...values,
  };
  return post(url, body, method);
};

export const setBulklVisitsTime = values => {
  const method = POST;
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/optaplanner/bulk-SetTime`;
  const body = {
    ...values,
  };
  return post(url, body, method);
};

export const setPlannedVisitsAssign = values => {
  const method = POST;
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/optaplanner/manual-planned`;
  const body = {
    ...values,
  };
  return post(url, body, method);
};

export const setRateCard = values => {
  const method = PATCH;
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/scheduler/visits/visit_bulk_rate_card_update/`;
  const body = {
    ...values,
  };
  return post(url, body, method);
};

export const setBulkMedication = values => {
  const method = PATCH;
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/medication/medicationVisitWise/attachMedication/`;
  const body = {
    ...values,
  };
  return post(url, body, method);
};

export const SetActualBulkTime = values => {
  const method = PATCH;
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/scheduler/bulk-clock/bulk_clock_in_out/`;
  const body = {
    ...values,
  };
  return post(url, body, method);
};
export const SetBulkTimeRotaShiftWithShift = values => {
  const method = PATCH;
  const baseUrl = getBaseUrl2();
  const url = `${baseUrl}/shifts/shifts/edit_actual/`;
  const body = {
    ...values,
  };
  return post(url, body, method);
};

export const SetReassignShift = values => {
  const method = PATCH;
  const baseUrl = getBaseUrl2();
  const url = `${baseUrl}/shifts/shifts/reassign_shifts/`;
  const body = {
    ...values,
  };
  return post(url, body, method);
};

export const deleteShiftBulk = values => {
  const method = DELETE;
  const baseUrl = getBaseUrl2();
  const url = `${baseUrl}/shifts/shifts/bulk_delete/`;
  const body = {
    ...values,
  };
  return post(url, body, method);
};
export const SetBulkTimeRotaShiftWithOutShift = values => {
  const method = PATCH;
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/shifts/availability-clock/clock/`;
  const body = {
    ...values,
  };
  return post(url, body, method);
};

export const SetRevertBulkClocked = (values, clock) => {
  const method = PATCH;
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/scheduler/bulk-clock/bulk_revert_${clock}/`;
  const body = {
    ...values,
  };
  return post(url, body, method);
};

export const revertVisits = values => {
  const method = PATCH;
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/scheduler/visits/revert_visit/`;
  const body = {
    ...values,
  };
  return post(url, body, method);
};

export const copyVisits = values => {
  const method = POST;
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/scheduler/visits/copyVisit/`;
  const body = {
    ...values,
  };
  return post(url, body, method);
};

export const publishOrUnpublishVisits = values => {
  const method = PATCH;
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/scheduler/visits/RevertPublish/`;
  const body = {
    ...values,
  };
  return post(url, body, method);
};

export const getSuggestedEmployee = visitId => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/optaplanner/visit-suggestion?visit_id=${visitId}`;
  return get(url);
};

//scheduler  modal pop up audit history  get api
export const getAuditHistoryData = (visitId, values) => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  if (values.search) {
    toParse.search = values.search;
  }
  if (values.ordering) {
    toParse.ordering = values.ordering;
  }
  if (visitId) {
    toParse.visit_id = visitId;
  }
  // if (values.offset) {
  toParse.offset = values.offset;
  // }
  toParse.limit = values.limitperpage;

  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/scheduler/visits/get_audit_history/?${stringified}`;
  return get(url);
};

export const getMedAuditHistoryData = (med_id) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/medication/getMedicationAuditHistory/?medication=${med_id}`;
  return get(url);
};



//Body Map Apis
//get body parts
export const getVisitWiseBodyParts = async (visitId) => {
  const baseUrl = getBaseUrl();

  const url = `${baseUrl}/clients/client/body-part/visit/wise/?visit=${visitId}`

  return await get(url)
}

//Add new body parts
export const addNewBodyPart = async (body) => {
  const baseUrl = getBaseUrl();
  const method = POST
  const url = `${baseUrl}/clients/client/body-part/`

  return await post(url, body, method)
}

//Update body part

export const updateVistWiseBodyPart = async (body, bodyPartId) => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/clients/client/body-part/${bodyPartId}/`

  return await post(url, body, method)
}

//Delete body part 

export const deleteVistBodyPart = async (id) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/clients/client/body-part/${id}/`
  return await deleteFetch(url)
}


//Vist wise expenses
export const getVsitWiseExpensesList = async (visitId, values) => {
  const baseUrl = getBaseUrl();
  let stringified = ""
  let toParse = {};
  toParse.offset = values.offset
  toParse.limit = values.limitperpage || 20;
  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/employees/employee/expense-visit/?visit_id=${visitId}&${stringified}`

  return await get(url)
}


export const addNewExpenses = async (body, selectedId) => {
  const baseUrl = getBaseUrl();
  const method = selectedId ? PATCH : POST;
  const url = selectedId ?
    `${baseUrl}/employees/employee/expense-visit/${selectedId}/` :
    `${baseUrl}/employees/employee/expense-visit/`

  const formData = new FormData()
  for (let [key, value] of Object.entries(body)) {
    if (
      typeof value === 'object' &&
      value !== null &&
      key !== 'expense_document'
    ) {
      for (let [keySub, valueSub] of Object.entries(value)) {
        if (valueSub !== null) {
          formData.append(
            `${key}.${keySub}`,
            Array.isArray(valueSub) ? JSON.stringify(valueSub) : valueSub
          );
        } else {
          formData.append(`${key}.${keySub}`, '');
        }
      }
    }
    // else if(key==='visits'){
    //   formData.append(key,JSON.stringify(value))

    //  }
    else {
      if (value !== null) {
        formData.append(key, value);
      } else {
        formData.append(key, '');
      }
    }
  }

  const response = await fetch(url, {
    body: formData,
    headers: {
      Authorization: 'Token ' + getToken(),
    },
    method: method,
  });

  const json = await response.json();

  return json;
}


export const deleteExpense = async (expenseId) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/employees/employee/expense-visit/${expenseId}/`

  return deleteFetch(url)
}


export const getLocationList = async () => {
  const baseUrl = getBaseUrl();

  const url = `${baseUrl}/clients/client/location/location-list/`

  return await get(url)
}


export const updateVisitTravelMode = (body) =>{
  const baseUrl = getBaseUrl();
  const method = PATCH
  const url = `${baseUrl}/scheduler/visits/update_visit_travel_mode/`;
 
  return post(url, body, method);
}

export const getDynamicPayRate = (values) => {
  let stringified = '';
  let toParse = {};
  const baseUrl = getBaseUrl();

  toParse.start_date = values.start_date
  toParse.employee__id__in = values.employeeId
  toParse.branch__id__in = values.branch
  toParse.shift_type = values.shiftType

  stringified = queryString.stringify(toParse) || '';
  const url = `${baseUrl}/employees/employee/rate/card/?${stringified}&populate_rate_card=true`;

  return get(url);
};


export const getVisitCareForm = values => {
  const baseUrl = getBaseUrl();
  let stringified = '';
  let toParse = {};

  toParse.offset = values.offset;
  toParse.limit = values.limitperpage;
  if (values.visit_id) {
    toParse.visit_id = values.visit_id;
  }
 
  stringified = queryString.stringify(toParse) || '';

  const url = `${baseUrl}/form-builder/visit/form/?${stringified}`;
  return get(url);
};

export const deleteVisitCareForm = async id => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/form-builder/visit/form/${id}/`;

  return deleteFetch(url);
};

export const setVisitFormAssign = values => {
  const baseUrl = getBaseUrl();
  const method = POST;
  const url = `${baseUrl}/form-builder/visit/form/`;

  const body = {
    ...values,
  };

  return post(url, body, method);
};


export const editCareForumVisit = (values, id) => {
  const baseUrl = getBaseUrl();
  const method = PATCH;
  const url = `${baseUrl}/form-builder/visit/form/${id}/`;
  const body = {
    ...values,
  };
  return post(url, body, method);
};

export const getEmployeesRunRouteWise = (values) => {
  let stringified = '';
  let toParse = {};
  const baseUrl = getBaseUrl();
  if (values.runroutesteam && values.runroutesteam.length) {
    let employeeiIds = values.runroutesteam.map(el => el.id).join(',');
    toParse.run_route_employee = employeeiIds
  }
  if (toParse.run_route_employee)
    stringified += `run_route_employee=${toParse.run_route_employee}`
  const url = `${baseUrl}/scheduler/visits/teamRunRouteRelatedEmployees/?${stringified}`;

  return get(url);
};


export const bulkDeleteRegularVisits = async (body) => {
  const baseUrl = getBaseUrl();
  const method = PATCH;

  const url = `${baseUrl}/clients/client/pinned-employee/bulkDelete/`;
  return await patch(url, body, method)
}