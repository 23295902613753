import React, { useState, useEffect, Fragment, useRef } from 'react';
import LineContainer from 'components/SharedComponents/LineContainer';
import Spinner from 'components/SharedComponents/Spinner';
import { GRAY_700, PURPLE } from 'shared/styles/constants/colors';
import {
  StylePointer,
  TitleSection,
  SettingItemsContainer,
  LoadingContainer,
} from 'shared/styles/constants/tagsStyles';
import { getPageToSearchParam } from 'shared/methods';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import Pagination from 'components/SharedComponents/Pagination';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import { useModal } from 'hooks/ModalHook';
import { FaPlus } from 'react-icons/fa';
import ItemList from './ItemList';
import ItemListStaffCheck from './ItemListStaffCheck';
import {
  AccordionArrow,
  AccordionContainer,
  StaticArrow,
} from 'components/DashboardComponents/LeftSidebar/styles';
import { Toast } from 'primereact/toast';
import {
  ON_SAVE,
  ON_EDIT,
  ON_ERROR,
  ON_LOADING,
  ON_DELETE,
  ON_REMOVE,
  ON_CANCEL,
} from 'utils/constants/settingsStates';
import { values } from 'lodash';
import moment from 'moment';
import InputLabelTop from '../InputLabelTop';
import { useInput } from 'hooks/InputHook';
import { AiOutlineClose } from 'react-icons/ai';
import { MdSearchOff, MdSearch } from 'react-icons/md';
import Fade from 'react-reveal/Fade';
import {SearchContainer} from "./styles"

const Template = ({
  titleSection,
  labelInputTitle,
  getMethod,
  setMethod,
  deleteMethod,
  textToMessageDelete,
  textToEmptyDataValues = "Data not found",
  addOneDropdown,
  addSecondDropdown,
  addCheckBox,
  specializeCase,
  specializedMethods,
  addOneInput,
  addSecondInput,
  fieldAttributes = {},
  update,
  create,
  deletePermission,
  userStaff,
  staffCheck,
  handleChangeChoices = () => { },
  deletePermissionPrioritySettings,
  readPermissionPrioritySettings,
  updatePrioritySettings,
  filterAdmin,
  filterSuperAdmin,
  createPrioritySettings,
  createPermissionEmployeType,
  addColorPickerInput,
  addInput,
  setUserType,
  defaultColor = "",
  // cardBodyName,
  isEditable = false,
  coreCalender = false,
  size,
  setExpandable = () => { },
}) => {
  const [simplePagination] = useState(true);
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataValues, setDataValues] = useState([]);
  const [idSelected, setIdSelected] = useState('');
  const [enabledInput, setEnabledInput] = useState('');
  const [leftButtonIcon, setLeftButtonIcon] = useState(ON_EDIT);
  const [rightButtonIcon, setRightButtonIcon] = useState(ON_DELETE);
  const [loading, setLoading] = useState(true);
  const mountedRef = useRef(true);
  const [data, setData] = useState();
  const [offset, setOffSet] = useState(0);
  const [pageSizes, setPageSizes] = useState(10);
  const [delCheck, setDelCheck] = useState(false);
  const { setValue: setModalDeleteValue, bind: bindModalDelete } =
    useModal(false);
  const toast = useRef();

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    loadDataValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const loadDataValues = () => {
    const createPageSize = PAGE_SIZE_RENDER / 2;

    let pageToSearch = getPageToSearchParam(
      '',
      setCurrentPage,
      simplePagination,
      currentPage,
      createPageSize,
      dataValues
    );

    getMethod({ ...pageToSearch }, createPageSize).then(res => {
      if (!mountedRef.current) return null;

      if (specializeCase && specializeCase === 'role') {
        //below added res.result as in get api data binding position now is resp.results
        setDataValues(
          res.results ||
          res.absence_type ||
          res.absence_reason ||
          res.absence_informed_method ||
          res.absence_informed_method ||
          res.absence_planned ||
          res.absence_paid ||
          res.count_deduction ||
          res.medication ||
          res.count_deduction ||
          res.value_int ||
          res.count_deduction ||
          []
        );
      } else {
        if (coreCalender) {
          let year = new Date().getFullYear()
          res.results = res.results.map(el => {
            let start_date = moment(year + '-' + el.start_date).format("YYYY-MM-DD")
            let end_date = moment(year + '-' + el.end_date ).format("YYYY-MM-DD")
            console.log(end_date, start_date)
            return { ...el, end_date, start_date }
          })
        }
        setDataValues(
          res.results ||
          res.absence_type ||
          res.absence_reason ||
          res.absence_informed_method ||
          res.absence_informed_method ||
          res.absence_planned ||
          res.absence_paid ||
          res.count_deduction ||
          res.medication ||
          res.user_type_category ||
          res.value_int ||
          res.count_deduction ||
          []
        );
      }

      setPages(Math.ceil(res.count / createPageSize));
      if (res.results.length === 0 && currentPage >= 1) {
        setCurrentPage(currentPage - 1);
      } else if (res.results.length === 1 && !currentPage) {
        setCurrentPage(1);
      }
      setLoading(false);
    });
  };

  const addNewService = () => {
    const haveNew = dataValues.find(data => data.id === '');

    if (haveNew) {
      return;
    }

    setDataValues([...dataValues, { id: '', name: '' }]);

    setLeftButtonIcon(ON_SAVE);
    setRightButtonIcon(ON_REMOVE);

    setEnabledInput('');
  };

  const removeNewService = () => {
    dataValues.forEach(function (value, index) {
      if (!value.id) {
        dataValues.splice(index, 1);
      }
    });
    setDataValues([...dataValues]);
  };

  const handleSubmit = async valuesToSave => {
    if (!valuesToSave.name) {
      return;
    }
    setLeftButtonIcon(ON_LOADING);
    let setResponse = valuesToSave;

    let body = { name: valuesToSave.name, id: valuesToSave.id };
    let newData = { name: valuesToSave.name };
    if (specializeCase && specializeCase === 'role') {
      let myId = {};
      if (valuesToSave.id) {
        myId = {
          id: valuesToSave.id,
        };
      }

      body = {
        role: {
          ...myId,
          name: valuesToSave.name,
          lable: valuesToSave.value,
          is_staff: valuesToSave.is_staff ? valuesToSave.is_staff : false,

        },
      };
    }

    if (addOneDropdown) {
      if (addSecondDropdown) {
        body[addSecondDropdown.value] = valuesToSave[addSecondDropdown.value];
      } else {
        body[addOneDropdown.value] = valuesToSave[addOneDropdown.value];
        newData[addOneDropdown.value] = valuesToSave[addOneDropdown.value];
      }
    }
    if (addCheckBox) {
      body[addCheckBox.value] = valuesToSave[addCheckBox.value];
    }
    if (addOneInput) {
      body[addOneInput.value] = valuesToSave[addOneInput.value];
      newData[addOneInput.value] = valuesToSave[addOneInput.value];
    }
    if (addSecondInput) {
      body[addSecondInput.value] = valuesToSave[addSecondInput.value];
    }
    if (addInput && addInput.length) {
      addInput.map(input => {
        
        if (input.value === 'user_type') {
          body.role.user_type = valuesToSave.user_type
        } else if (input.value === 'color_code') {
          body[input.value] = valuesToSave[input.value] ? valuesToSave[input.value] : defaultColor ? defaultColor : GRAY_700
          newData[input.value] = valuesToSave[input.value] ? valuesToSave[input.value] : defaultColor ? defaultColor : GRAY_700
          debugger
        }else if (input.value === 'start_date'|| input.value === 'end_date') {
          console.log(valuesToSave[input.value],"valuesToSave")
          body[input.value] = valuesToSave[input.value].slice(5,10);
          newData[input.value] = valuesToSave[input.value].slice(5,10);
        }
         else {
          body[input.value] = valuesToSave[input.value]
          newData[input.value] = valuesToSave[input.value];
        }
      })
    }
    try {
      let response = await setMethod(
        body.id !== '' ? body : newData,
        valuesToSave.category,
        valuesToSave.absence,
        valuesToSave.userTypeCategory,
        valuesToSave.value_int && valuesToSave.value_int,
        valuesToSave.count_deduction
      );
      loadDataValues();
      setLeftButtonIcon(ON_EDIT);
      if (response.id || response.status == "SUCCESS") {
        setResponse = response;
        onSuccess();
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: `${textToMessageDelete} Added successfuly`,
        });
      } else if (response.status === 'FAIL') {
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: `${response.message}`,
        });
      }
    } catch (error) {
      if (error.status === 'FAIL' || error.status === 'False') {
        onError(valuesToSave);
        setResponse = false;
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: `${error.message}`,
        });
      }
    }

    return setResponse;
  };

  const onSuccess = () => {
    setEnabledInput('');
    setLeftButtonIcon(ON_EDIT);
    setRightButtonIcon(ON_DELETE);
    handleChangeChoices();
    loadDataValues();
  };

  const onError = valuesToSave => {
    if (valuesToSave.id) {
      setRightButtonIcon(ON_CANCEL);
    } else {
      setRightButtonIcon(ON_REMOVE);
    }
    setLeftButtonIcon(ON_ERROR);
  };

  const toDeleteId = id => {
    if (!id) {
      return;
    }
    setModalDeleteValue(true);
    setIdSelected(id);
  };

  const handleDelete = async () => {
    await deleteMethod(idSelected);
    setModalDeleteValue(false);
    setIdSelected('');

    setLeftButtonIcon(ON_EDIT);
    setRightButtonIcon(ON_DELETE);
    setEnabledInput('');
    handleChangeChoices();
    loadDataValues();

    if (specializeCase && specializeCase === 'role') {
      specializedMethods.updateRolValues();
    }
  };

  const toEditId = id => {
    setEnabledInput(id);
    setLeftButtonIcon(ON_SAVE);
    if (id) {
      setRightButtonIcon(ON_CANCEL);
      removeNewService();
    } else {
      setRightButtonIcon(ON_REMOVE);
    }
  };

  const cancelEdit = () => {
    setEnabledInput('');
    setLeftButtonIcon(ON_EDIT);
    setRightButtonIcon(ON_DELETE);
  };

  const tryReSubmit = id => {
    setLeftButtonIcon(ON_SAVE);
    if (!id) {
      setRightButtonIcon(ON_REMOVE);
    } else {
      setRightButtonIcon(ON_CANCEL);
    }
  };

  const [filteredDataValues, setFilteredDataValues] = useState([]);
  const {value: searchFilterText, setValue: setSearchFilterText, reset: resetSearchFilterText} = useInput("")
  useEffect(()=>{
    if(!dataValues) return;
    setFilteredDataValues(dataValues.filter(value=>value.name.toLowerCase().includes(searchFilterText.toLowerCase())))
  }, [searchFilterText, dataValues])

  const [showSearch, setShowSearch] = useState(false);

  return (
    <LineContainer>
      <Toast ref={toast} position="top-right" />
      <div className='d-flex justify-content-between align-items-center px-3 py-2'>
        <TitleSection style={{
          marginRight: size==="sm"?"1rem":"2rem",
          whiteSpace: "nowrap",
          minWidth: size==="sm"?"6rem":"10rem"
        }}>{titleSection}</TitleSection>
        <div className="d-flex align-items-center">
            <span
              role="button"
              onClick={() => {
                setShowSearch(!showSearch);
              }} className={`d-flex justify-content-center align-items-center p-2 ${showSearch && size!=="sm" && "mr-2"}`}>
              {
                showSearch?<MdSearch size={24} />:<MdSearch size={24} />
              }
            </span>
            <SearchContainer show={showSearch} size={size}>
              <Fade collapse when={showSearch}>
                <div className="d-flex align-items-center">
                  <InputLabelTop
                    label={`Search ${titleSection}`}
                    name="search"
                    id={`${titleSection}-search`}
                    type="text"
                    no_internal_margin
                    value={searchFilterText}
                    onChange={(e)=>setSearchFilterText(e.target.value)}
                  />
                  {
                    size!=="sm"&&
                    <span
                      role="button"
                      className='p-2'
                      onClick={resetSearchFilterText}
                    >
                      <AiOutlineClose />
                    </span>
                  }
                </div>
              </Fade>
            </SearchContainer>
          </div>
      </div>

      {loading ? (
        <LoadingContainer>
          <Spinner />
        </LoadingContainer>
      ) : (
        <Fragment>
          <SettingItemsContainer
            minHeight={`${filteredDataValues.length >= 1 ? '30rem' : ''}`}>
            {filteredDataValues.length >= 1 ? (
              staffCheck ? (
                <Fragment>
                  {filteredDataValues.map((dataValue, indexValue) => {
                    return (
                      <ItemListStaffCheck
                        key={indexValue}
                        dataValue={dataValue}
                        handleSubmit={handleSubmit}
                        toDeleteId={toDeleteId}
                        toEditId={toEditId}
                        cancelEdit={cancelEdit}
                        removeNewService={removeNewService}
                        leftButtonIcon={leftButtonIcon}
                        rightButtonIcon={rightButtonIcon}
                        enabledInput={enabledInput}
                        setEnabledInput={setEnabledInput}
                        tryReSubmit={tryReSubmit}
                        labelInputTitle={labelInputTitle}
                        addOneDropdown={addOneDropdown}
                        specializeCase={specializeCase}
                        specializedMethods={specializedMethods}
                        addOneInput={addOneInput}
                        fieldAttributes={fieldAttributes}
                        update={update}
                        create={create}
                        deletePermission={deletePermission}
                        userStaff={userStaff}
                        filterS
                        addInput={addInput}
                        setUserType={setUserType}
                        setExpandable={setExpandable}
                      />
                    );
                  })}
                </Fragment>
              ) : (
                <Fragment>
                  {filteredDataValues.map((dataValue, indexValue) => {
                    return (
                      <ItemList
                        key={indexValue}
                        dataValue={dataValue}
                        handleSubmit={handleSubmit}
                        toDeleteId={toDeleteId}
                        toEditId={toEditId}
                        cancelEdit={cancelEdit}
                        removeNewService={removeNewService}
                        leftButtonIcon={leftButtonIcon}
                        rightButtonIcon={rightButtonIcon}
                        enabledInput={enabledInput}
                        setEnabledInput={setEnabledInput}
                        tryReSubmit={tryReSubmit}
                        labelInputTitle={labelInputTitle}
                        addOneDropdown={addOneDropdown}
                        addSecondDropdown={addSecondDropdown}
                        addCheckBox={addCheckBox}
                        specializeCase={specializeCase}
                        specializedMethods={specializedMethods}
                        addOneInput={addOneInput}
                        addSecondInput={addSecondInput}
                        addInput={addInput}
                        addColorPickerInput={addColorPickerInput}
                        fieldAttributes={fieldAttributes}
                        update={update}
                        create={create}
                        deletePermission={deletePermission}
                        userStaff={userStaff}
                        updatePrioritySettings={updatePrioritySettings}
                        createPrioritySettings={createPrioritySettings}
                        deletePermissionPrioritySettings={
                          deletePermissionPrioritySettings
                        }
                        readPermissionPrioritySettings={
                          readPermissionPrioritySettings
                        }
                        filterAdmin={filterAdmin}
                        filterSuperAdmin={filterSuperAdmin}
                        isEditable={isEditable}
                      />
                    );
                  })}
                </Fragment>
              )
            ) : (
              <div
                className={
                  'pt-4 pb-4 pl-3 pr-2 mb-4 ml-3 mr-3 border rounded text-secondary d-flex justify-content-center text-center'
                }>
                {textToEmptyDataValues}
              </div>
            )}
          </SettingItemsContainer>

          <div>
            <div className="mt-3 text-center">
              <Pagination
                simplePagination={simplePagination}
                setCurrentPage={setCurrentPage}
                totalPages={pages}
                currentPage={currentPage}
                reduceMargin={true}
                dropdownEntriesLimit={false}
                dataLength={filteredDataValues.length}
              />
            </div>
            {/* {update&&( */}
            <div className={'w-100 pr-3 pt-3 pb-3 text-right'}>
              <StylePointer onClick={addNewService}>
                <FaPlus color={PURPLE} size={26} />
              </StylePointer>
            </div>
            {/* )
} */}
          </div>

          <ModalDecision
            type="delete"
            title="Warning"
            body={`Are you sure you want to delete this ${textToMessageDelete} ? \n delete the ${textToMessageDelete} will remove all records from database.`}
            onOk={() => {
              handleDelete();
              setDelCheck(true);
            }}
            onCancel={() => {
              setIdSelected('');
            }}
            okTitle={'DELETE'}
            {...bindModalDelete}
          />
        </Fragment>
      )}
    </LineContainer>
  );
};

export default Template;
