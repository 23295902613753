import React, { useEffect, useRef, useState, useContext } from 'react';
import TimelineCalendar from 'components/SharedComponents/TimelineCalendar';
import { RotaShiftContainer, ToggleDiv } from './styles';
import Dropdown from 'components/SharedComponents/Dropdown';
import { getEmployee, getClient } from '../../../utils/localStorage/token';
import {
  PrimaryButtonForm,
  Toggle,
  ToggleLabel,
  ToggleWrapper,
} from 'shared/styles/buttons';
import { DOWNLOAD, ERROR_OUTLINE } from 'utils/constants/icons';
import { useForm } from 'hooks/FormHook';
import { useModal } from 'hooks/ModalHook';
import RotaListView from './RotaListView/RotaListView';
import { getEmployeeBranchAccess, getEmployees } from 'utils/api/EmployeeApi';
import { createDateWithTime, validateDate, validateTime } from 'shared/methods';
import {
  deleteAvailabilities,
  deleteAvailabilitiesDetails,
  deleteShift,
  deleteShiftDetails,
  getAvailabilities,
  getRotaShifts,
  getSchedulerInRotaShifts,
  getShift,
  getShiftPatterns,
  setAvailability,
  setAvailabilityDetails,
  setEditBulk,
  setShift,
  setSplitAvailability,
  setUnassignedVisitOnRota,
} from 'utils/api/RotaShift';
import {
  AVAILABLE_EXISTING_SHIFT,
  AVAILABLE_EXISTING_UNAVAILABLE,
  FILTER_NONE_ID,
  SHIFT_EXISTING_AVAILABLE,
  SHIFT_EXISTING_SHIFT,
  SHIFT_EXISTING_UNAVAILABLE,
  TYPE_AVAILABLE,
  TYPE_SHIFT,
  TYPE_UNAVAILABLE,
  UNAVAILABLE_EXISTING_AVAILABLE,
  UNAVAILABLE_EXISTING_SHIFT,
} from 'utils/constants/rotaShift';
import { ButtonsContainerRota } from './styles';
import {
  getScheduler,
  getWaitTime,
  getEmployeeVisitData,
  deleteShiftBulk,
  getDownloadRotaShiftFile,
  getActiveEmp,
} from 'utils/api/SchedulerApi';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import { getChoices, getChoicesMap } from 'utils/api/CoreApi';
import {
  ERROR,
  InvalidOrEmptyValues,
  InvalidRange,
  SAVE,
  SAVED,
} from 'utils/constants/messages';
import {
  CHECK,
  CROSS,
  FLOPPY,
  Home_ICON,
  BIKE_ICON,
  CAR_ICON,
  FOOT_ICON,
} from 'utils/constants/icons';
import {
  AddAvailability,
  AddUnavailability,
  AssignShiftModal,
} from './ButtonsModals';
import {
  ButtonsModals,
  DataContainer,
  FiltersContainerRotaShifts,
  FiltersLeftGroupRotaShifts,
  FiltersTypesContainerRotaShifts,
  Title,
} from 'shared/styles/constants/tagsStyles';
import {
  GRAY_820,
  ROTA_AVAILABLE,
  ROTA_SHIFT,
  ROTA_UNAVAILABLE,
  ROTA_ABSENCE,
  TRAVEL_TIME_COLOR,
  WHITE,
  WAIT_TIME_COLOR,
  BREAK_WAIT_COLOR,
  GRAY_700,
} from 'shared/styles/constants/colors';
import moment from 'moment';
import {
  ROTA_ADD_AVAILABLE,
  ROTA_ADD_UNAVAILABLE,
  ROTA_ASSIGN_SHIFT,
  ROTA_SHIFTS_SETTINGS,
  SELF_WEB_CREATE_PERMISSION,
  SELF_WEB_READ_PERMISSION,
  SELF_WEB_UPDATE_PERMISSION,
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  WEB_PERMISSION,
  WEB_READ_PERMISSION,
  WEB_UPDATE_PERMISSION,
} from ' ../../utils/constants/permissions';
import { getPayRates } from 'utils/api/ClientApi';
import SplitOverlapModal from './ButtonsModals/SplitOverlapModal';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import { AppContext } from 'context';
import { BranchItemsContext } from 'context';
import _ from 'lodash';
import ActualEdits from './ButtonsModals/ActualEdits';
import RevertClocked from './ButtonsModals/RevertClocked';
import EditBulk from './ButtonsModals/EditBulk';
import Spinner from 'components/SharedComponents/Spinner';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import {
  ItemContainer,
  MainContainer,
  PublishButton,
  RightContainer,
} from 'components/SharedComponents/ProgressInformation/styles';
import AssignTravelRate from './ButtonsModals/AssignTravelRate';
import AssignRate from './ButtonsModals/AssignRate';
import ReassignShiftModal from './ButtonsModals/ReassignShiftModal';
import ShiftSplitOverlapModal from './ButtonsModals/ShiftSplitOverlapModal';
import { rota_shift_choices } from 'utils/choiceConstant';
import ConfirmationModal from './ConfirmationMdal';
import ContextMenu, { useContextMenu } from '../../SharedComponents/ContextMenu/ContextMenu';
import { Toast } from 'primereact/toast';
const SHIFT_VALUES = 'shiftValues';

const RotaShifts = props => {
  const { values, setValues, useInput, errors, setCheckRequires } = useForm({
    [SHIFT_VALUES]: {},
  }); //added new  [SHIFT_VALUES]: {},
  const [employees, setEmployees] = useState([]);
  const [employeeVisitData, setEmployeeVisitData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingOverlap, setLoadingOverlap] = useState([]);
  const [loadingProceed, setLoadingProceed] = useState([]);
  const [disableButton, setDisableButton] = useState([]);

  const [patterns, setPatterns] = useState([]);
  const [patternsCombo, setPatternsCombo] = useState([]);
  const [shiftType, setShiftType] = useState('resourceTimelineDay');
  const [resources, setResources] = useState([]);
  const [employeeFilter, setEmployeeFilter] = useState([]);
  const [includeVisits, setIncludeVisits] = useState(false);
  const [scheduleEvents, setScheduleEvents] = useState([]);
  const [rotaEvents, setRotaEvents] = useState([]);
  const [totalRotaEvents, setTotalRotaEvents] = useState([]);
  const [dateMessage, setDateMessage] = useState('Is required');
  const [timeMessage, setTimeMessage] = useState('Is required');
  const [dateError, setDateError] = useState(false);
  const [timeError, setTimeError] = useState(false);
  const [invalidOvernight, setInvalidOvernight] = useState(false);
  const [assignedEmployees, setAssignedEmployees] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [choices, setChoices] = useState({ client_status: [] });
  const [availableHours, setAvailableHours] = useState(0);
  const [unavailableHours, setUnavailableHours] = useState(0);
  const [travelHours, setTravelHours] = useState(0);
  const [waitHours, setWaitHours] = useState(0);
  const [shiftHours, setShiftHours] = useState(0);
  const [visitHours, setVisitHours] = useState(0);
  const [isDateAssigned, setIsDateAssigned] = useState(false);
  const [typeOfMessage, setTypeOfMessage] = useState('');
  const [errorTime, setErrorTime] = useState({});
  const [readPermission, setReadPermissions] = useState(false);
  const [readSelf, setReadSelfPermissions] = useState(false)
  const [update, setUpdatePermissions] = useState(false);
  const [create, setCreatePermissions] = useState(false);
  const [createAvailable, setCreateAvailablePermissions] = useState(false);
  const [createUnAvailable, setCreateUnAvailablePermissions] = useState(false);
  const [createAssignShift, setCreateAssignShiftPermissions] = useState(false);
  const [createSelfAvailable, setCreateSelfAvailablePermissions] =
    useState(false);
  const [createSelfUnAvailable, setCreateSelfUnAvailablePermissions] =
    useState(false);
  const [createSelfAssignShift, setCreateSelfAssignShiftPermissions] =
    useState(false);
  const [deletePermission, setDeletePermissions] = useState(false);
  // const [readPermission, setDeletePermissions] = useState(false);
  const [shiftOverlapData, setOverlapData] = useState([]);
  const [unassignedVisitList, setUnassignedVisitList] = useState([]);
  const [overlapMessage, setOverlapMessage] = useState('');
  const [currentModal, setCurrentModal] = useState(false);
  //dropdown filter runrotes+job department  below
  const [runRouteData, setRunRouteData] = useState([]);
  const [runRoutesFilter, setRunRoutesFilter] = useState([]);
  const [runRoutesclientFilter, setRunRoutesclientFilter] = useState([]);
  const [jobDepartment, setJobDepartment] = useState([]);
  const [jobDepartmentFilter, setJobDepartmentFilter] = useState([]);
  const [branch, setBranch] = useState([]);
  const [branchFilter, setBranchFilter] = useState([]);
  const [shiftTypeFilter, setShiftTypeFilter] = useState([]);
  const [shiftTypeChecked, setShiftTypeChecked] = useState([]);
  const [availabilitiesEmployeeId, setAvailabilitiesEmployeeId] = useState([]);
  const [availabilitiesEmployeeFullName, setAvailabilitiesEmployeeFullName] =
    useState([]);
  const [availabilitiesEmployeeIdName, setAvailabilitiesEmployeeIdName] =
    useState([]);
  const [checkedRows, setCheckedRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newShift, setNewShift] = useState({});
  const {
    contextChoices,
    contextChoicesMap,
    contextPermissions,
    contextSubSectionPermissions,
    contextIsAdmin,
    contextIsSuperAdmin,
    contextIsStaff,
    setContextChoices,
    setContextChoicesMap,
    handleGetChoices,
    handleChangeChoices
  } = useContext(AppContext);
  const { branchData } = useContext(BranchItemsContext);
  const [travelRate, setTravelRate] = useState([]);
  const [officeRate, setOfficeRate] = useState([]);
  const [onCallRate, setOnCallRate] = useState([]);
  const [payRate, setPayRate] = useState([]);
  const [TravelData, setTravelData] = useState([]);
  const [rotaView, setRotaView] = useState('teamView');
  const [detailId, setDetailId] = useState('');
  const [shiftTypePopup, setShiftTypePopup] = useState('');
  const { setValue: setModalDeleteValue, bind: bindModalDelete } =
    useModal(false);
  const mountedRef = useRef(true);
  //added week days below
  const [daysOfWeek, setDaysOfWeek] = useState([
    { day: 'MON', dayValue: 1, selected: false },
    { day: 'TUE', dayValue: 2, selected: false },
    { day: 'WED', dayValue: 3, selected: false },
    { day: 'THU', dayValue: 4, selected: false },
    { day: 'FRI', dayValue: 5, selected: false },
    { day: 'SAT', dayValue: 6, selected: false },
    { day: 'SUN', dayValue: 7, selected: false },
  ]);
  const [decreaseWidth, setDecreaseWidth] = useState(true);

  const filterEmployee = getEmployee();
  const filterClient = getClient();
  const userStaff = contextIsStaff;
  const filterSuperAdmin = contextIsSuperAdmin;
  const filterAdmin = contextIsAdmin;
  //added below require days of week
  const [requireDayOfWeek, setRequireDayOfWeek] = useState(false);
  const [display, setDisplay] = useState();
  const [regularshiftName, sertRegularshiftName] = useState();
  const [availabilityDetailsDays, setAvailabilityDetailsDays] = useState();
  const [listView, setListView] = useState(false);
  const [downloadDisable, SetDownloadDisable] = useState(false);
  const [newShiftId, setNewShiftId] = useState({});
  const [absenceId, setAbsenceId] = useState({});
  const [employeeDisable, setEmployeeDisable] = useState(true);
  const [employeeTempOnPopup, setEmployeeTempOnPopup] = useState([]);
  const [shiftTempOnPopup, setShiftTempOnPopup] = useState('');
  const [unassignedData, setUnassignedData] = useState([]);
  const [absenceRateCard, setAbsenceRateCard] = useState([]);
  const [handleDeleteClicked, setHandleDeleteClicked] = useState(false)
  const [allowSecondTimeApiCall, setAllowSecondTimeApiCall] = useState(false);
  let initialWait = 4;
  const [seconds, setSeconds] = useState(initialWait);

  const toast = useRef()
  const {
    setValue: setModalConfirmUnassignVisits,
    value: modalValueUnassignVisits,
    setTitle,
    fullBind: fullBindUnassignVisits,
  } = useModal(false);
  const {
    setValue: setAssignShiftModal,
    value: assignShiftModal,
    fullBind: fullBindAssignShiftModal,
  } = useModal(false);

  const {
    setValue: setAddAvailability,
    value: addAvailability,
    fullBind: fullBindAddAvailability,
  } = useModal(false);
  const {
    setValue: setBulkEditModal,
    value: bulkEditModal,
    fullBind: fullBindBulkEditModal,
  } = useModal(false);
  const {
    setValue: setAssignRate,
    value: assignRate,
    fullBind: fullBindAssignRate,
  } = useModal(false);
  const {
    setValue: setAddUnavailability,
    value: addUnavailability,
    fullBind: fullBindAddUnavailability,
  } = useModal(false);
  const {
    setValue: setOverlapModal,
    value: overlapModal,
    fullBind: fullBindOverlapModal,
  } = useModal(false);
  const {
    setValue: setOverlapModalShift,
    value: overlapModalShift,
    fullBind: fullBindOverlapModalShift,
  } = useModal(false);
  const {
    setValue: setEditActual,
    value: editActual,
    fullBind: fullBindEditActual,
  } = useModal(false);
  const {
    setValue: setAssignTravel,
    value: assignTravel,
    fullBind: fullBindAssignTravel,
  } = useModal(false);
  const {
    setValue: setReverClocked,
    value: ReverClocked,
    fullBind: fullBindReverClocked,
  } = useModal(false);
  const {
    setValue: setAssignShift,
    value: AssignShift,
    fullBind: fullBindAssignShift,
  } = useModal(false);


  useEffect(() => {
    handleGetChoices(rota_shift_choices)
    loadPayRates()
    loadPatterns();
    firstTimeApiCaller()
    return () => {
      mountedRef.current = false;
    };
  }, [])

  const firstTimeApiCaller = async () => {
    const { regular_shift_type ,...rest} = await handleChangeChoices('regular_shift_type') || {};
    contextChoices.regular_shift_type = regular_shift_type
    setContextChoices({...contextChoices, ...rest})
    if (values.end_date) {
      getEmployeeActiveData()
    }
    // Load apis first time
    if(values.start_date && values.end_date){
      searchRotaShift();
    }
   
    setTimeout(() => {
      setAllowSecondTimeApiCall(true)
    }, 2000)
  }

  console.log('branch_data rota', contextChoices)



  const setRunroutefilter = item => {
    setRunRoutesFilter([...item]);
  };
  const setRunrouteclientfilter = item => {
    setRunRoutesclientFilter([...item]);
  };
  const setJobdeptfilter = item => {
    setJobDepartmentFilter([...item]);
  };
  const setFilterBranch = item => {
    setBranchFilter([...item]);
  };
  const setFilterShiftType = items => {
    setShiftTypeFilter([...items]);
  };
  const setEmployeefilter = item => {
    setEmployeeFilter([...item]);
  };

  // useEffect(() => {
  //   values.shiftType = shiftType;
  //   setValues({...values});
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [shiftType]);

  useEffect(() => {
    if (values.end_date && (readPermission || readSelf || contextIsAdmin || contextIsSuperAdmin) && allowSecondTimeApiCall) {
      getEmployeeActiveData()
    }
  }, [values.end_date, readPermission, readSelf, contextIsAdmin])

  const getEmployeeActiveData = async () => {
    const date_val = moment(values.end_date).format('YYYY-MM-DD')
    const response = await getActiveEmp(date_val);
    if (response.data) {
      let employeesResources = response.data.map(employee => {
        return {
          id: employee.id,
          title: employee.full_name,
        };
      });
      if (!readPermission && readSelf && !contextIsAdmin && !contextIsSuperAdmin) {
        employeesResources = employeesResources?.filter((selfPer) => selfPer.id == filterEmployee)
      }
      setResources([...employeesResources,]);
    }
  }

 
  useEffect(() => {
    if (shiftType !== '' && allowSecondTimeApiCall) {
      searchRotaShift();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.availability,
    values.start_date,
    values.end_date,
    //shiftType,
    includeVisits,
    // contextChoicesMap,
    branchData
  ]);

  // useEffect(() => {
  //   if (shiftType !== '') {
  //     searchRotaShift();
  //   }
  // }, [
  //   //values.jobDepartment,
  //   // values.runroutes,
  //   // values.runroutesclient,
  //   // values.runroutesteam,
  //   // values.branch,
  //   //values.employees,
  //   includeVisits,
  //   branchData,
  // ]);
  // useEffect(() => {

  // }, [choices]);
  const dropDownHandleRate = item => {
    values.travel_rate = item.travel_rate;
    values.travel_rate_per_hour = TravelData.find(
      travelid => travelid.id == item.travel_rate
    ).rate_per_hour;
    setValues({ ...values });
  };

const loadPayRates = () => {
  getPayRates().then(res => {
    setTravelData(res || []);
    setPayRate(res.filter(data => data.rate_card_type == 'Pay Rates') || []);
    setTravelRate(
      res.filter(data => data.rate_card_type == 'Travel Rates') || []
    );
    setOfficeRate(
      res.filter(data => data.rate_card_type == 'Office Rates') || []
    );
    setOnCallRate(
      res.filter(data => data.rate_card_type == "On Call Rates") || []
    );
    setAbsenceRateCard(
      res.filter(data => data.rate_card_type === 'Absence Rates') || []
    )
  });
}

  useEffect(() => {
   
    if (filterAdmin || filterSuperAdmin) {
      setCreatePermissions(true);
      setDeletePermissions(true);
      setUpdatePermissions(true);
      setCreateAvailablePermissions(true);
      setCreateAssignShiftPermissions(true);
      setCreateUnAvailablePermissions(true);
      setCreateSelfAvailablePermissions(true);
      setCreateSelfUnAvailablePermissions(true);
      setCreateSelfAssignShiftPermissions(true);
      setReadPermissions(true);
      setReadSelfPermissions(true);
    } else {

      const rolesPermissions = contextPermissions;

      let rolePermissions = {};
      if (rolesPermissions.length > 0) {
        rolesPermissions.forEach(
          item => {
            // if (item?.section?.name === CLIENT_PERSONAL_DETAILS) {
            rolePermissions[item.section.name] = {};
            rolePermissions[item.section.name][WEB_PERMISSION] = {};
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] = item?.[WEB_READ_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_CREATE_PERMISSION
            ] = item?.[WEB_CREATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_DELETE_PERMISSION
            ] = item?.[WEB_DELETE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_UPDATE_PERMISSION
            ] = item?.[WEB_UPDATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][SELF_WEB_READ_PERMISSION] =
              item[SELF_WEB_READ_PERMISSION];
          }
          // }
        );
      }
      const roles = contextSubSectionPermissions;
  
      if (roles.length > 0) {
        roles.forEach(
          item => {
            if (item.section !== null) {
              // if (item?.section?.name === CLIENT_PERSONAL_DETAILS) {
              rolePermissions[item.section.name] = {};
              rolePermissions[item.section.name][WEB_PERMISSION] = {};
              rolePermissions[item.section.name][WEB_PERMISSION][
                WEB_READ_PERMISSION
              ] = item?.[WEB_READ_PERMISSION];
              rolePermissions[item.section.name][WEB_PERMISSION][
                SELF_WEB_READ_PERMISSION
              ] = item?.[SELF_WEB_READ_PERMISSION];
              rolePermissions[item.section.name][WEB_PERMISSION][
                WEB_CREATE_PERMISSION
              ] = item?.[WEB_CREATE_PERMISSION];
              rolePermissions[item.section.name][WEB_PERMISSION][
                SELF_WEB_CREATE_PERMISSION
              ] = item?.[SELF_WEB_CREATE_PERMISSION];
              rolePermissions[item.section.name][WEB_PERMISSION][
                WEB_DELETE_PERMISSION
              ] = item?.[WEB_DELETE_PERMISSION];
              rolePermissions[item.section.name][WEB_PERMISSION][
                WEB_UPDATE_PERMISSION
              ] = item?.[WEB_UPDATE_PERMISSION];
              rolePermissions[item.section.name][WEB_PERMISSION][
                SELF_WEB_UPDATE_PERMISSION
              ] = item?.[SELF_WEB_UPDATE_PERMISSION];
            }
          }
          // }
        );
      }
  
      setCreateAvailablePermissions(
        rolePermissions[ROTA_ADD_AVAILABLE]?.[WEB_PERMISSION]?.[
        WEB_CREATE_PERMISSION
        ]
      );
      setCreateAssignShiftPermissions(
        rolePermissions[ROTA_ASSIGN_SHIFT]?.[WEB_PERMISSION]?.[
        WEB_CREATE_PERMISSION
        ]
      );
      setCreateUnAvailablePermissions(
        rolePermissions[ROTA_ADD_UNAVAILABLE]?.[WEB_PERMISSION]?.[
        WEB_CREATE_PERMISSION
        ]
      );
      setCreateSelfAvailablePermissions(
        rolePermissions[ROTA_ADD_AVAILABLE]?.[WEB_PERMISSION]?.[
        SELF_WEB_CREATE_PERMISSION
        ]
      );
      setCreateSelfUnAvailablePermissions(
        rolePermissions[ROTA_ADD_UNAVAILABLE]?.[WEB_PERMISSION]?.[
        SELF_WEB_CREATE_PERMISSION
        ]
      );
      setCreateSelfAssignShiftPermissions(
        rolePermissions[ROTA_ASSIGN_SHIFT]?.[WEB_PERMISSION]?.[
        SELF_WEB_CREATE_PERMISSION
        ]
      );
      setCreatePermissions(
        rolePermissions[ROTA_SHIFTS_SETTINGS][WEB_PERMISSION][
        WEB_CREATE_PERMISSION
        ]
      );
      setDeletePermissions(
        rolePermissions[ROTA_SHIFTS_SETTINGS][WEB_PERMISSION][
        WEB_DELETE_PERMISSION
        ]
      );
      setUpdatePermissions(
        rolePermissions[ROTA_SHIFTS_SETTINGS][WEB_PERMISSION][
        WEB_UPDATE_PERMISSION
        ]
      );
      setReadPermissions(
        rolePermissions[ROTA_SHIFTS_SETTINGS][WEB_PERMISSION][
        WEB_READ_PERMISSION
        ]
      );
      setReadSelfPermissions(
        rolePermissions[ROTA_SHIFTS_SETTINGS][WEB_PERMISSION][
        SELF_WEB_READ_PERMISSION
        ]
      );

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    contextPermissions,
    filterAdmin,
    filterSuperAdmin,
    contextSubSectionPermissions,
  ]);

  useEffect(() => {
    if (values.full_day) {
    } else {
      const dateValid = validateDate(true, values.start_day, values.end_day);
      const timeValid = validateTime(
        true,
        values.start_day,
        values.end_day,
        values.start,
        values.end
      );

      if (dateValid || (!values.start_day && !values.end_day)) {
        setDateMessage('Is required');
      } else {
        setDateMessage(InvalidRange);
      }

      if (timeValid || (!values.start && !values.end)) {
        setTimeMessage('Is required');
      } else {
        setTimeMessage(InvalidRange);
      }

      if (values.start_day && values.end_day) {
        if (dateValid) {
          setDateError(false);
        } else {
          setDateError(true);
        }
      }

      if (values.start && values.end) {
        if (timeValid) {
          setTimeError(false);
        } else {
          setTimeError(true);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    errorMessage,
    values.start,
    values.end,
    values.start_day,
    values.end_day,
  ]);


  const getEmployeeVisits = () => {
    // getEmployeeVisitData(values).then(res => {
    //   setEmployeeVisitData(res.data);
    // });
  }
  useEffect(() => {
    if (allowSecondTimeApiCall) {
      getEmployeeVisits()
    }
  }, [
    values.carer,
    values.availability,
    values.start_date,
    values.end_date,
    shiftType,
    values.jobDepartment,
    // values.runroutes,
    values.runroutesclient,
    values.runroutesteam,
    values.branch,
    values.employees,
    includeVisits,
    branchData,
  ]);

  useEffect(() => {
    if (scheduleEvents) {
      let travelHoursCheck = scheduleEvents
        .filter(event => event.traveltime)
        .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

      if (travelHoursCheck.length > 0) {
        let aHours = travelHoursCheck.reduce((total, visit) => total + visit);
        aHours % 0.1 === 0
          ? setTravelHours(aHours)
          : setTravelHours(Math.round(aHours * 100) / 100);
      } else {
        setTravelHours(0);
      }
      let waitHoursCheck = scheduleEvents
        .filter(event => event.waittime)
        .map(visit => (new Date(visit.end) - new Date(visit.start)) / 3600000);

      if (waitHoursCheck.length > 0) {
        let aHours = waitHoursCheck.reduce((total, visit) => total + visit);
        aHours % 0.1 === 0
          ? setWaitHours(aHours)
          : setWaitHours(Math.round(aHours * 100) / 100);
      } else {
        setWaitHours(0);
      }
    }
  }, [scheduleEvents]);

  useEffect(() => {
    // errors
    let showErrorMessage = 0;
    if (
      values.start_day &&
      values.end_day &&
      validateDate(true, values.start_day, values.end_day)
    ) {
      showErrorMessage += 1;
    }

    if (
      !assignShiftModal &&
      values.start &&
      values.end &&
      validateTime(
        true,
        values.start_day,
        values.end_day,
        values.start,
        values.end
      )
    ) {
      showErrorMessage += 1;
    }

    if (values.employee) {
      showErrorMessage += 1;
    }

    if (assignShiftModal && values.pattern && assignedEmployees.length >= 1) {
      showErrorMessage += 1;
    }

    if (showErrorMessage === 3) {
      setErrorMessage('');
    }

    setButtonIcon(FLOPPY);
    setTextStatus(SAVE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedEmployees.length, values]);



  const loadPatterns = () => {
    getShiftPatterns({ unlimited: true }, 500).then(response => {
      if (!mountedRef.current) return null;

      let patternsToLoad = [];
      if (response.results) {
        patternsToLoad = response.results.map(pattern => {
          return {
            id: pattern.id,
            name: pattern.name,
            details: pattern.details,
          };
        });
      }

      setPatterns(patternsToLoad || []);
      // create new reference
      patternsToLoad = [...patternsToLoad];
      // assign to the start the all opt
      patternsToLoad.unshift({
        id: FILTER_NONE_ID,
        name: 'None',
      });
      patternsToLoad.unshift({ id: '', name: 'All ' });
      setPatternsCombo(patternsToLoad || []);
    });
  };

  // useEffect(() => {
  //   const employees = selectedEmployees.map(selected => {
  //     return typeof selected === 'string'
  //       ? selected
  //       : selected.employee
  //         ? selected.employee
  //         : selected.value;
  //   });
  //   setAssignedEmployees(employees);
  // }, [selectedEmployees]);

  useEffect(() => {
    loadChoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextChoices]);

  useEffect(() => {
    setIsDateAssigned(false);
  }, [values]);

  // const rotashiftColor = name => {
  //   console.log(name,"name")
  // if (name === 'Shift') {
  //   return ROTA_SHIFT;
  // } else if (name === 'Availability') {
  //   return ROTA_AVAILABLE;
  // } else if (name === 'Absence') {
  //   return ROTA_ABSENCE;
  // } else if (name === 'Unavailability') {
  //   return ROTA_UNAVAILABLE;
  // } else if (name === 'On Call') {
  //   return '#FF8C00';
  // } else if (name === 'Car - Driver - Shift') {
  //   return '#e0b16e';
  // } else if (name === 'Bank-Flexible') {
  //   return '#de5285';
  // } else {
  //   return '#e89b2e';
  // }
  // };
  const newRotashiftColor = (id) => {
    const shift = contextChoices?.regular_shift_type?.find(item => item.id === id);
    return shift?.color_code || GRAY_700;
  };


  const getNameFormChoicesMap = (name, id) => {
    if (!id) return "";
    const data = contextChoicesMap?.[name] && contextChoicesMap?.[name]?.[id];
    return data?.name || data?.full_name || "";
  }


  const searchRotaShift = async () => {
    //clear current events to prevent "double render
    if (!loading) {
      setLoading(true);
    }
    setSeconds(initialWait)
    setScheduleEvents([]);
    setRotaEvents([]);
    setTotalRotaEvents([]);
    let rota = await getRotaShifts(
      { ...branchData, ...values },
      PAGE_SIZE_RENDER
    ).then(response => {
      if (!mountedRef.current) return null;
      console.log(response, "response")
      let data = [];
      data =
        response &&
        response.map(rotaShift => {
          const shift_type_name = rotaShift.shift_type && getNameFormChoicesMap("regular_shift_type", rotaShift?.shift_type);
          const employe_name = rotaShift.employee && getNameFormChoicesMap("employee_listing", rotaShift?.employee);
          const availabilities = {
            id: rotaShift.id,
            resourceId: rotaShift.employee,
            groupId: rotaShift.id,
            typeEvent: rotaShift.is_available
              ? TYPE_AVAILABLE
              : TYPE_UNAVAILABLE,
            title:
              !rotaShift.regular_shift_name
                ? (rotaShift.is_available
                  ? rotaShift.shift_type_name
                  : (rotaShift.is_absent
                    ? 'Absence'
                    : 'Unavailable'))
                : rotaShift.regular_shift_name,
            // backgroundColor: rotashiftColor(rotaShift.shift_type_name),
            // borderColor: rotashiftColor(rotaShift.shift_type_name),
            // shift_type
            backgroundColor: rotaShift.employee_absence_type ? rotaShift.employee_absence_type_colour || GRAY_700 : newRotashiftColor(rotaShift.shift_type),
            borderColor: rotaShift.employee_absence_type ? rotaShift.employee_absence_type_colour || GRAY_700 : newRotashiftColor(rotaShift.shift_type),
            textColor: rotaShift.is_available ? GRAY_820 : WHITE,
            shift_type: rotaShift.shift_type,
            start: rotaShift.start_date,
            end: rotaShift.end_date,
            actual_shift_start_time: rotaShift.actual_shift_start_time,
            actual_shift_end_time: rotaShift.actual_shift_end_time,
            shift_status_name: rotaShift.shift_status_name,
            travelMode: rotaShift.is_available
              ? rotaShift.travel_mode_name === 'Walk'
                ? FOOT_ICON
                : rotaShift.travel_mode_name === 'Bike' ||
                  rotaShift.travel_mode_name === 'Scooter' ||
                  rotaShift.travel_mode_name === 'Motarbike'
                  ? BIKE_ICON
                  : rotaShift.travel_mode_name === 'Car'
                    ? CAR_ICON
                    : ''
              : '',
            listViewStartDate: rotaShift.start_date?.split('T')[0],
            listViewEndDate: rotaShift.end_date?.split('T')[0],
            listViewStartTime: rotaShift.start_date?.split('T')[1],
            listViewEndTime: rotaShift.end_date?.split('T')[1],
            listViewShiftName: rotaShift.regular_shift_name,
            listViewShiftType: rotaShift.shift_type_name,
            listViewTravelName: rotaShift.travel_mode_name,
            ListViewEmployeeName: employe_name,
            shift_type_name: rotaShift.shift_type_name
          };

          // const shifts = rotaShift.shifts.map(shift => {
          //   const isRegularShift = shift.pattern_id == null;
          //   const shift_pattern_name =
          //     patterns.map(pattern_value => {
          //       if (shift.pattern_id === pattern_value.id) {
          //         return pattern_value.name;
          //       }
          //     }) || '';

          //   const regular_shift_name = shift.regular_shift_name || '';

          //   // let date = new Date(shift.detail_start_date);
          //   // let start = date.setDate(date.getDate()+1);
          //   //  date = new Date(shift.detail_end_date);
          //   // let end = date.setDate(date.getDate() +1);

          //   return {
          //     id: shift.id,
          //     resourceId: rotaShift.id,
          //     groupId: shift.shift_id,
          //     typeEvent: TYPE_SHIFT,
          //     title: isRegularShift ? regular_shift_name : shift_pattern_name,
          //     //   backgroundColor: shift.shift_type_name == 'Availability'
          //     //     ? ROTA_AVAILABLE
          //     //     : shift.shift_type_name == 'On Call'
          //     //       ? '#FF8C00' :
          //     //       shift.shift_type_name == 'Bank-Flexible' ?
          //     //         '#de5285' : ROTA_SHIFT,
          //     // borderColor: shift.shift_type_name == 'Availability'
          //     //     ? ROTA_AVAILABLE
          //     //     : shift.shift_type_name == 'On Call'
          //     //       ? '#FF8C00' :
          //     //       shift.shift_type_name == 'Bank-Flexible' ?
          //     //         '#de5285' : ROTA_SHIFT,
          //     backgroundColor: rotashiftColor(shift.shift_type_name),
          //     borderColor: rotashiftColor(shift.shift_type_name),
          //     textColor: GRAY_820,
          //     start: shift.detail_start_date,
          //     shift_type: 'Shift',
          //     end: shift.detail_end_date,
          //     actual_shift_start_time: shift.actual_shift_start_time,
          //     actual_shift_end_time: shift.actual_shift_end_time,
          //     shift_status_name: shift.shift_status_name,
          //     travelMode:
          //       shift.travel_method_name === 'Walk'
          //         ? FOOT_ICON
          //         : shift.travel_method_name === 'Bike' ||
          //           shift.travel_method_name === 'Scooter' ||
          //           shift.travel_method_name === 'Motarbike'
          //         ? BIKE_ICON
          //         : shift.travel_method_name === 'Car'
          //         ? CAR_ICON
          //         : '',
          //     listViewStartDate: shift.detail_start_date.split('T')[0],
          //     listViewEndDate: shift.detail_end_date.split('T')[0],
          //     listViewStartTime: shift.detail_start_date.split('T')[1],
          //     listViewEndTime: shift.detail_end_date.split('T')[1],
          //     listViewShiftName: shift.regular_shift_name,
          //     listViewShiftType: shift.shift_type_name,
          //     listViewTravelName: shift.travel_method_name,
          //     ListViewEmployeeName: rotaShift.full_name,
          //   };
          // });

          return availabilities;
        });
      console.log(data, 753951);
      //return data.flat();
      return data;
    });

    let schedule = await getSchedulerInRotaShifts({ ...branchData, ...values }, 800).then(
      response => {
        if (!mountedRef.current) return null;
        let travel_data = [];
        let data1 = [];
        data1 = response.length ?
          response.map(scheduler => {
            const visits = scheduler.visits.map(detail => {
              return {
                id: detail.id,
                resourceId: detail.employee_id,
                groupId: detail.client_service_visit_id,
                typeEvent: detail.employee_id
                  ? TYPE_AVAILABLE
                  : TYPE_UNAVAILABLE,
                title: scheduler.full_name,
                backgroundColor: detail.is_regular_visit
                  ? '#6BB6FF'
                  : '#1E90FF',
                borderColor: detail.is_regular_visit ? '#6BB6FF' : '#1E90FF',
                textColor: detail.employee_id ? GRAY_820 : WHITE,
                start: detail.start_date,
                end: detail.end_date,
                client: scheduler.full_name,
                icon: Home_ICON,
              };
            });

            return visits;
          }) : [];
        response.data && response.data.map(scheduler => {
          // TOTAL_VISITS = TOTAL_VISITS + scheduler.visits.length;

          return scheduler.visits.map(detail => {
            if (
              detail.employee_id &&
              detail.start_travel_time !== detail.end_travel_time
            ) {
              travel_data.push({
                id: detail.id,
                resourceId: detail.employee_id,
                typeEvent: detail.employee_id
                  ? TYPE_AVAILABLE
                  : TYPE_UNAVAILABLE,
                unassigned: detail.employee_id ? false : true,
                title: scheduler.full_name,
                backgroundColor: TRAVEL_TIME_COLOR,
                borderColor: TRAVEL_TIME_COLOR,
                textColor: GRAY_820,
                start: detail.start_travel_time,
                end: detail.end_travel_time,
                client: scheduler.full_name,
                clientId: scheduler.id,
                employeeId: detail.employee_id,
                serviceId: detail.client_service_visit_id,
                openModal: true,
                traveltime: true,
                travelMode:
                  detail.travel_mode === 'Walk'
                    ? FOOT_ICON
                    : detail.travel_mode === 'Scooter' ||
                      detail.travel_mode === 'Motorbike' ||
                      detail.travel_mode === 'Bike'
                      ? BIKE_ICON
                      : CAR_ICON,
              });
            }
          });
        });
        let final_data = [...data1, travel_data];
        return final_data.flat();
      }
    );
    // let final_wait_data = 
    // await getWaitTime(
    //   { ...branchData, ...values },
    //   800
    // ).then(response => {
    //   let wait_arr = [];
    //   response &&
    //     Array.isArray(response) &&
    //     response.map(wait_data => {
    //       wait_data.waitTime &&
    //         wait_data.waitTime.map(event => {
    //           let duration_arr = event.duration && event.duration.split(':');
    //           let duration_str =
    //             duration_arr && duration_arr[0] + ':' + duration_arr[1];
    //           wait_arr.push({
    //             resourceId: wait_data.employeeId,
    //             backgroundColor: event.isBreak
    //               ? BREAK_WAIT_COLOR
    //               : WAIT_TIME_COLOR,
    //             borderColor: event.isBreak ? BREAK_WAIT_COLOR : WAIT_TIME_COLOR,
    //             title:
    //               duration_str +
    //               `(${event.startPostCode}-${event.endPostCode})`,
    //             textColor: wait_data.employeeId ? GRAY_820 : WHITE,
    //             start: event.startDate,
    //             end: event.endDate,
    //             openModal: true,
    //             waittime: true,
    //           });
    //         });
    //     });
    //   return wait_arr.flat();
    // });
    schedule = schedule && [...schedule,];
    //calculation of hourly totals at top of rotashift page

    let availableCheck =
      rota &&
      rota
        .filter(visit => visit.typeEvent === TYPE_AVAILABLE)
        .map(visit => {
          if (new Date(visit.end) > new Date(visit.start))
            return (new Date(visit.end) - new Date(visit.start)) / 3600000;
          else return (new Date(visit.start) - new Date(visit.end)) / 3600000;
        });

    if (availableCheck?.length > 0) {
      let available = availableCheck.reduce((total, visit) => total + visit);
      available % 0.1 === 0
        ? setAvailableHours(available)
        : setAvailableHours(Math.round(available * 100) / 100);
    } else {
      setAvailableHours(0);
    }

    let unavailableCheck = rota && rota
      .filter(visit => visit.typeEvent === TYPE_UNAVAILABLE)
      .map(visit => {
        if (new Date(visit?.end) > new Date(visit.start))
          return (new Date(visit.end) - new Date(visit.start)) / 3600000;
        else return (new Date(visit.start) - new Date(visit.end)) / 3600000;
      });

    if (unavailableCheck?.length > 0) {
      let unavailable = unavailableCheck.reduce(
        (total, visit) => total + visit
      );
      unavailable % 0.1 === 0
        ? setUnavailableHours(unavailable)
        : setUnavailableHours(Math.round(unavailable * 100) / 100);
    } else {
      setUnavailableHours(0);
    }

    // let shiftCheck = rota?.filter(visit => visit.typeEvent === TYPE_SHIFT)
    let shiftCheck = rota?.filter(visit => visit.shift_type_name === 'Shift')
      .map(visit => {
        if (new Date(visit.end) > new Date(visit.start))
          return (new Date(visit.end) - new Date(visit.start)) / 3600000;
        else return (new Date(visit.start) - new Date(visit.end)) / 3600000;
      });

    if (shiftCheck?.length > 0) {
      let shift = shiftCheck.reduce((total, visit) => total + visit);
      shift % 0.1 === 0
        ? setShiftHours(shift)
        : setShiftHours(Math.round(shift * 100) / 100);
    } else {
      setShiftHours(0);
    }

    if (schedule?.length > 0) {
      let visitCalc = schedule
        .filter(event => !(event.traveltime || event.waittime))
        .map(visit => {
          if (new Date(visit.end) > new Date(visit.start))
            return (new Date(visit.end) - new Date(visit.start)) / 3600000;
          else return (new Date(visit.start) - new Date(visit.end)) / 3600000;
        })

        //  added below 0 in reduce  to fix bug  Reduce of empty array with no initial value
        .reduce((total, visit) => total + visit, 0);

      visitCalc % 0.1 === 0
        ? setVisitHours(visitCalc)
        : setVisitHours(Math.round(visitCalc * 100) / 100);
    } else {
      setVisitHours(0);
    }
    setCheckedRows([]);
    setLoading(false);
    setRotaEvents(rota);
    setTotalRotaEvents(rota);
    setScheduleEvents(schedule);
    getEmployeeVisits()

  };

  const dropDownHandleSelect = (item, name) => {
    // if(item.runroutes){
    //   item.runroutes=item.runroutes.length===(runRouteData?runRouteData:0).length||item.runroutes.length===0?[]:item.runroutes
    // }
    if (item.branch_id) {
      values.branch_id = item.branch_id
    }
    if (item.jobDepartment) {
      item.jobDepartment =
        item.jobDepartment.length ===
          (jobDepartment ? jobDepartment : 0).length ||
          item.jobDepartment.length === 0
          ? []
          : item.jobDepartment;
    }

    if (item.branch) {
      item.branch =
        item.branch.length === (branch ? branch : 0).length ||
          item.branch.length === 0
          ? []
          : item.branch;
    }

    if (item.employees) {
      item.employees =
        item.employees.length === (employees ? employees : 0).length ||
          item.employees.length === 0
          ? []
          : item.employees;
    }

    const always_disable = ['Availability', 'Unavailability', 'Bank-Flexible', 'Absence', 'Car - Driver - Shift'].some((item) => item === shiftTempOnPopup);

    if (item.shift_type && !always_disable && values.idSelected) {
      const result = ['Availability', 'Unavailability', 'Bank-Flexible', 'Absence', 'Car - Driver - Shift'].some((item) => item === name);
      if (result) {
        setEmployeeDisable(true)
        values.employee = employeeTempOnPopup;
      } else {
        setEmployeeDisable(false)
      }
    }

    if (name === 'pay_pype') {
      values.pay_type = item.id
    }

    if (item.pay_rate_card) {
      const match = absenceRateCard.find(el => el.id == item.pay_rate_card)
      values.pay_rate_card = item.pay_rate_card;
      values.pay_rate_per_hour = match?.rate_per_hour

    }
    if (name == "employee")
      values.branch_id = null;

    setValues({ ...values, ...item });
  };

  // const dropDownHandleShiftFilter = item => {
  //   let tempEvents = _.cloneDeep(totalRotaEvents);
  //   if (item.shiftType.length == 0) {
  //     setRotaEvents(tempEvents);
  //   } else {
  //     let filteredEvents = [];
  //     item.shiftType.forEach(event => {
  //       let innerFilteredEvents = tempEvents.filter(
  //         ev => ev.shift_type == event.name
  //       );
  //       filteredEvents = [...filteredEvents, ...innerFilteredEvents];
  //     });
  //     setRotaEvents(filteredEvents);
  //   }
  // };
  const handleDropAbsenceType = item => {
    if (item.absence_type || item.absence_type == null) {
      values.absence_reason = null;
      values.sickness_reason = null;
    }
    setValues({ ...values, ...item });
  };

  const employeesHandleSelect = (preferred, name) => {
    if (name == "employee")
      values.branch_id = null;
    setValues({ ...values, ...preferred });
    setSelectedEmployees(preferred.employee);
  };

  const resetValues = () => {
    values.branch_id = ""
    values.idSelected = '';
    values.shiftAvailabilityId = '';
    values.availabilitiesDetails = '';
    values.shiftId = '';
    values.shiftDetails = '';
    values.employee = '';
    values.start_day = '';
    values.end_day = '';
    values.name = '';
    values.start = '';
    values.shift_type = '';
    values.travel_rate = '';
    values.regular_shift_pay_rate_id = '';
    values.travel_time = null;
    values.comment = '';
    values.end = '';
    values.pattern = '';
    values.full_day = false;
    values.travel_method = '';
    values.travel_mode = '';
    values.is_overnight = false;
    values.absence_informed_method = '';
    values.absence_paid = '';
    values.absence_planned = '';
    values.absence_reason = '';
    values.absence_type = '';
    values.absence_notes = '';
    values.sickness_reason = '';
    values.pay_rate_card = null;
    values.pay_amount = null;
    values.pay_rate_per_hour = null;
    values.absence_hours_to_paid = null
    // values.start_date = '';
    // values.end_date = '';
    values.is_absent = false;
    values.remote_work = false
    setDaysOfWeek([
      { day: 'MON', dayValue: 1, selected: false },
      { day: 'TUE', dayValue: 2, selected: false },
      { day: 'WED', dayValue: 3, selected: false },
      { day: 'THU', dayValue: 4, selected: false },
      { day: 'FRI', dayValue: 5, selected: false },
      { day: 'SAT', dayValue: 6, selected: false },
      { day: 'SUN', dayValue: 7, selected: false },
    ]);
    setValues({...values})
    setErrorMessage('');
    setCheckRequires(false);
    setDateError(false);
    setTimeError(false);
    setLoadingSave(false);
    setAssignedEmployees([]);
    setSelectedEmployees([]);
    setIsDateAssigned(false);
    setDisableButton([]);
    setInvalidOvernight(false);
    const lengthError = errors.length;
    errors.splice(0, lengthError);
  };

  const handleAssignShiftModal = () => {
    resetValues();
    setTextStatus(SAVE);
    setButtonIcon(FLOPPY);
    setAssignShiftModal(!assignShiftModal);
    setDisplay(true);
  };

  const handleBulkEditModal = () => {
    resetValues();
    setTextStatus(SAVE);
    setButtonIcon(FLOPPY);
    setBulkEditModal(!bulkEditModal);
  };

  const handleBulkDeleteModal = () => {
    setModalDeleteValue(true);
  };

  const handleDeleteTheShift = () => {
    const idList = selectedElId ? [{ id: selectedElId }] : checkedRows;
    let rows = idList.map(item => item.id);
    let body = {
      employee_shift: [...rows],
    };
    deleteShiftBulk(body)
      .then(response => {
        if (response.status) {
          setModalDeleteValue(false);
          searchRotaShift();
          clearSelectedElId();
        }
      })
      .catch(err => { });
  };

  const handleAddAvailability = () => {
    resetValues();
    setTextStatus(SAVE);
    setButtonIcon(FLOPPY);
    setAddAvailability(!addAvailability);
  };

  const handleAddUnavailability = () => {
    resetValues();
    setTextStatus(SAVE);
    setButtonIcon(FLOPPY);
    setAddUnavailability(!addUnavailability);
  };

  const addShift = async (
    force_submit,
    split_submit,
    employee_id,
    index,
    start_date,
    end_date,
    item
  ) => {
    let forceUpdate = { force: false };
    let finalSelectedEmp = [];
    if (force_submit) {
      forceUpdate = { force: true };
    }

    if (employee_id) {
      finalSelectedEmp[0] = employee_id;
      setLoadingProceed([...loadingProceed, index + 1]);
    } else {
      if (!createAssignShift && createSelfAssignShift) {
        finalSelectedEmp[0] = filterEmployee;
      } else {
        finalSelectedEmp = [...assignedEmployees];
      }
    }

    if (
      errors.length > 0 ||
      finalSelectedEmp.length <= 0 ||
      !values.pattern ||
      (!values.start_day && !values.end_day) ||
      !validateDate(true, values.start_day, values.end_day)
    ) {
      setCheckRequires(true);
      setErrorMessage(InvalidOrEmptyValues);
      return;
    }

    setLoadingSave(true);

    // const start_date = moment(values.start_day).format('YYYY-MM-DD')
    // const end_date = moment(values.end_day).format('YYYY-MM-DD')



    const body = {
      ...forceUpdate,
      start_date: start_date ? start_date : values.start_day,
      end_date: end_date ? end_date : values.end_day,
      employees: finalSelectedEmp,
      pattern: values.pattern,
      travel_mode: values.travel_mode && values.travel_mode,
      travel_rate: values.travel_rate && values.travel_rate,
      is_split: split_submit || false,
      comment: values.comment
    };


    await setShift(body)
      .then(result => {
        if (result.message) {
          setIsDateAssigned(true);
          assignMessageShift(result);
          //setCurrentModal(available);
          setOverlapMessage(result.message.message);
          setOverlapData(result.message.shift_availabilities_dates);
          // if(result.message === "Please enter valid branch"){
          //   toast.current.show({
          //     severity: 'error',
          //     summary: 'Failed',
          //     detail: `${response?.message}`,
          //   });
          // }
          setOverlapModalShift(!overlapModalShift);
          setTextStatus(SAVE);
          setLoadingSave(false);
          //assignMessageAvailableUnavailable(true, result);
          //throw new Error();
        } else {
          // toast.current.show({
          //   life: 5000,
          //   severity: 'success',
          //   summary: 'Success',
          //   detail: 'Instant notification send successfully',
          // });
          setButtonIcon('');
          setButtonIcon(CHECK);
          setTextStatus(SAVED);
          setLoadingSave(false);
          let loadingArr = loadingOverlap.filter(ele => ele !== index + 1);
          setLoadingProceed([...loadingArr]);
          setDisableButton([...disableButton, index + 1]);
          //searchRotaShift();
        }
      })
      .catch(error => {
        setButtonIcon('');
        setButtonIcon(CROSS);
        setTextStatus(ERROR);
        setLoadingSave(false);
      });
  };

  const assignMessageShift = result => {
    if (result && result.shift_dates) {
      setTypeOfMessage(SHIFT_EXISTING_SHIFT);
    } else if (result && result.shift_availabilities_dates) {
      let find_availability = null;
      if (result && result.shift_availabilities_dates) {
        find_availability = result.shift_availabilities_dates.find(
          result => result.is_available === 'True'
        );
      }

      if (find_availability) {
        setTypeOfMessage(SHIFT_EXISTING_AVAILABLE);
      } else {
        setTypeOfMessage(SHIFT_EXISTING_UNAVAILABLE);
      }
    }
  };

  const assignMessageAvailableUnavailable = (available, result) => {
    if (result && result[0].shift_dates) {
      if (available) {
        setTypeOfMessage(AVAILABLE_EXISTING_SHIFT);
      } else {
        setTypeOfMessage(UNAVAILABLE_EXISTING_SHIFT);
      }
    } else if (result && result[0].shift_availabilities_dates) {
      let find_availability = null;
      if (result && result[0].shift_availabilities_dates) {
        find_availability = result[0].shift_availabilities_dates.find(
          result => result.is_available === 'True'
        );
      }

      if (find_availability) {
        setErrorTime({
          start: moment(
            result[0].shift_availabilities_dates[0].start_date
          ).format('LT'),
          end: moment(result[0].shift_availabilities_dates[0].end_date).format(
            'LT'
          ),
        });
        setTypeOfMessage(UNAVAILABLE_EXISTING_AVAILABLE);
      } else {
        setTypeOfMessage(AVAILABLE_EXISTING_UNAVAILABLE);
      }
    }
  };

  const editAvailabilityBulk = async (
    available,
    force_submit,
    employee_id,
    index,
    start_date,
    end_date,
    item
  ) => {
    //     const addNewAvailability = async (available, force_submit, item,index)
    let detail_tem_id = [];

    const idList = selectedElId ? [{ id: selectedElId }] : checkedRows;
    idList.forEach(item => {
      detail_tem_id.push(item.id);
    });
    const availabilityValues = [];
    if (values.full_day) {
      // values.end_date = values.start_date;
      if (!values.end_day) values.end_day = values.start_day;
      values.start = '00:00';
      values.end = '23:59:59';
    } //else {
    // if (filterEmployee !== 'null' && userStaff !== 'true') {
    //   values.employee = filterEmployee
    // }
    // let errorValue = values.full_day ? false : errors.length > 0;
    // if (errorValue || (!values.start && !values.end) || invalidOvernight) {
    //   setCheckRequires(!values.full_day);
    //   setErrorMessage(InvalidOrEmptyValues);
    //   return;
    // }
    if (employee_id) {
      setLoadingProceed([...loadingProceed, index + 1]);
    }
    setLoadingSave(true);
    setErrorMessage('');
    let forceUpdate = { force: false };

    if (force_submit) {
      forceUpdate = { force: true };
    }
    let selectedData = {};
    if (values.idSelected) {
      selectedData = {
        detail_id: values.idSelected,
        split: false,
        edit: true,
      };
    }

    let firstDayValue = employee_id
      ? start_date.split(' ')[0]
      : values.start_day;
    //  its old coode   modified is below
    // let firstDayValue = employee_id ? start_date[0].split(" ")[0] : values.start_day;
    let weeks = [];
    let day_information = [];

    let no_of_week = moment(firstDayValue).isoWeek();
    weeks.push(no_of_week);

    let endDayValue = employee_id ? end_date.split(' ')[0] : values.end_day;
    // let endDayValue = employee_id ? end_date [0].split(" ")[0]  : values.end_day
    while (moment(firstDayValue).isSameOrBefore(endDayValue)) {
      const searchWeek = weeks.indexOf(moment(firstDayValue).isoWeek());

      if (searchWeek <= -1) {
        weeks.push(moment(firstDayValue).isoWeek());
      }

      const beginningTime = moment(values.start, 'h:mma');
      const endTime = moment(values.end, 'h:mma');
      // const crossDays =
      //   firstDayValue !== endDayValue &&
      //   endTime.isSameOrBefore(beginningTime);

      // const start_date_format = createDateWithTime(
      //   firstDayValue,
      //   values.start + ':00'
      // );
      const start_date_format = firstDayValue + ' ' + values.start + ':00';
      // const end_date_format = createDateWithTime(
      //   firstDayValue,
      //   values.end + ':00'
      // );
      const end_date_format =
        firstDayValue +
        ' ' +
        (values.full_day ? values.end : values.end + ':00');
      //console.log(end_date_format,5548889)
      day_information.push({
        week_no: moment(firstDayValue).isoWeek(),
        details: {
          is_available: available,
          start_date: start_date_format,
          end_date: end_date_format,
        },
      });

      firstDayValue = moment(firstDayValue).add(1, 'days').format('YYYY-MM-DD');
    }

    weeks.forEach(week => {
      const my_days = [];

      day_information.forEach(day_info => {
        if (day_info.week_no === week) {
          delete day_info.week_no;
          my_days.push({ ...day_info.details });
        }
      });

      //employees to pickup it vlue,newly added
      const empArray = [];
      if (employee_id) {
        empArray[0] = employee_id;
      } else {
        if (values.employee && values.employee.value) {
          empArray.push(values.employee.value);
        } else {
          values.employee &&
            values.employee.forEach(e => {
              empArray.push(e.value);
            });
        }
      }

      available
        ? availabilityValues.push({
          ...forceUpdate,
          employee_shift: detail_tem_id,
          start_time: values.start ? moment(values.start, 'HH:mm').format('HH:mm:ss') : '',
          end_time: values.end ? moment(values.end, 'HH:mm').format('HH:mm:ss') : '',
          travel_mode: values.travel_method ? values.travel_method : null,
          travel_time: values.travel_time ? values.travel_time : null,
          shift_availability_pay_rate: values.regular_shift_pay_rate_id,
          shift_bill_rate: values.travel_rate,
          shift_type: values.shift_type,
          is_overnight: values.is_overnight,
        })
        : availabilityValues.push({
          ...forceUpdate,
          employee_shift: detail_tem_id,
          start_time: values.start ? moment(values.start, 'HH:mm').format('HH:mm:ss') : '',
          end_time: values.end ? moment(values.end, 'HH:mm').format('HH:mm:ss') : '',
          travel_mode: values.travel_method ? values.travel_method : null,
          is_overnight: values.is_overnight,
          is_available: available,
          is_absent: values.is_absent ? values.is_absent : false,
          absence_informed_method: values.absence_informed_method
            ? values.absence_informed_method
            : null,
          absence_paid: values.absence_paid ? values.absence_paid : null,
          absence_planned: values.absence_planned
            ? values.absence_planned
            : null,
          absence_reason: values.absence_reason
            ? values.absence_reason
            : null,
          absence_type: values.absence_type ? values.absence_type : null,
          absence_notes: values.absence_notes ? values.absence_notes : null,
          sickness_reason: values.sickness_reason
            ? values.sickness_reason
            : null,
        });
    });

    Object.keys(availabilityValues[0]).forEach(key => {
      if (!availabilityValues[0][key] || (availabilityValues[0][key] == '')) {
        delete availabilityValues[0][key];
      }
    });

    const saveAvailability = () => {
      return async function (availabilities) {
        //availabilities in array has to be done
        let response = await setEditBulk(availabilities);
        if (response.status == 'Warning') {
          setLoadingSave(false);
          setErrorMessage(response.message);
        } else {
          return response;
        }
      };
    };

    const saveAvailabilitiesDetails = function (availability_Id) {
      return async function (details) {
        return await setAvailabilityDetails(details, availability_Id);
      };
    };

    let savedAvailabilities = [];

    let removeDuplicateAvailablity = [availabilityValues[0]];
    const resultAvailabilities = await Promise.all(
      removeDuplicateAvailablity.map(saveAvailability())
    )

      .then(result => {
        if (result[0].message) {
          if (result[0].message === 'Data successfully updated') {
            setButtonIcon('');
            setButtonIcon(CHECK);
            setTextStatus(SAVED);
            setLoadingSave(false);
            let loadingArr = loadingOverlap.filter(ele => ele !== index + 1);
            setLoadingProceed([...loadingArr]);
            setDisableButton([...disableButton, index + 1]);
            return result;
          } else {
            setIsDateAssigned(true);
            setCurrentModal(available);
            setOverlapMessage(result[0]?.message?.message);
            if (available) {
              setAddAvailability(false);
            } else {
              setAddUnavailability(false);
            }
            setOverlapData(result[0]?.message?.shift_availabilities_dates);
            // setOverlapData(result[0].shift_availabilities_dates)
            setOverlapModal(!overlapModal);
            assignMessageAvailableUnavailable(available, result);
            throw new Error();
          }
        } else {
          setButtonIcon('');
          setButtonIcon(CHECK);
          setTextStatus(SAVED);
          setLoadingSave(false);
          let loadingArr = loadingOverlap.filter(ele => ele !== index + 1);
          setLoadingProceed([...loadingArr]);
          setDisableButton([...disableButton, index + 1]);
          return result;
        }
      })
      .catch(error => {
        setButtonIcon('');
        setButtonIcon(CROSS);
        setTextStatus(ERROR);
        setLoadingSave(false);
        let loadingArr = loadingOverlap.filter(ele => ele !== index + 1);
        setLoadingProceed([...loadingArr]);
        return [];
      });

    if (!resultAvailabilities) {
      return;
    }

    savedAvailabilities.push(...resultAvailabilities);
    // savedAvailabilities.forEach((availability, index_availability) => {

    //   Promise.all(
    //     availabilityValues[index_availability].details.map(
    //       saveAvailabilitiesDetails(availability.id)
    //     )
    //   );
    // });

    setLoadingSave(false);
    searchRotaShift();
    // }
  };

  const addNewAvailability = async (
    available,
    force_submit,
    split_submit,
    employee_id,
    index,
    start_date,
    end_date,
    item,
    force_unassigned_visit
  ) => {

    setHandleDeleteClicked(false)
    //     const addNewAvailability = async (available, force_submit, item,index)
    let newData = {};
    let responseData = {};
    if (values.shift_type === 'new_shift') {
      responseData = await getChoices('regular_shift_type').then(response => {
        newData = response.regular_shift_type.find(items => {
          if (items.name == values.name) {
            return items;
          }
        });
        setContextChoices({ ...contextChoices, ...response });
        return newData;
      });
      getChoicesMap('regular_shift_type')
        .then(res => {
          setContextChoicesMap({ ...contextChoicesMap, ...res })
        })
    }

    let detail_tem_id = [];
    if (force_submit) {
      item.forEach(val => {
        detail_tem_id.push(val.detail_id);
      });
    }
    let detail_ids = {};
    if (detail_tem_id.length > 0) {
      detail_ids = { detail_id: detail_tem_id };
    }
    const availabilityValues = [];
    if (values.full_day) {
      // values.end_date = values.start_date;
      if (!values.end_day) values.end_day = values.start_day;
      values.start = '00:00';
      values.end = '23:59:59';
    } //else {
    // if (filterEmployee !== 'null' && userStaff !== 'true') {
    //   values.employee = filterEmployee
    // }
    let errorValue = values.full_day ? false : errors.length > 0;
    if (errorValue ||
      !values.employee ||
      (!values.start_day && !values.end_day) ||
      (!values.start && !values.end) ||
      !validateDate(!values.full_day, values.start_day, values.end_day) ||
      invalidOvernight
    ) {
      setCheckRequires(!values.full_day);
      setErrorMessage(InvalidOrEmptyValues);
      return;
    }
    if (employee_id) {
      setLoadingProceed([...loadingProceed, index + 1]);
    }
    setLoadingSave(true);
    setErrorMessage('');
    let forceUpdate = { force: false };

    if (force_submit) {
      forceUpdate = { force: true };
    }
    // let selectedData = {};
    // if (values.idSelected) {
    //   selectedData = {
    //     detail_id: values.idSelected,
    //     split: false,
    //     edit: true,
    //   };
    // }

    let firstDayValue = employee_id
      ? start_date.split(' ')[0]
      : values.start_day;
    //  its old coode   modified is below
    // let firstDayValue = employee_id ? start_date[0].split(" ")[0] : values.start_day;
    let weeks = [];
    let day_information = [];

    let no_of_week = moment(firstDayValue).isoWeek();
    weeks.push(no_of_week);

    let endDayValue = employee_id ? end_date.split(' ')[0] : values.end_day;
    // let endDayValue = employee_id ? end_date [0].split(" ")[0]  : values.end_day
    while (moment(firstDayValue).isSameOrBefore(endDayValue)) {
      const searchWeek = weeks.indexOf(moment(firstDayValue).isoWeek());

      if (searchWeek <= -1) {
        weeks.push(moment(firstDayValue).isoWeek());
      }

      const beginningTime = moment(values.start, 'h:mma');
      const endTime = moment(values.end, 'h:mma');
      // const crossDays =
      //   firstDayValue !== endDayValue &&
      //   endTime.isSameOrBefore(beginningTime);

      // const start_date_format = createDateWithTime(
      //   firstDayValue,
      //   values.start + ':00'
      // );
      const start_date_format = firstDayValue + ' ' + values.start + ':00';
      // const end_date_format = createDateWithTime(
      //   firstDayValue,
      //   values.end + ':00'
      // );
      const end_date_format =
        firstDayValue +
        ' ' +
        (values.full_day ? values.end : values.end + ':00');
      //console.log(end_date_format,5548889)
      day_information.push({
        week_no: moment(firstDayValue).isoWeek(),
        details: {
          is_available: available,
          start_date: start_date_format,
          end_date: end_date_format,
        },
      });

      firstDayValue = moment(firstDayValue).add(1, 'days').format('YYYY-MM-DD');
    }

    weeks.forEach(week => {
      const my_days = [];

      let a = day_information?.forEach(day_info => {
        if (day_info.week_no === week) {
          delete day_info.week_no;
          my_days.push({ ...day_info.details });
        }
      });

      // const data = [...employee]
      // const employeeId = data.map(id => data.id);

      // days of week newly added below
      const weeks = [...daysOfWeek];
      const weekDays = weeks.filter(weeks => weeks.selected === true);
      const weekArray = [];
      weekDays.forEach(w => {
        weekArray.push(w.day);
      });
      const week_of_day = weekArray.join();

      //employees to pickup it vlue,newly added
      const empArray = [];
      if (employee_id) {
        empArray[0] = employee_id;
      } else {
        if (Array.isArray(values.employee)) {
          values.employee.forEach(e => {
            empArray.push(e.value);
          });
        } else {
          empArray.push(values.employee);
        }
      }
      // } else if (filterEmployee !== 'null' && userStaff !== 'true') {
      //   empArray[0] = values.employee
      let unavailable_id = ''

      if (!available) {
        unavailable_id = values.is_absent ? choices.regular_shift_type.find(val => val.name == 'Absence').id : choices.regular_shift_type.find(val => val.name == 'Unavailability').id
      }


      const payType = values.shift_type && choices?.regular_shift_type && choices?.regular_shift_type?.find(item => item.id === values.shift_type)
      // const isShiftTypeOffice = (payType && payType?.pay_type_name === "Office")

      if (values.idSelected) {
        available
          ? availabilityValues.push({
            ...forceUpdate,
            id: values.idSelected,
            employee: empArray[0],
            branch: values?.branch_id,
            week_of_day: week_of_day ? week_of_day : null,
            // ...detail_ids,
            start_date: (employee_id ? start_date.split(' ')[0] : values.start_day) + ' ' + values.start + ':00',
            end_date: (employee_id ? end_date.split(' ')[0] : values.end_day) + ' ' + (values.full_day ? values.end : values.end + ':00'),
            travel_mode: values.travel_method ? values.travel_method : null,
            travel_time: values.travel_time ? values.travel_time : null,
            pay_rate_card: values.regular_shift_pay_rate_id ? values.regular_shift_pay_rate_id : null,
            shift_bill_rate: values.travel_rate ? values.travel_rate : null,
            shift_type:
              values.shift_type === 'new_shift'
                ? responseData.id
                : (values.shift_type ? values.shift_type : null),
            comment: values.comment ? values.comment : null,
            is_overnight: values.is_overnight,
            is_split: split_submit || false,
            force_unassigned_visit: force_unassigned_visit,
            is_available: true,
            remote_work:  values.remote_work || false
            // details: [...my_days],
            // ...selectedData,
          })
          : availabilityValues.push({
            ...forceUpdate,
            id: values.idSelected,
            employee: values.idSelected ? values.employee : empArray,
            branch: values.branch_id,
            avg_52_weeks_hours: values.avg_52_weeks_hours,
            absence_hours_to_paid: values.absence_hours_to_paid,
            branch_specific: values.branch_specific,
            week_of_day: week_of_day,
            // ...detail_ids,
            start_date: (employee_id ? start_date.split(' ')[0] : values.start_day) + ' ' + values.start + ':00',
            end_date: (employee_id ? end_date.split(' ')[0] : values.end_day) + ' ' + (values.full_day ? values.end : values.end + ':00'),
            travel_mode: values.travel_method ? values.travel_method : null,
            travel_time: values.travel_time ? values.travel_time : null,
            pay_rate_card: values.pay_rate_card,
            shift_bill_rate: values.travel_rate,
            shift_type: unavailable_id,
            comment: values.comment,
            is_overnight: values.is_overnight,
            is_split: split_submit || false,
            force_unassigned_visit: force_unassigned_visit,
            is_available: false,
            is_absent: values.is_absent ? values.is_absent : false,
            absence_informed_method: values.absence_informed_method
              ? values.absence_informed_method
              : null,
            absence_paid: values.absence_paid ? values.absence_paid : null,
            absence_planned: values.absence_planned
              ? values.absence_planned
              : null,
            absence_reason: values.absence_reason
              ? values.absence_reason
              : null,
            absence_type: values.absence_type ? values.absence_type : null,
            absence_notes: values.absence_notes ? values.absence_notes : null,
            sickness_reason: values.sickness_reason
              ? values.sickness_reason
              : null,
            //  details: [...my_days],
          });
      } else {
        available
          ? availabilityValues.push({
            ...forceUpdate,
            employee: empArray,
            week_of_day: week_of_day ? week_of_day : null,
            // ...detail_ids,
            branch: values?.branch_id,
            start_date: (employee_id ? start_date.split(' ')[0] : values.start_day) + ' ' + values.start + ':00',
            end_date: (employee_id ? end_date.split(' ')[0] : values.end_day) + ' ' + (values.full_day ? values.end : values.end + ':00'),
            travel_mode: values.travel_method ? values.travel_method : null,
            travel_time: values.travel_time ? values.travel_time : null,
            pay_rate_card: values.regular_shift_pay_rate_id ? values.regular_shift_pay_rate_id : null,
            shift_bill_rate: values.travel_rate ? values.travel_rate : null,
            shift_type:
              values.shift_type === 'new_shift'
                ? responseData?.id
                : (values.shift_type ? values.shift_type : null),
            comment: values.comment ? values.comment : null,
            is_overnight: values.is_overnight,
            is_split: split_submit || false,
            force_unassigned_visit: force_unassigned_visit,
            is_available: true,
            remote_work: values.remote_work || false
            // details: [...my_days],
            // ...selectedData,
          })
          : availabilityValues.push({
            ...forceUpdate,
            employee: values.idSelected ? values.employee : empArray,
            week_of_day: week_of_day,
            branch: values.branch_id,
            avg_52_weeks_hours: values.avg_52_weeks_hours,
            absence_hours_to_paid: values.absence_hours_to_paid,
            branch_specific: values.branch_specific,
            // ...detail_ids,
            start_date: (employee_id ? start_date.split(' ')[0] : values.start_day) + ' ' + values.start + ':00',
            end_date: (employee_id ? end_date.split(' ')[0] : values.end_day) + ' ' + (values.full_day ? values.end : values.end + ':00'),
            travel_mode: values.travel_method ? values.travel_method : null,
            travel_time: values.travel_time ? values.travel_time : null,
            // pay_rate_card: values.regular_shift_pay_rate_id,
            shift_bill_rate: values.travel_rate,
            shift_type: unavailable_id,
            comment: values.comment,
            is_overnight: values.is_overnight,
            is_split: split_submit || false,
            force_unassigned_visit: force_unassigned_visit,
            is_available: false,
            is_absent: values.is_absent ? values.is_absent : false,
            absence_informed_method: values.absence_informed_method
              ? values.absence_informed_method
              : null,
            absence_paid: values.absence_paid ? values.absence_paid : null,
            absence_planned: values.absence_planned
              ? values.absence_planned
              : null,
            absence_reason: values.absence_reason
              ? values.absence_reason
              : null,
            absence_type: values.absence_type ? values.absence_type : null,
            absence_notes: values.absence_notes ? values.absence_notes : null,
            sickness_reason: values.sickness_reason
              ? values.sickness_reason
              : null,
            //  details: [...my_days],
            pay_rate_card: values.pay_rate_card || null
          });
      }
    });

    const saveAvailability = () => {
      return async function (availabilities) {
        //availabilities in array has to be done
        let response = await setAvailability(availabilities);
        if (response.status == 'Warning' || response.message == "Please enter valid branch") {
          setLoadingSave(false);
          setErrorMessage(response.message);
        } else {
          return response;
        }
      };
    };

    const saveAvailabilitiesDetails = function (availability_Id) {
      return async function (details) {
        return await setAvailabilityDetails(details, availability_Id);
      };
    };

    let savedAvailabilities = [];

    let removeDuplicateAvailablity = [availabilityValues[0]];
    const resultAvailabilities = await Promise.all(
      removeDuplicateAvailablity.map(saveAvailability())
    )

      .then(result => {
        if (result[0].message) {
          if ((result[0].message === 'Employee Shift has been created') || (result[0].message === 'Employee Shift Successfully created') || (result[0].message === 'Employee Shift Successfully updated')) {

            setButtonIcon('');
            setButtonIcon(CHECK);
            setTextStatus(SAVED);
            if (available) {
              setAddAvailability(false);
            } else {
              setAddUnavailability(false);
            }
            setLoadingSave(false);
            let loadingArr = loadingOverlap.filter(ele => ele !== index + 1);
            setLoadingProceed([...loadingArr]);
            setDisableButton([...disableButton, index + 1]);
            if (result[0].unassigned_visit_data && (result[0].unassigned_visit_data.length > 0)) {
              setModalConfirmUnassignVisits(true)
              setUnassignedData(result[0].unassigned_visit_data)
            }
            return result;
          } else {
            setIsDateAssigned(true);
            setCurrentModal(available);
            setOverlapMessage(result[0].message.message);
            if (available) {
              setAddAvailability(false);
            } else {
              setAddUnavailability(false);
            }
            setOverlapData(result[0].message.shift_availabilities_dates && result[0].message.shift_availabilities_dates.map((val) => ({ ...val, force_unassigned_visit: false })));
            setUnassignedVisitList(result[0].unassigned_visit_list)
            // setOverlapData(result[0].shift_availabilities_dates)
            setOverlapModal(!overlapModal);
            assignMessageAvailableUnavailable(available, result);
            throw new Error();
          }
        } else {

          setButtonIcon('');
          setButtonIcon(CHECK);
          setTextStatus(SAVED);
          setLoadingSave(false);
          let loadingArr = loadingOverlap.filter(ele => ele !== index + 1);
          setLoadingProceed([...loadingArr]);
          setDisableButton([...disableButton, index + 1]);
          return result;
        }
      })
      .catch(error => {
        setButtonIcon('');
        setButtonIcon(CROSS);
        setTextStatus(ERROR);
        setLoadingSave(false);
        let loadingArr = loadingOverlap.filter(ele => ele !== index + 1);
        setLoadingProceed([...loadingArr]);
        return [];
      });

    if (!resultAvailabilities) {
      return;
    }

    // savedAvailabilities.push(...resultAvailabilities);
    // if (!values.idSelected) {
    //   savedAvailabilities.forEach((availability, index_availability) => {
    //     Promise.all(
    //       availabilityValues[index_availability].details.map(
    //         saveAvailabilitiesDetails(availability.id)
    //       )
    //     );
    //   });
    // }
    setLoadingSave(false);
    searchRotaShift();
    // }
  };
  const splitAvailability = async (
    available,
    force_submit,
    employee_id,
    start_date,
    end_date,
    item,
    index
  ) => {
    let detail_tem_id = [];

    item.forEach(val => {
      detail_tem_id.push(val.detail_id);
    });

    const availabilityValues = [];

    if (
      errors.length > 0 ||
      !values.employee ||
      (!values.start_day && !values.end_day) ||
      (!values.start && !values.end) ||
      !validateDate(true, values.start_day, values.end_day) ||
      !validateTime(
        true,
        values.start_day,
        values.end_day,
        values.start,
        values.end
      )
    ) {
      setCheckRequires(true);
      setErrorMessage(InvalidOrEmptyValues);
      return;
    }

    let newData = {};
    newData = contextChoices.regular_shift_type.find(items => {
      if (items.name === values.name) {
        return items;
      }
    });

    setLoadingOverlap([...loadingOverlap, index + 1]);
    let forceUpdate = { force: false };

    if (force_submit) {
      forceUpdate = { force: true };
    }

    let firstDayValue = start_date.split(' ')[0];
    // let firstDayValue = start_date[0].split(" ")[0];
    //changed the start  as its changed in splitOverlapModal.js
    let weeks = [];
    let day_information = [];

    let no_of_week = moment(firstDayValue).isoWeek();
    weeks.push(no_of_week);

    while (moment(firstDayValue).isSameOrBefore(end_date.split(' ')[0])) {
      // while (moment(firstDayValue).isSameOrBefore(end_date[0].split(" ")[0])) {
      //changed the end dtae as its changed in splitOverlapModal.js
      const searchWeek = weeks.indexOf(moment(firstDayValue).isoWeek());

      if (searchWeek <= -1) {
        weeks.push(moment(firstDayValue).isoWeek());
      }

      const beginningTime = moment(values.start, 'h:mma');
      const endTime = moment(values.end, 'h:mma');
      // const crossDays =
      // start_date.split(" ")[0]!== end_date.split(" ")[0] &&
      //   endTime.isSameOrBefore(beginningTime);

      // const start_date_format = createDateWithTime(
      //   firstDayValue,
      //   values.start + ':00'
      // );
      const start_date_format = firstDayValue + ' ' + values.start;
      const end_date_format = firstDayValue + ' ' + values.end;
      day_information.push({
        week_no: moment(firstDayValue).isoWeek(),
        details: {
          id: values.idSelected,
          is_available: available,
          start_date: start_date_format,
          end_date: end_date_format,
          is_overnight: values.is_overnight,
          shift_type:
            values.shift_type === 'new_shift' ? newData.id : values.shift_type,
          travel_mode: values.travel_method ? values.travel_method : null,
          travel_time: values.travel_time ? values.travel_time : null,
          pay_rate_card: values.regular_shift_pay_rate_id,
          is_absent: values.is_absent ? values.is_absent : false,
          absence_informed_method: values.absence_informed_method
            ? values.absence_informed_method
            : null,
          absence_paid: values.absence_paid ? values.absence_paid : null,
          absence_planned: values.absence_planned
            ? values.absence_planned
            : null,
          absence_reason: values.absence_reason ? values.absence_reason : null,
          absence_type: values.absence_type ? values.absence_type : null,
          absence_notes: values.absence_notes ? values.absence_notes : null,
          sickness_reason: values.sickness_reason
            ? values.sickness_reason
            : null,
        },
      });

      firstDayValue = moment(firstDayValue).add(1, 'days').format('YYYY-MM-DD');
    }

    weeks.forEach(week => {
      const my_days = [];
      day_information.forEach(day_info => {
        if (day_info.week_no === week) {
          delete day_info.week_no;
          my_days.push({ ...day_info.details });
        }
      });
      // const weeks = [...daysOfWeek]
      // const weekDays = weeks.filter(weeks => weeks.selected === true);
      //  const weekArray =[] ;
      //  weekDays.forEach(w=>{
      // weekArray.push(w.day);
      //  });
      //  const week_of_day = weekArray.join();

      let update_keys = {};

      if (values.idSelected) {
        update_keys = {
          update_detail: { ...my_days[0] },
          is_update: true,
        };
      } else {
        update_keys = {
          details: [...my_days],
        };
      }

      availabilityValues.push({
        // id: values.employee.map(employee=>employee.value),
        employee: employee_id,
        force: true,

        start_date: start_date.split(' ')[0] + ' ' + values.start + ':00',
        // start_date: start_date[0].split(" ")[0]  + " " + values.start + ":00",
        end_date: end_date.split(' ')[0] + ' ' + values.end,
        detail_id: [...detail_tem_id],
        // end_date: end_date[0].split(" ")[0] + " " + values.end + ":00",
        //changed the start +end dtae as its changed in splitOverlapModal.js
        travel_method: values.travel_method ? values.travel_method : null,
        travel_time: values.travel_time ? values.travel_time : null,
        shift_type:
          values.shift_type === 'new_shift' ? newData.id : values.shift_type || values.is_absent ? absenceId.id : newShiftId.id,
        shift_bill_rate: values.travel_rate,
        is_overnight: values.is_overnight,

        ...update_keys,
        // week_of_day:week_of_day
      });
    });

    const saveAvailability = () => {
      return async function (availabilities) {
        return await setSplitAvailability(availabilities);
      };
    };

    const saveAvailabilitiesDetails = function (availability_Id) {
      return async function (details) {
        return await setAvailabilityDetails(details, availability_Id);
      };
    };

    let savedAvailabilities = [];
    const resultAvailabilities = await Promise.all(
      availabilityValues.map(saveAvailability())
    )
      .then(result => {
        console.log(result, 75946);
        if (result[0].message) {
          setIsDateAssigned(true);
          assignMessageAvailableUnavailable(available, result);
          throw new Error();
        } else {
          setButtonIcon('');
          setButtonIcon(CHECK);
          setTextStatus(SAVED);
          let loadingArr = loadingOverlap.filter(ele => ele !== index + 1);
          setLoadingOverlap([...loadingArr]);
          setDisableButton([...disableButton, index + 1]);
          return result;
        }
      })
      .catch(error => {
        setButtonIcon('');
        setButtonIcon(CROSS);
        setTextStatus(ERROR);
        let loadingArr = loadingOverlap.filter(ele => ele !== index + 1);
        setLoadingOverlap([...loadingArr]);
        return [];
      });

    if (!resultAvailabilities) {
      return;
    }

    savedAvailabilities.push(...resultAvailabilities);
    if (!values.idSelected) {
      savedAvailabilities.forEach((availability, index_availability) => {
        Promise.all(
          availabilityValues[index_availability].details.map(
            saveAvailabilitiesDetails(availability.id)
          )
        );
      });
    }
    setLoadingSave(false);
    searchRotaShift();
  };

  const handleDeleteUnavailabilityDetail = async (force) => {
    // await deleteAvailabilitiesDetails(
    //   values.shiftAvailabilityId,
    //   values.idSelected
    // );
    // if there was 1 detail left, then also delete the parent
    // if (
    //   values.availabilitiesDetails &&
    //   values.availabilitiesDetails.length === 1
    // ) {
    // await deleteAvailabilities(values?.shiftAvailabilityId);
    const res = await deleteAvailabilities(values?.shiftAvailabilityId, force);
    const json = await res.json()
    if (json.unassigned_visit_data && (json.unassigned_visit_data.length > 0)) {
      setModalConfirmUnassignVisits(true)
      setUnassignedData(json.unassigned_visit_data)
    } else {
      setModalConfirmUnassignVisits(false)
    }
    searchRotaShift();
    // }
    setAddUnavailability(false);
  };

  const handleDownlaod = () => {
    let fileName =
      'Rotashift_' +
      moment(values.start_date).format('YYYY-MM-DD') +
      '_' +
      moment(values.end_date).format('YYYY-MM-DD');
    SetDownloadDisable(true);
    getDownloadRotaShiftFile(values)
      .then(response => {
        response.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          a.click();
          SetDownloadDisable(false);
        });
      })
      .catch(error => {
        SetDownloadDisable(false);
      });
  };

  const handleDeleteAvailabilityDetail = async (force) => {
    setHandleDeleteClicked(true)
    const res = await deleteAvailabilities(values?.shiftAvailabilityId, force);
    const json = await res.json()
    if (json.unassigned_visit_data && (json.unassigned_visit_data.length > 0)) {
      setModalConfirmUnassignVisits(true)
      setUnassignedData(json.unassigned_visit_data)
    } else {
      setModalConfirmUnassignVisits(false)
    }
    searchRotaShift();
    setAddAvailability(false);
    setHandleDeleteClicked(false)
  };

  const handleDeleteShiftDetail = async () => {
    await deleteShiftDetails(values.shiftId, values.idSelected);
    // if there was 1 detail left, then also delete the parent
    if (values.shiftDetails && values.shiftDetails.length === 1) {
      await deleteShift(values.shiftId);
    }
    searchRotaShift();
    handleAssignShiftModal();
  };

  const eventClickHandler = async ({ id, groupId, extendedProps }) => {
    let shifts;
    let selectedShiftDetail;
    let availabilities;
    let selectedAvailabilityDetail;
    setShiftTypePopup(extendedProps.typeEvent);
    setHandleDeleteClicked(false)
    setDetailId(id);
    const getAvailabilitiesData = async () => {
      availabilities = await getAvailabilities(groupId, id);
      console.log(availabilities, "availabilities")
      values.travel_method = availabilities.travel_method;
      // search the selected availability in the array of details
      // selectedAvailabilityDetail =availabilities.details &&  availabilities.details.find(
      //   detail => detail.id === id
      // );
      selectedAvailabilityDetail = availabilities;
      setAvailabilitiesEmployeeIdName(availabilities?.employee_details?.first_name);
      setAvailabilitiesEmployeeId(availabilities.employee);
      setAvailabilitiesEmployeeFullName(availabilities?.employee_details?.full_name);
    };

    const getShiftData = async () => {
      shifts = await getAvailabilities(groupId, id);
      //   search the selected shift in the array of details
      //   if (shifts.details != null) {
      //     selectedShiftDetail = shifts.details.find(detail => detail.id === id);
      //   }
      selectedShiftDetail = shifts;
      setAvailabilitiesEmployeeIdName(shifts.employee_details.first_name);
      setAvailabilitiesEmployeeId(shifts.employee);
      setAvailabilitiesEmployeeFullName(shifts.employee_details.full_name);
    };

    const loadAvailabilitySelectedData = () => {
      values.employee = availabilities.employee
      setEmployeeTempOnPopup(availabilities.employee)
      values.days =
        selectedAvailabilityDetail &&
        moment(selectedAvailabilityDetail.start_date, 'YYYY-MM-DD').format(
          'dddd'
        );
      setAvailabilityDetailsDays(values.days);
      values.idSelected = id;
      values.shiftAvailabilityId = groupId;
      values.branch_id = availabilities.branch
      values.travel_method = availabilities.travel_mode;
      values.is_overnight = availabilities.is_overnight;
      values.availabilitiesDetails = availabilities.details;
      values.travel_time = availabilities.travel_time;
      values.shift_type = availabilities.shift_type;
      values.remote_work = availabilities.remote_work
      values.regular_shift_pay_rate_id = availabilities?.pay_rate_card ? availabilities?.pay_rate_card : null
      values.pay_amount = availabilities?.pay_amount || null
      values.pay_rate_card = availabilities?.pay_rate_card


      values.absence_hours_to_paid = availabilities?.absence_hours_to_paid || null
      values.avg_52_weeks_hours = availabilities?.avg_working_days_52_weeks || null
      values.branch_specific = availabilities?.branch_specific
      let shift_name = contextChoicesMap && getNameFormChoicesMap("regular_shift_type", availabilities?.shift_type);
      setShiftTempOnPopup(shift_name)
      if (availabilities.shift_type) {
        const result = ['Availability', 'Unavailability', 'Bank-Flexible', 'Absence', 'Car - Driver - Shift'].some((item) => item === shift_name);
        if (result) {
          setEmployeeDisable(true)
        } else {
          setEmployeeDisable(false)
        }
      }
      values.travel_rate = availabilities.shift_bill_rate;
      values.comment = availabilities.comment;
      // values.regular_shift_pay_rate_id = availabilities.shift_availability_pay_rate
      values.regular_shift_pay_rate_id = availabilities.pay_rate_card;
      values.start_day =
        selectedAvailabilityDetail &&
        moment(selectedAvailabilityDetail.start_date).format('YYYY-MM-DD');
      values.end_day =
        selectedAvailabilityDetail &&
        moment(selectedAvailabilityDetail.end_date).format('YYYY-MM-DD');
      values.start =
        selectedAvailabilityDetail &&
        moment(selectedAvailabilityDetail.start_date).format('HH:mm');
      values.end =
        selectedAvailabilityDetail &&
        moment(selectedAvailabilityDetail.end_date).format('HH:mm');
      values.is_absent =
        selectedAvailabilityDetail && selectedAvailabilityDetail.is_absent;
      values.absence_type =
        selectedAvailabilityDetail &&
        selectedAvailabilityDetail.is_absent &&
        selectedAvailabilityDetail.absence_type;
      values.absence_reason =
        selectedAvailabilityDetail &&
        selectedAvailabilityDetail.is_absent &&
        selectedAvailabilityDetail.absence_reason;
      values.sickness_reason =
        selectedAvailabilityDetail &&
        selectedAvailabilityDetail.is_absent &&
        selectedAvailabilityDetail.sickness_reason;
      values.absence_informed_method =
        selectedAvailabilityDetail &&
        selectedAvailabilityDetail.is_absent &&
        selectedAvailabilityDetail.absence_informed_method;
      values.absence_planned =
        selectedAvailabilityDetail &&
        selectedAvailabilityDetail.is_absent &&
        selectedAvailabilityDetail.absence_planned;
      values.absence_paid =
        selectedAvailabilityDetail &&
        selectedAvailabilityDetail.is_absent &&
        selectedAvailabilityDetail.absence_paid;
      values.absence_notes =
        selectedAvailabilityDetail &&
        selectedAvailabilityDetail.is_absent &&
        selectedAvailabilityDetail.absence_notes;
      const match = availabilities?.pay_rate_card ? absenceRateCard?.find(el => el.id == availabilities?.pay_rate_card) : null
      if (match) {
        values.pay_rate_per_hour = match.rate_per_hour
      }
      setValues({ ...values });
    };

    const loadShiftSelectedData = () => {
      // values.idSelected = id;
      // values.shiftId = groupId;
      // values.shiftDetails = shifts.details;
      // values.pattern = shifts.pattern_details;
      // values.pattern = shifts.regualar_shift_detail;
      // if (selectedShiftDetail && selectedShiftDetail.pattern_details) {
      //   values.pattern =
      //     selectedShiftDetail &&
      //     selectedShiftDetail.pattern_details &&
      //     selectedShiftDetail.pattern_details.id;
      // } else if (
      //   selectedShiftDetail &&
      //   selectedShiftDetail.regualar_shift_details
      // ) {
      //   values.pattern =
      //     selectedShiftDetail &&
      //     selectedShiftDetail.regualar_shift_details &&
      //     selectedShiftDetail.regualar_shift_details.id;
      //   setDisplay(false);
      //   sertRegularshiftName(
      //     selectedShiftDetail &&
      //       selectedShiftDetail.regualar_shift_details &&
      //       selectedShiftDetail.regualar_shift_details.regular_shift_name
      //   );

      values.employee = {
        value: selectedShiftDetail.employee.id,
        label: selectedShiftDetail.employee.full_name,
      };
      values.days =
        selectedAvailabilityDetail &&
        moment(selectedAvailabilityDetail.start_date, 'YYYY-MM-DD').format(
          'dddd'
        );
      setAvailabilityDetailsDays(values.days);
      values.idSelected = id;
      values.shiftAvailabilityId = groupId;
      values.travel_method = selectedShiftDetail.travel_mode.id;
      values.is_overnight = selectedShiftDetail.is_overnight;
      values.availabilitiesDetails = selectedShiftDetail.details;
      values.travel_time = selectedShiftDetail.travel_time;
      values.shift_type = selectedShiftDetail.shift_type;
      values.remote_work = selectedShiftDetail.remote_work;
      values.travel_rate = selectedShiftDetail.shift_bill_rate;
      values.comment = selectedShiftDetail.comment;
      // values.regular_shift_pay_rate_id = selectedShiftDetail.shift_availability_pay_rate
      values.regular_shift_pay_rate_id = selectedShiftDetail.pay_rate_card;
      values.start_day =
        selectedShiftDetail &&
        moment(selectedShiftDetail.start_date).format('YYYY-MM-DD');
      values.end_day =
        selectedShiftDetail &&
        moment(selectedShiftDetail.end_date).format('YYYY-MM-DD');
      values.start =
        selectedShiftDetail &&
        moment(selectedShiftDetail.start_date).format('HH:mm');
      values.end =
        selectedShiftDetail &&
        moment(selectedShiftDetail.end_date).format('HH:mm');

      setValues({ ...values });
      // sertRegularshiftName(selectedShiftDetail.regualar_shift_details )
      // }

      // values.travel_mode = selectedShiftDetail.travel_mode.id;
      // console.log(values.travel_mode, ' values.travel_mode');
      // if (selectedShiftDetail != null) {
      //   values.start_day = moment(selectedShiftDetail.start_date).format(
      //     'YYYY-MM-DD'
      //   );
      //   values.end_day = moment(selectedShiftDetail.end_date).format(
      //     'YYYY-MM-DD'
      //   );
      // }
      // if (shifts.employee != null) {
      //   values.employee = {
      //     value: shifts.employee.id,
      //     label: shifts.employee.full_name,
      //   };
      // }
      // setValues({...values});
    };

    switch (extendedProps.typeEvent) {
      case TYPE_SHIFT:
        await getShiftData();
        // handleAssignShiftModal();
        handleAddAvailability();
        loadShiftSelectedData();
        break;
      case TYPE_AVAILABLE:
        await getAvailabilitiesData();
        handleAddAvailability();
        loadAvailabilitySelectedData();
        break;
      case TYPE_UNAVAILABLE:
        await getAvailabilitiesData();
        handleAddUnavailability();
        loadAvailabilitySelectedData();
        break;
      default:
    }
  };

  const handleConfirmUnassignVisitsPopup = () => {
    let unassignIds = unassignedData.map((item) => {
      return item.visit_id
    })
    let body = {
      unassigned_visit_ids: unassignIds
    }
    setUnassignedVisitOnRota(body)
      .then((res) => {
        if (res.status)
          setModalConfirmUnassignVisits(false)
        searchRotaShift()
      })
      .catch((error) => { })
  }

  const loadChoices = () => {
    setChoices((contextChoices && contextChoices) || []);

    setRunRouteData(
      contextChoices &&
      contextChoices.run_routes &&
      contextChoices.run_routes.map(y => {
        return { id: y && y.id, name: y && y.name };
      })
    );

    setJobDepartment(
      contextChoices.job_category &&
      contextChoices.job_category.map(y => {
        return { id: y && y.id, name: y && y.name };
      })
    );
    setBranch(
      contextChoices.branch &&
      contextChoices.branch.map(y => {
        return { id: y && y.id, name: y && y.name };
      })
    );

    setNewShiftId(
      contextChoices && contextChoices?.regular_shift_type
      && contextChoices?.regular_shift_type.find(item => {
        if (item.name === 'Unavailability') {
          return item;
        }
      }));


    setAbsenceId(
      contextChoices && contextChoices?.regular_shift_type
      && contextChoices?.regular_shift_type.find(item => {
        if (item.name === 'Absence') {
          return item;
        }
      }));

    setEmployees(contextChoices && contextChoices?.employee_listing &&
      contextChoices?.employee_listing.map((item) => {
        return ({ id: item.id, name: item.full_name })
      }));
  };

  console.log(shiftOverlapData, 75663);

  const { displayMenu, selectedElId, clearSelectedElId, encloseModalBind } = useContextMenu(decreaseWidth)
  const rotaMenuItems = [
    {
      name: "Reassign Shift",
      onClick: () => setAssignShift(!AssignShift)
    },
    {
      name: "Edit Actual",
      onClick: () => setEditActual(!editActual)
    },
    {
      name: "Revert Clocked",
      onClick: () => setReverClocked(!ReverClocked)
    },
    {
      name: "Edit",
      onClick: () => handleBulkEditModal()
    },
    {
      name: "Delete",
      onClick: () => handleBulkDeleteModal()
    },
    {
      name: "Rate Card",
      onClick: () => setAssignRate(!assignRate)
    },
  ]
  // checkedrows
  const rightClickHandler = (arge) => {
    arge.el.addEventListener("contextmenu", (e) => displayMenu(e, arge?.event?._def?.publicId))
  }
  const removeRightClickHandler = (arge) => {
    arge.el.removeEventListener("contextmenu", (e) => displayMenu(e, arge?.event?._def?.publicId))
  }

  return (
    <>
      <Toast ref={toast} />
      <ContextMenu menuItems={rotaMenuItems} />
      {loading && <Spinner spinnerTop={'90%'} textTop={'88%'} />}
      <RotaShiftContainer loading={loading} height={'100%'}>
        {decreaseWidth &&
          <>
            <Title>Rota Shifts</Title>
            <div className={'mt-2'}>
              <div>
                <FiltersContainerRotaShifts>
                  <FiltersTypesContainerRotaShifts>
                    <NoSelectAllDropdownFilter
                      items={employees ? employees : []}
                      setItemsSelected={items => {
                        dropDownHandleSelect({ employees: items });
                      }}
                      itemsSelected={employeeFilter}
                      setSelectedFilter={setEmployeefilter}
                      placeHolder={'Employees'}
                      isObject
                      allOption
                    // hitApiAfterClear={hitApiAfterClear}
                    />
                  </FiltersTypesContainerRotaShifts>
                  {/* <FiltersTypesContainerRotaShifts className="ml-2">
                        <Dropdown
                          items={patternsCombo}
                          onSelect={item => {
                            dropDownHandleSelect({ shift: item });
                          }}
                          placeHolder={'Shift'}
                          isObject
                          allOption
                        />
                      </FiltersTypesContainerRotaShifts> */}
                  {/* <FiltersTypesContainerRotaShifts className="ml-2">
                  <NoSelectAllDropdownFilter
                    items={runRouteData?runRouteData:[]}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ runroutes: items });
                    }}
                    itemsSelected={runRoutesFilter}
                    setSelectedFilter={setRunroutefilter}
                    placeHolder={'Group Area'}
                    isObject
                    allOption 
               
                  />
                </FiltersTypesContainerRotaShifts> */}
                  <>
                    <FiltersTypesContainerRotaShifts className="ml-2">
                      <NoSelectAllDropdownFilter
                        items={runRouteData ? runRouteData : []}
                        setItemsSelected={items => {
                          dropDownHandleSelect({ runroutesteam: items });
                        }}
                        itemsSelected={runRoutesFilter}
                        setSelectedFilter={setRunroutefilter}
                        placeHolder={'Team Group Area'}
                        isObject
                        allOption
                      />
                    </FiltersTypesContainerRotaShifts>

                    <FiltersTypesContainerRotaShifts className="ml-2">
                      <NoSelectAllDropdownFilter
                        items={runRouteData ? runRouteData : []}
                        setItemsSelected={items => {
                          dropDownHandleSelect({ runroutesclient: items });
                        }}
                        itemsSelected={runRoutesclientFilter}
                        setSelectedFilter={setRunrouteclientfilter}
                        placeHolder={'Client Group Area'}
                        isObject
                        allOption
                      />
                    </FiltersTypesContainerRotaShifts>
                  </>

                  <FiltersTypesContainerRotaShifts className="ml-2">
                    <NoSelectAllDropdownFilter
                      items={jobDepartment ? jobDepartment : []}
                      setItemsSelected={items => {
                        dropDownHandleSelect({ jobDepartment: items });
                      }}
                      itemsSelected={jobDepartmentFilter}
                      setSelectedFilter={setJobdeptfilter}
                      placeHolder={' Job Department '}
                      isObject
                      allOption
                    // hitApiAfterClear={hitApiAfterClear}
                    />
                  </FiltersTypesContainerRotaShifts>

                  <FiltersTypesContainerRotaShifts className="ml-2">
                    <NoSelectAllDropdownFilter
                      items={branch ? branch : []}
                      setItemsSelected={items => {
                        dropDownHandleSelect({ branch: items });
                      }}
                      itemsSelected={branchFilter}
                      setSelectedFilter={setFilterBranch}
                      placeHolder={'Branch'}
                      isObject
                      allOption
                    // hitApiAfterClear={hitApiAfterClear}
                    />
                  </FiltersTypesContainerRotaShifts>
                  <FiltersTypesContainerRotaShifts className="ml-2">
                    <NoSelectAllDropdownFilter
                      items={
                        choices && choices.regular_shift_type
                          ? choices.regular_shift_type
                          : []
                      }
                      setItemsSelected={items => {
                        dropDownHandleSelect({ shift_type_filter: items });
                      }}
                      itemsSelected={shiftTypeFilter}
                      setSelectedFilter={setFilterShiftType}
                      placeHolder={'Shift Type'}
                      isObject
                      allOption
                    // hitApiAfterClear={hitApiAfterClear}
                    />
                  </FiltersTypesContainerRotaShifts>
                  <PrimaryButtonForm
                    minWidth="6rem"
                    className={'ml-2'}
                    onClick={() => searchRotaShift()}>
                    <span className={'mr-1'}>Search</span>
                  </PrimaryButtonForm>
                </FiltersContainerRotaShifts>
              </div>
              <div className="row mt-1 ">
                {((create && filterClient === 'null') || userStaff == true) && (
                  <ButtonsModals className="col-12 col-md-12 col-lg-12 col-xl-6 d-flex justify-content-center justify-content-md-start mt-2 pt-1   ">
                    <FiltersContainerRotaShifts>
                      <FiltersLeftGroupRotaShifts>
                        <FiltersTypesContainerRotaShifts
                          className={'mr-2 text-right'}>
                          <PrimaryButtonForm
                            minWidth="9rem"
                            onClick={handleAssignShiftModal}>
                            <span className={'mr-2'}>Assign Shift</span>
                          </PrimaryButtonForm>
                        </FiltersTypesContainerRotaShifts>

                        <FiltersTypesContainerRotaShifts
                          className={'mr-2' + ' text-left'}>
                          <PrimaryButtonForm
                            minWidth="9rem"
                            onClick={handleAddAvailability}>
                            <span className={'mr-2'}>Add Available</span>
                          </PrimaryButtonForm>
                        </FiltersTypesContainerRotaShifts>
                      </FiltersLeftGroupRotaShifts>

                      <FiltersTypesContainerRotaShifts>
                        <PrimaryButtonForm
                          minWidth="9rem"
                          onClick={handleAddUnavailability}>
                          <span>Add Unavailable</span>
                        </PrimaryButtonForm>
                      </FiltersTypesContainerRotaShifts>
                    </FiltersContainerRotaShifts>
                  </ButtonsModals>
                )}
              </div>
            </div>
            <ButtonsContainerRota rotaView={rotaView} listView={listView}>
              <div
                className={'rotaView'}
              //  style={{ marginTop: '3.75%' }}
              >
                <button
                  className={'teamView'}
                  onClick={() => {
                    setListView(false);
                  }}>
                  Team View
                </button>

                <button
                  //   style={{ marginRight: '14%' }
                  //  }
                  className={'listView'}
                  onClick={() => {
                    setListView(true);
                  }}>
                  List View
                </button>
              </div>
            </ButtonsContainerRota>
          </>
        }

        {!assignShiftModal
          ? null
          : filterClient === 'null' && (
            <AssignShiftModal
              readOnly={values.idSelected ? true : false}
              dropDownHandleSelect={dropDownHandleSelect}
              fullBind={() => encloseModalBind(fullBindAssignShiftModal)}
              useInput={useInput}
              patterns={patterns}
              values={values}
              choices={choices}
              dateMessage={dateMessage}
              dateError={dateError}
              buttonIcon={buttonIcon}
              loadingSave={loadingSave}
              textStatus={textStatus}
              errorMessage={errorMessage}
              addShift={addShift}
              deleteShiftDetail={handleDeleteShiftDetail}
              employees={employees}
              employeesHandleSelect={employeesHandleSelect}
              isDateAssigned={isDateAssigned}
              closeModal={() => {
                clearSelectedElId();
                setAssignShift(false);
              }}
              typeOfMessage={typeOfMessage}
              filterEmployee={filterEmployee}
              filterClient={filterClient}
              userStaff={userStaff}
              filterSuperAdmin={filterSuperAdmin}
              filterAdmin={filterAdmin}
              display={display}
              regularshiftName={regularshiftName}
              setDisplay={setDisplay}
              detailId={detailId}
              travelRate={travelRate}
              createAssignShift={createAssignShift}
              createSelfAssignShift={createSelfAssignShift}
            />
          )}
        {!addAvailability
          ? null
          :
          <AddAvailability
            readOnly={false}
            travelRate={travelRate}
            dropDownHandleRate={dropDownHandleRate}
            dropDownHandleSelect={dropDownHandleSelect}
            fullBind={fullBindAddAvailability}
            useInput={useInput}
            employees={employees}
            values={values}
            addNewAvailability={addNewAvailability}
            splitAvailability={splitAvailability}
            deleteAvailabilityDetail={handleDeleteAvailabilityDetail}
            errorMessage={errorMessage}
            buttonIcon={buttonIcon}
            loadingSave={loadingSave}
            textStatus={textStatus}
            dateMessage={dateMessage}
            timeMessage={timeMessage}
            dateError={dateError}
            timeError={timeError}
            choices={choices}
            closeModal={() => {
              setAddAvailability(false);
            }}
            isDateAssigned={isDateAssigned}
            typeOfMessage={typeOfMessage}
            //added new props below
            shiftIndex={0}
            daysOfWeek={daysOfWeek}
            setDaysOfWeek={setDaysOfWeek}
            requireDayOfWeek={requireDayOfWeek}
            nameSection={`${SHIFT_VALUES}`}
            setValues={setValues}
            filterEmployee={filterEmployee}
            filterClient={filterClient}
            userStaff={userStaff}
            filterSuperAdmin={filterSuperAdmin}
            filterAdmin={filterAdmin}
            invalidOvernight={invalidOvernight}
            setInvalidOvernight={setInvalidOvernight}
            availabilitiesEmployeeId={availabilitiesEmployeeId}
            availabilitiesEmployeeFullName={availabilitiesEmployeeFullName}
            availabilitiesEmployeeIdName={availabilitiesEmployeeIdName}
            employeesHandleSelect={employeesHandleSelect}
            availabilityDetailsDays={availabilityDetailsDays}
            detailId={detailId}
            createAvailable={createAvailable}
            createSelfAvailable={createSelfAvailable}
            shiftTypePopup={shiftTypePopup}
            employeeDisable={employeeDisable}
          />
        }
        {!bulkEditModal ? null : (
          <EditBulk
            readOnly={false}
            travelRate={travelRate}
            dropDownHandleRate={dropDownHandleRate}
            dropDownHandleSelect={dropDownHandleSelect}
            fullBind={() => encloseModalBind(fullBindBulkEditModal)}
            useInput={useInput}
            employees={employees}
            values={values}
            addNewAvailability={addNewAvailability}
            editAvailabilityBulk={editAvailabilityBulk}
            splitAvailability={splitAvailability}
            deleteAvailabilityDetail={handleDeleteAvailabilityDetail}
            errorMessage={errorMessage}
            buttonIcon={buttonIcon}
            loadingSave={loadingSave}
            textStatus={textStatus}
            dateMessage={dateMessage}
            timeMessage={timeMessage}
            dateError={dateError}
            timeError={timeError}
            choices={choices}
            closeModal={() => {
              clearSelectedElId();
              setBulkEditModal(false);
            }}
            isDateAssigned={isDateAssigned}
            typeOfMessage={typeOfMessage}
            //added new props below
            shiftIndex={0}
            daysOfWeek={daysOfWeek}
            setDaysOfWeek={setDaysOfWeek}
            requireDayOfWeek={requireDayOfWeek}
            nameSection={`${SHIFT_VALUES}`}
            setValues={setValues}
            filterEmployee={filterEmployee}
            filterClient={filterClient}
            userStaff={userStaff}
            filterSuperAdmin={filterSuperAdmin}
            filterAdmin={filterAdmin}
            invalidOvernight={invalidOvernight}
            setInvalidOvernight={setInvalidOvernight}
            availabilitiesEmployeeId={availabilitiesEmployeeId}
            availabilitiesEmployeeFullName={availabilitiesEmployeeFullName}
            availabilitiesEmployeeIdName={availabilitiesEmployeeIdName}
            employeesHandleSelect={employeesHandleSelect}
            availabilityDetailsDays={availabilityDetailsDays}
          />
        )}

        {!addUnavailability
          ? null
          : filterClient === 'null' && (
            <AddUnavailability
              absenceRateCard={absenceRateCard}
              readOnly={false}
              dropDownHandleSelect={dropDownHandleSelect}
              fullBind={fullBindAddUnavailability}
              useInput={useInput}
              employees={employees}
              choices={choices}
              values={values}
              errorTime={errorTime}
              splitAvailability={splitAvailability}
              addNewAvailability={addNewAvailability}
              deleteAvailabilityDetail={handleDeleteUnavailabilityDetail}
              errorMessage={errorMessage}
              buttonIcon={buttonIcon}
              loadingSave={loadingSave}
              textStatus={textStatus}
              dateMessage={dateMessage}
              timeMessage={timeMessage}
              dateError={dateError}
              timeError={timeError}
              closeModal={() => {
                setAddUnavailability(false);
              }}
              isDateAssigned={isDateAssigned}
              typeOfMessage={typeOfMessage}
              //added new props below
              shiftIndex={0}
              daysOfWeek={daysOfWeek}
              setDaysOfWeek={setDaysOfWeek}
              requireDayOfWeek={requireDayOfWeek}
              nameSection={`${SHIFT_VALUES}`}
              setValues={setValues}
              filterEmployee={filterEmployee}
              filterClient={filterClient}
              userStaff={userStaff}
              filterSuperAdmin={filterSuperAdmin}
              filterAdmin={filterAdmin}
              invalidOvernight={invalidOvernight}
              setInvalidOvernight={setInvalidOvernight}
              handleDropAbsenceType={handleDropAbsenceType}
              employeesHandleSelect={employeesHandleSelect}
              availabilityDetailsDays={availabilityDetailsDays}
              createUnAvailable={createUnAvailable}
              createSelfUnAvailable={createSelfUnAvailable}
              handleDeleteClicked={handleDeleteClicked}
            />
          )}
        {assignTravel && (
          <AssignTravelRate
            fullBind={fullBindAssignTravel}
            employees={employees}
            checkedRows={checkedRows}
            choices={choices}
            travelRate={travelRate}
            searchScheduler={searchRotaShift}
          />
        )}
        {!overlapModal ? null : (
          <SplitOverlapModal
            fullBind={fullBindOverlapModal}
            shiftOverlapData={shiftOverlapData}
            unassignedVisitList={unassignedVisitList}
            setOverlapData={setOverlapData}
            employees={employees}
            splitAvailability={splitAvailability}
            loadingOverlap={loadingOverlap}
            addNewAvailability={addNewAvailability}
            loadingProceed={loadingProceed}
            disableButton={disableButton}
            overlapMessage={overlapMessage}
            currentModal={currentModal}
            values={values}
          />
        )}
        {!overlapModalShift ? null : (
          <ShiftSplitOverlapModal
            fullBind={fullBindOverlapModalShift}
            shiftOverlapData={shiftOverlapData}
            setOverlapData={setOverlapData}
            employees={employees}
            loadingOverlap={loadingOverlap}
            addShift={addShift}
            loadingProceed={loadingProceed}
            disableButton={disableButton}
            overlapMessage={overlapMessage}
            currentModal={currentModal}
            values={values}
          />
        )}
        {editActual && (
          <ActualEdits
            fullBind={() => encloseModalBind(fullBindEditActual)}
            checkedRows={selectedElId ? [{ id: selectedElId }] : checkedRows}
            searchRotaShift={searchRotaShift}
            setEditActual={setEditActual}
            shiftTypeChecked={shiftTypeChecked}
            clearSelectedElId={clearSelectedElId}
          />
        )}
        {ReverClocked && (
          <RevertClocked
            fullBind={() => encloseModalBind(fullBindReverClocked)}
            checkedRows={selectedElId ? [{ id: selectedElId }] : checkedRows}
            searchRotaShift={searchRotaShift}
            setReverClocked={setReverClocked}
            shiftTypeChecked={shiftTypeChecked}
            clearSelectedElId={clearSelectedElId}
          />
        )}
        {assignRate && (
          <AssignRate
            fullBind={() => encloseModalBind(fullBindAssignRate)}
            checkedRows={selectedElId ? [{ id: selectedElId }] : checkedRows}
            searchRotaShift={searchRotaShift}
            setAssignRate={setAssignRate}
            travelRate={travelRate}
            payRate={payRate}
            onCallRate={onCallRate}
            officeRate={officeRate}
            choices={choices}
            clearSelectedElId={clearSelectedElId}
          />
        )}

        {AssignShift && (
          <ReassignShiftModal
            fullBind={() => encloseModalBind(fullBindAssignShift)}
            checkedRows={selectedElId ? [{ id: selectedElId }] : checkedRows}
            searchRotaShift={searchRotaShift}
            setAssignShift={setAssignShift}
            shiftTypeChecked={shiftTypeChecked}
            employees={employees}
            clearSelectedElId={clearSelectedElId}
          />
        )}
        {decreaseWidth &&
          <div className={`d-flex ${listView ? "justify-end" : "justify-content-between"} align-items-center w-100`}>
            {!listView ? (
              <ToggleDiv>
                <ToggleWrapper>
                  <Toggle
                    id="checkbox"
                    type="checkbox"
                    onClick={() => {
                         setIncludeVisits(prev => !prev);
                    }}
                  />
                  <ToggleLabel htmlFor="checkbox" />
                </ToggleWrapper>
                <div className="toggle-label ml-2" style={{ minWidth: "6rem" }}>
                  Show Visits?
                </div>
              </ToggleDiv>
            ) : null}

            {listView && checkedRows && checkedRows.length > 0 && (
              <div class="dropdown text-right">
                <PrimaryButtonForm
                  minWidth="6rem"
                  class="btn dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">
                  <span className={'mr-2'}>Action</span>
                </PrimaryButtonForm>
                <div class="dropdown-menu" style={{ cursor: "pointer" }} aria-labelledby="dropdownMenuButton">
                  <a
                    class="dropdown-item"
                    onClick={() => setAssignShift(!AssignShift)}>
                    Reassign Shift
                  </a>
                  <a
                    class="dropdown-item"
                    onClick={() => setEditActual(!editActual)}>
                    Edit Actual
                  </a>
                  <a
                    class="dropdown-item"
                    onClick={() => setReverClocked(!ReverClocked)}>
                    Revert Clocked
                  </a>
                  <a class="dropdown-item" onClick={() => handleBulkEditModal()}>
                    Bulk Edit
                  </a>
                  <a class="dropdown-item" onClick={() => handleBulkDeleteModal()}>
                    Bulk Delete
                  </a>
                  <a
                    class="dropdown-item"
                    onClick={() => setAssignRate(!assignRate)}>
                    Rate Card
                  </a>
                  {/* <a class="dropdown-item" onClick={() => setAssignTravel(!assignTravel)}>Travel Rate</a> */}
                </div>
              </div>
            )}

            {!listView ? (
              <DataContainer className="my-0">
                <div className="total-container">
                  <div className="data-header">
                    <p>Hours</p>
                  </div>
                  <div className={'data-container data-container-schedule'}>
                    <div className="data">
                      <p className="fontBold">Visits</p>
                      <p>{visitHours}</p>
                    </div>
                    <div className="data leftPadding">
                      <p className="fontBold">Shifts</p>
                      <p>{shiftHours}</p>
                    </div>
                    <div className="data leftPadding">
                      <p className="fontBold">Available</p>
                      <p>{availableHours}</p>
                    </div>
                    <div className="data leftPadding">
                      <p className="fontBold">Unavailable</p>
                      <p>{unavailableHours}</p>
                    </div>
                    <div className="data leftPadding">
                      <p className="fontBold">Travel</p>
                      <p>{travelHours}</p>
                    </div>
                    <div className="data leftPadding">
                      <p className="fontBold">Wait</p>
                      <p>{waitHours}</p>
                    </div>
                  </div>
                </div>
              </DataContainer>
            ) : null}

          </div>
        }

        <ModalDecision
          type="delete"
          title="Warning"
          body={'Are you sure you want to delete this shift ? '}
          subBody={
            'This cannot be undone. Proceed to delete with extreme caution.'
          }
          onOk={handleDeleteTheShift}
          onCancel={() => {
            clearSelectedElId();
            setModalDeleteValue(false);
          }}
          okTitle={'DELETE'}
          {...encloseModalBind(bindModalDelete)}
        />
        {modalValueUnassignVisits && (
          <ConfirmationModal
            // selectedClientId={selectedClientId}
            type={'warning'}
            // title={''}
            // okTitle={'NO, CANCEL'}
            // onCancelTitle={'YES, PROCEED'}
            icon={ERROR_OUTLINE}
            // hideOnCancel={false}
            // onCancel={hideAllService}
            unassignedData={unassignedData}
            setModalValue={setModalConfirmUnassignVisits}
            fullBind={fullBindUnassignVisits}
            checkBeforeSend={() => {
              handleConfirmUnassignVisitsPopup()
            }}
            // loadingUnassigned={loadingUnassigned}
            // disableButtonUnassigned={disableButtonUnassigned}
            searchRotaShift={searchRotaShift}
            handleDeleteAvailabilityDetail={handleDeleteAvailabilityDetail}
          />)
        }
        {listView ? (
          <RotaListView
            rotaEvents={rotaEvents}
            setCheckedRows={setCheckedRows}
            checkedRows={checkedRows}
            setShiftTypeChecked={setShiftTypeChecked}
            shiftTypeChecked={shiftTypeChecked}
            displayMenu={displayMenu}
          />
        ) : (
          <TimelineCalendar
            resourceAreaHeaderContent="Employees"
            resources={[...resources]}
            events={
              includeVisits
                ? [...rotaEvents, ...scheduleEvents]
                : [...rotaEvents]
            }
            setShiftType={setShiftType}
            rotaShift={true}
            shiftType={shiftType}
            values={values}
            employeeVisitData={employeeVisitData}
            setValues={setValues}
            eventClickHandler={data => {
              eventClickHandler(data);
            }}
            downloadDisable={downloadDisable}
            handleDownlaod={handleDownlaod}
            setLoading={setLoading}
            useInput={useInput}
            decreaseWidth={decreaseWidth}
            setDecreaseWidth={setDecreaseWidth}
            // timeZone={'local'}
            rightClickHandler={(args) => rightClickHandler(args)}
            removeRightClickHandler={(args) => removeRightClickHandler(args)}
            seconds={seconds}
            setSeconds={setSeconds}
          />
        )}
      </RotaShiftContainer>
    </>
  );
};

export default RotaShifts;
