import ModalNoInfo from 'components/DashboardComponents/ModalDynamic/ModalNoInfo'
import DateSelector from 'components/SharedComponents/DateSelector'
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Fragment } from 'react'
import { ErrorMessage } from 'shared/styles/AuthComponents'
import { PrimaryButtonForm } from 'shared/styles/buttons'
import { FlexGrid3Container, FlexGrid3Item, ModalContainer } from 'shared/styles/constants/tagsStyles'
import { getModifiedVisitsId } from 'utils/api/AlertsApi'

function AddCareForm({formChoices, selectedClientId, fullBind, errorMessage, handleSubmit, textStatus, loadingSave, buttonIcon, values, useInput, setValues, contextChoices,contextChoicesMap }) {
    const [visitDropdown, setVisitDropdown] = useState([])
    const visitCompleteData = async () => {
        if (selectedClientId && values.client_service_visit_date) {
            const response = await getModifiedVisitsId(selectedClientId, values.client_service_visit_date
            );
            let VisitDropdown = response?.data?.map(visitData => {
                const jobTitle = visitData.job_title ? `${visitData.job_title} ` : '';
                const timeRange = `${visitData.start_date && visitData.start_date?.split("T")[1].slice(0, 5)} - ${visitData.end_date && visitData.end_date?.split("T")[1].slice(0, 5)}`;

                return {
                    id: visitData.id,
                    name: `${jobTitle}${timeRange}`,
                };
            });

            setVisitDropdown(VisitDropdown);
            setValues({ ...values });
        }
    };
    useEffect(() => {
        visitCompleteData()
    }, [selectedClientId, values.client_service_visit_date])

    const dropDownHandleSelect = (item, nameItem) => {
        if (nameItem == 'form') {
            values.form = item[nameItem].id
            values.form_name = item[nameItem].name
            //    values.form_name = contextChoicesMap.form_builder_list && contextChoicesMap?.form_builder_list[item[nameItem]] && contextChoicesMap.form_builder_list[item[nameItem]]?.name
            setValues({ ...values})
        }else {
        setValues({ ...values, ...item });
    }
    };
    return (
        <div>
            <ModalContainer max_width={'60%'}>
                <ModalNoInfo
                    title={'Add Care Form'}
                    {...fullBind}
                    max_width={'60%'}
                    minWidthTitle="8.5rem"
                    loading={false} >
                    <div className="pl-3 pr-3 pb-3 pt-0">
                        <>
                            {/* <TitleSection>Care Notes Details</TitleSection> */}
                            <FlexGrid3Container className={'w-100 mb-2'}>
                                <FlexGrid3Item className="pr-1 mt-1 mb-2" ResizeWidth={'50%'}>
                                    <DateSelector
                                        isRequired
                                        disabled={false} labelTop="Client Service Visit Date / Start Date"
                                        {...useInput('client_service_visit_date', 'isRequired')}
                                    />
                                </FlexGrid3Item>
                                <FlexGrid3Item className="pr-1 mt-1 mb-2" ResizeWidth={'50%'}>
                                    <DateSelector
                                        isRequired
                                        disabled={false} labelTop="End Date"
                                        {...useInput('end_date', 'isRequired')}
                                    />
                                </FlexGrid3Item>

                                <FlexGrid3Item className="pr-1 mt-1 " ResizeWidth={'50%'}>
                                    <DropdownSearchable
                                        // {...useInput('visit')}
                                        placeHolder={'Visit'}
                                        width={'100%'}
                                        disabled={false}
                                        options={visitDropdown ? visitDropdown : []}
                                        selectedOption={values ? values.visit : null}
                                        onChange={value =>
                                            dropDownHandleSelect({ visit: value ? value : null }, 'visit')
                                        }
                                    />
                                </FlexGrid3Item>
                                <FlexGrid3Item className="pr-1 mt-1 mb-2" ResizeWidth={'50%'}>
                                    <DropdownSearchable
                                        disabled={false}
                                        required
                                        {...useInput('form', 'isRequired')}
                                        placeHolder={'Select Care Form'}
                                        options={formChoices && formChoices}
                                        allItem={true}
                                        selectedOption={values.form}
                                        onChange={value =>{
                                            console.log(value,"value");
                                            dropDownHandleSelect({
                                                form: value ? value : null, 
                                            },"form")}
                                        }
                                    />
                                </FlexGrid3Item>
                            </FlexGrid3Container>
                        </>

                        <div className={'d-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'}>
                            <div className={'mb-3'}>
                                {errorMessage ? (
                                    <ErrorMessage>
                                        <label className={'mt-2'}>{errorMessage}</label>
                                    </ErrorMessage>
                                ) : (
                                    <Fragment />
                                )}
                            </div>
                            <div className={'d-flex justify-content-center justify-content-md-end'}>
                                <div className={'ml-0 ml-md-3 mr-2'}>
                                    <PrimaryButtonForm
                                        minWidth="6rem"
                                        onClick={handleSubmit}
                                        disabled={false}>
                                        <span className={'mr-2'}>{textStatus}</span>
                                        {!loadingSave ? (
                                            buttonIcon
                                        ) : (
                                            <span
                                                className="spinner-border spinner-border-sm mr-1"
                                                role="status"
                                            />
                                        )}
                                    </PrimaryButtonForm>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalNoInfo>
            </ModalContainer></div>
    )
}

export default AddCareForm